body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {font-family: "Marydale Bold";
  src: url("Marydale/@font-face/d1dfcbfe6785bf9406b22a9f061111d0.eot"); /* IE9*/
  src: url("Marydale/@font-face/d1dfcbfe6785bf9406b22a9f061111d0.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
  url("Marydale/@font-face/d1dfcbfe6785bf9406b22a9f061111d0.woff2") format("woff2"), /* chrome、firefox */
  url("Marydale/@font-face/d1dfcbfe6785bf9406b22a9f061111d0.woff") format("woff"), /* chrome、firefox */
  url("Marydale/@font-face/d1dfcbfe6785bf9406b22a9f061111d0.ttf") format("truetype"), /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
  url("Marydale/@font-face/d1dfcbfe6785bf9406b22a9f061111d0.svg#Marydale Bold") format("svg"); /* iOS 4.1- */
}
