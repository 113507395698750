.hp-layout-wrapper {
    width:100%;
    position: relative;
    min-height: 950px;
}

.hplb-wrapper {
    width:100%;
}

.hp-wrapper {
    width:100%;
}

.hp-container {
    position:relative;
    width:100%;
}

.hp-item-container {
    width:100%;
}

.hp-mb-first {
    padding:0 0 16px 0;
}

.hp-mb {
    padding:16px 0;
}

.hp-no-mb {
    padding:32px 0;
}

.hp-heading {
    text-align: center;
    font-family: "Open Sans";
    font-size: 26px;
    color: #414142;
    font-weight: 800;
    margin: 40px 0 40px;
    width:100%;
}

.hp-generic-error-msg-container {
    width:100%;
    max-width:768px;
    margin:64px auto 0;
    padding:0 16px;
    display:flex;
    align-items: center;
    justify-content: center;
}

.hp-no-results-msg-wrapper {
    width:100%;
    max-width:768px;
    padding:0 16px;
    margin:0 auto;
}

.hp-loader-animation-container {
    position:absolute;
    display: flex;
    justify-content: center;
    flex:1 1;
    transform: translate(-50%, 376px);
    top:0;
    left:50%;
    min-height: 4500px;
}

@media screen and (min-width:480px){
    .hp-mb-first {
        padding:0 0 32px 0;
    }

    .hp-mb {
        padding:32px 0;
    }

    .hp-no-mb {
        padding:48px 0;
    }

}

@media screen and (min-width:768px){
    .hp-mb-first {
        padding:0 0 32px 0;
        margin-top:0;
    }

    .hp-mb {
        padding:32px 0;
        margin-top:0;
    }

    .hp-no-mb {
        padding:48px 0;
        margin-top:0;
    }
    
}
