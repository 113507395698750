.page-not-found-wrapper {
    width:100%;
    min-height: 950px;
}

.page-not-found-display-wrapper {
    max-width: 1200px;
    padding:16px;
    margin:0 auto;
}

.page-not-found-heading-container {
    width:100%;
    margin:40px 0 20px;
}

.page-not-found-heading {
    color: #414142;
    font-family: "Open Sans";
    margin:0;
    font-size: 36px;
    font-weight: 800;
    text-align: center;
}

.page-not-found-text-container {
    width:100%;
    margin-bottom:400px;
}

.page-not-found-text {
    font-size: 16px;
    font-family: "Open Sans";
    text-align: center;
}