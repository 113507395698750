.shop-store-department-breadcrum-link {
    color:#717172;
    text-decoration: none;
}

.shop-store-department-breadcrum-link:hover {
    color:#717172;
    text-decoration: underline;
}

.shop-store-department-category-breadcrum-link {
    color:#414142;
    text-decoration: none;
}

.shop-special-department-category-sidebar-list {
    list-style-type: none;
    padding:0;
    margin-left:8px;
}

.ssdcsl-adjust {
    margin:0;
}

.shop-special-store-department-category-product-category-sidebar-list {
    list-style-type: none;
    padding:0;
    font-family: "Open Sans";
}

.sssdcpcsl-adjust {
    margin-left:8px;
}

.dc-highlight-adj:hover{
    background-color: #F7F7F7;
}


.shop-special-store-department-category-product-category-sidebar-list-item-link,
.shop-special-store-department-category-product-category-sidebar-list-item-link:hover {
    display:flex;
    justify-content: space-between;
    align-items: center;
    line-height: 24px;
    padding:8px;
    color:#414142;
    text-decoration: none;
    font-family: "Open Sans";
}

.shop-special-store-department-product-category-search-shortcut {
    display:grid;
    grid-template-columns: repeat(auto-fill, minmax(109px,1fr));
    grid-gap:8px;
    padding:0;
    list-style-type: none;
}

.shop-special-store-department-product-category-search-shortcut-item-link {
    margin:0 8px;
    padding:0;
    text-decoration: none;
    border:none;
    color:#414142; 
}

.shop-special-store-department-product-category-search-shortcut-image-container {
    box-shadow: 0px 2px 10px rgba(0,0,0,0.1);
    border:0.5px solid #D7D7D8;
    border-radius:100%;
    position:relative;
    padding-top:100%;
    background-color: #fff;
    margin-bottom:8px;
    overflow:hidden;
}

.shop-special-store-department-product-category-search-shortcut-image {
    position:absolute;
    top:0;
    left:0;
    width:100%;
    /*previous version
    height:100%;
    */
    height:auto;
    padding:16px;
}

.shop-special-store-department-product-category-search-shortcut-title {
    text-align: center;
    color:#414142;
    font-family: "Open Sans";
    font-size: 14px;
    overflow-wrap: break-word;
    margin-bottom:8px;
}

.sizeShape {
    min-height:1200px;
}

.search-filter-list {
    padding:0;
    list-style-type: none;
    max-height:200px;
    overflow-y: hidden;
}


.search-term-filter-form {
    display: flex;
    align-items: center;
    justify-content: space-between;
    align-items: center;
    /*cursor: pointer;*/
    line-height: 24px;
    padding: 8px;
}

.search-term-filter-form-link,
.search-term-filter-form-link:hover {
    display:inline-flex;
    align-items: center;
    text-decoration: none;
    color:#414142;
    width:100%;
}

.search-term-filter-img {
    border-radius:50%;
    margin-right:.25rem;
}

.search-term-filter-label-container {
    flex-grow:1;
}

.search-term-filter-label {
    margin:0;
}

.search-term-filter-radio-btn-container {
    height:14px;
    width:14px;
    /*border:1px solid rgb(210, 210, 210);*/
    border:1px solid rgb(65, 65, 66);
    /*border-radius: 50%;*/
    border-radius: 4px;
    margin:0 0 0 8px;
    position:relative;
    cursor: pointer;
}

.search-term-filter-checkbox-control {
    position:absolute;
    left:0;
    top:0;
    width:100%;
    height:100%;
    appearance: none;
    cursor: pointer;
}

.search-term-filter-radio-btn-container-checked {
    border:none;
    /*background-color: #007DB3;*/
}

.check-shape-container {
    position:absolute;
    left:0;
    top:0;
    width:100%;
    height:100%;
    display:flex;
    align-items: center;
    justify-content: center;
    background-color: #007DB3;
    z-index: 1;
    border-radius: 4px;
}

.check-shape {
    color:white;
    /*position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);*/
    height: 9px;
    width: 9px;
}

.highlight-department-category-text {
    font-weight: 800;
    line-height: 24px;
    padding: 8px 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    color: #414142;
}

.no-highlight-department-category-text {
    font-weight: 800;
    line-height: 24px;
    padding: 8px 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    color: #414142;
}

@media (min-width:480px){
    .shop-special-store-department-product-category-search-shortcut {
        display:flex;
        flex-wrap: wrap;
        justify-content: center;
    }

    .shop-special-store-department-product-category-search-shortcut-item-link {
        display:inline-block;
        width:118px;
    }
}

@media (min-width:1024px){
    .shop-special-store-department-category-product-category-sidebar-list-item:hover {
        background-color: #F7F7F7;
    }

    .search-filter-list-item:hover {
        background-color: #F7F7F7; 
    }
}