.store-location-form-group {
    margin-top:0;
}

.store-location-form-group legend {
    font-size: 20px;
    background-color: rgb(227 223 223);
    width:100%;
    border-radius: 4px;
    padding:8px;
    margin-bottom:16px;
}

.form-input-control {
    appearance: none;
    height:40px;
    border:1px solid rgb(177, 176, 176);
    border-radius: 4px;
    outline:none;
}

.text-description {
    height:140px;
}

.store-location-category-hours-label::first-letter {
    text-transform: uppercase;
}

.store-location-hours-label {
    display: block;
}

.category-hours-multi-day-btn,
.store-location-hours-btn {
    align-self: end;
    appearance: none;
    padding:8px 16px;
    color:#fff;
    background-color: black;
    border:none;
    font-weight: 600;
    font-size:14px;
    border-radius: 4px;
    margin-left:8px;
    display:flex;
    align-items: center;
    justify-content: center;
}

.store-hours-form-group:nth-child(1), .store-hours-form-group:nth-child(2) {
    margin:0;
}

.store-hours-form-group input {
    height:40px;
    border-radius: 4px;
    border: 1px solid #b1b0b0;
    padding:8px;
    width:100%;
    color:#414142
}

.store-location-category-hours-wrapper {
    margin-bottom:16px;
}

.store-location-hours-container {
    display:flex;
    flex-direction: column;
    margin-top:10px;
}

.store-dow-wrapper,
.pharmacy-dow-wrapper {
    display:flex;
    flex-wrap: wrap;
}

.store-dow-container,
.pharmacy-dow-container {
    flex:1 1 100%;
}

.category-hours-multi-day-btn {
    margin:8px 0;
    flex:1 1 100%;
}

.store-location-hours-btn-container {
    margin-top:16px;
    display:flex;
    align-items: center;
}

.store-location-hours-btn {
    background-color: red;
    margin:0;
    flex:1;
}

.store-location-hours-remove-btn {
    margin-left:8px;
}

.store-location-category-services-container {
    margin-top:10px;
}

.store-location-category-service-input {
    display:block;
    margin-top:5px;
}

.store-location-thumb-nail-img {
    object-fit: cover;
    object-position: center center;
    margin-right:8px;
}

.store-location-hours-missing-error,
.store-location-hours-overlap-error {
    align-items: center;
    background-color: #fcc6c6;
    border-radius: 4px;
    color: #7a0909;
    display: flex;
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    margin: 0;
    min-height: 36px;
    padding: 0 4px;
    width: 100%;
}

@media (min-width:768px) {
    .store-location-hours-container {
        flex-direction: row;
    }

    .store-dow-container,
    .pharmacy-dow-container {
        flex:1;
    }
    
    .category-hours-multi-day-btn {
        margin:0 0 0 8px;
        flex:unset;
    }

    .store-location-hours-btn {
        flex:unset;
    }

    .store-hours-form-group:nth-child(1), .store-hours-form-group:nth-child(2) {
        margin-right:8px;
    }



    .store-dow-container:nth-child(2),
    .pharmacy-dow-container:nth-child(2){
        margin-left:8px;
    }

}