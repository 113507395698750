.store-structure-alert-wrapper-hide {
    position:fixed;
    top:0;
    left:0;
    width:100%;
    height:100%;
    background-color: rgba(0,0,0, 0.4);
    border-radius: 8px;
    display:flex;
    justify-content: center;
    align-items: center;
    visibility: hidden;
    opacity: 0;
    z-index: 4;
    font-family: "Open Sans";
    transition: opacity 200ms linear 100ms, visibility 0s ease 300ms, height 0s linear 300ms;
}

.store-structure-alert-wrapper {
    position:fixed;
    top:0;
    left:0;
    width:100%;
    height:100%;
    background-color: rgba(0,0,0, 0.4);
    display:flex;
    justify-content: center;
    align-items: center;
    visibility: visible;
    opacity: 1;
    z-index: 4;
    font-family: "Open Sans";
    transition: opacity 100ms linear 100ms, visibility 0s ease 0s;
}

.store-structure-alert-container {
    background-color: #fff;
    border-radius: 24px;
    padding: 15px 16px 16px;
    max-width: 768px;
    margin:32px;
}

.store-structure-alert-msg {
    color: rgb(65, 65, 66);
    margin-bottom: 32px;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    white-space: pre-line;
}

.store-structure-alert-close-btn {
    width:100%;
    margin: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    background: rgb(220, 41, 30);
    border-radius: 4px;
    border: 1px solid transparent;
    padding: 8px 16px;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    transition: background-color 250ms ease-in-out 0s;
    color: rgb(255, 255, 255);
}

.store-structure-alert-close-btn:hover {
    background-color: rgb(192, 37, 37);
}

@media (min-width:1024px) {
    .store-structure-alert-close-btn {
        width:33.333%;
        margin:4px auto;
    }
}