.list-sorter-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #F7F7F7;
    padding:16px;
    /*max-width: 1000px;
    margin: 0 auto;*/
    width:100%;
}

.list-changer-main-btn-color {
    display:flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    color:black;
    height:25px;
    width:25px;
    border-radius: 50%;
    border:1px solid black;
    font-size:14px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 400;
}

.list-changer-main-btn, .list-changer-ellipsis-btn, .list-changer-previous-btn, .list-changer-next-btn {
    color:black;
    font-size: 14px;
    font-weight: 400;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}