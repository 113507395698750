.shop-by-area-type-layout-wrapper {
    width:100%;
    position: relative;
}

.shop-by-area-type-wrapper {
    position:relative;
    width:100%;
    min-height: 1020px;
}

.shop-by-area-type-container {
    width:100%;
    display:flex;
    margin:0 auto;
    max-width:1440px;
    padding:16px 4px;
    min-height: 900px;
}

.shop-by-area-type-products-wrapper {
    flex:1 1;
}

.shop-by-area-type-products-container {
    width:100%;
    position:relative;
}

.sbat-side-bar-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height:100%;
    background-color: #fff;
    transform: translateY(-25%);
    transition: transform .3s ease;
    visibility: hidden;
}

.shop-products-mobile-search-filter-wrapper {
    overflow-y: auto;
    width:100%;
    height:100%;
    overscroll-behavior-y: contain;
}

.spcbid, .spcbidc, .spcbipc {
    display:none;
}

.spcbid:nth-child(2), .spcbidc:nth-child(3), .spcbipc:nth-child(4) {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: center;
}

.spcbid:nth-child(2) span, .spcbidc:nth-child(3) span, .spcbipc:nth-child(4) span {
    width:8px;
    transform: scaleX(-1);
}

.sbat-loader-animation-container {
    position:absolute;
    display: flex;
    justify-content: center;
    flex:1 1;
    transform: translate(-50%, 280px);
    top:0;
    left:50%;
    height:4500px;
}

.sbat-loader-animation-background-container {
    width:100%;
    height:4500;
}


@media screen and (min-width:280px){
    .shop-by-area-type-container {
        padding-left: calc(15px + ((100vw - 301px) * .5238));
        padding-right: calc(15px + ((100vw - 301px) * .5238));
    }
}

@media screen and (min-width:302px){
    .shop-by-area-type-container {
        padding:16px;
    }
}

@media screen and (min-width:480px){
    .sbat-loader-animation-container {
        transform: translate(-50%, 280px);
    }
}

@media (min-width:768px) {
    .shop-by-area-type-container {
        padding:16px 32px;

    }

    .spcbid, .spcbidc, .spcbipc {
        display:flex;
        align-items: center;
        justify-content: center;
    }

    .spcbid:nth-child(2), .spcbidc:nth-child(3), .spcbipc:nth-child(4) {
        flex-direction: row;
    }

    .spcbid:nth-child(2) span, .spcbidc:nth-child(3) span, .spcbipc:nth-child(4) span {
        width:8px;
        transform: none;
    }

}

@media (min-width:1024px){
    /*
    .shop-by-area-type-container {
        min-height: 800px;
    }
    */

    .sbat-side-bar-wrapper {
        position: relative;
        display: flex;
        visibility: visible;
        margin-right: 16px;
        width: 220px;
        min-width:220px;
        transform: translateY(0%);
        transition: none;
        border: none;
    }

    .sbat-loader-animation-container {
        padding-left:236px;
    }
}