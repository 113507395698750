.menu-list-wrapper {
    width:100%;
    background-color: rgba(220, 41, 30);
    position:relative;
    /*previous version
    z-index: 3;
    */
    z-index: 6;
}

.menu-list-mobile-nav-wrapper {
    position:absolute;
    top:0;
    left:0;
    width:0%;
    height:100%;
    /*background-color: rgba(220, 41, 30);*/
    /*position:relative;*/
    /*previous version
    z-index: 5;
    */
    z-index:7;
}

.menu-list-wrapper-background, .menu-list-mobile-nav-wrapper-background {
    background-color: transparent;
    width:100%;
}

.menu-list-container {
    display: flex;
    justify-content: flex-end;
    background-color: transparent;
    height:44px;
    width:100%;
    position: relative;
    z-index: 3;
}

.menu-list-mobile-nav {
    display:none;
}

.menu-list-mobile {
    list-style-type: none;
}

.menu-list {
    padding:0;
    margin-bottom:0;
    display:flex;
    justify-content: center;
    align-items: center;
    display:none;
    list-style-type: none;
}

.menu-list-item {
    position:relative;
}

.menu-list-item:nth-child(1) .menu-list-item-heading{
    padding: 10px 10px 10px 0;
}

.menu-list-item-heading {
    color:#fff;
    padding:10px;
    font-size: 16px;
    line-height: 24px;
    font-family: "Open Sans";
    font-weight: 800;
    text-decoration: none;
    display:flex;
    align-items: center;
}

.menu-list-item-heading:hover {
    color:#fff;
    text-decoration: none;
}

.shop-menu-list-item:hover .shop-list-caret-up {
    display: block;
}

.shop-menu-list-item:hover .shop-dropdown-menu-list {
    display:block;
}

.shop-dropdown-menu-list-container {
    position:absolute;
    top:100%;
    left:-6px;
    width:256px;
    display: none;
    /*display:block;*/
    z-index: 3;
    background-color: transparent;
}

.shop-dropdown-menu-list {
    position:relative;
    width:100%;
    height:100%;
    padding:22px 0px;
    margin-bottom:0;
    list-style-type: none;
    background-color: #fff;
    border-radius:24px;    
    /*box-shadow: 0px 1px 5px 0 #777;*/

}

.menu-list-angle-down {
    margin-left:.25rem;
}

/*
original version
.caret-up-shape {
    font-size: 38px!important;
    position: absolute;
    top:20px;
    left:13px;
    z-index: 2;
    color:lightgrey;
}
*/

/*
.shop-list-caret-up {
    position:relative;
    display:none;
}


.shop-list-caret-up::before, .shop-list-caret-up::after {
    content:'';
    position:absolute;
    top:0;
    left:6px;
    width:0;
    height:0;
    z-index:4;
}

.shop-list-caret-up:before {
    top:-4px;
    border-left:12px solid transparent;
    border-right:12px solid transparent;
    border-bottom:12px solid lightgrey;
}

.shop-list-caret-up::after {
    top:-2px;
    border-left:12px solid transparent;
    border-right:12px solid transparent;
    border-bottom:12px solid white;
}
*/

.shop-dropdown-sub-menu-list-container {
    position: absolute;
    top:0;
    left:100%;
    width:100%;
    height:100%;
    /*box-shadow: 2px 1px 4px 0 #777;*/
    display:none;
    /*display:block;*/
    background-color: white;
    padding:8px 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 24px;
    border-bottom-right-radius: 24px;
}

.shop-dropdown-sub-menu-list-container::after {
    content: '';
    width: 200%;
    height: 100%;
    display: block;
    background-color: #fff;
    /* border: 1px solid black; */
    position: absolute;
    left: -100%;
    top: 0;
    z-index: -1;
    border-radius: 24px;
}

.shop-dropdown-sub-menu-list {
    width:100%;
    margin:0;
    list-style-type: none;
    padding:0;    
}

.shop-dropdown-sub-menu-list-heading {
    border-bottom:1px solid rgb(238, 236, 236);
    padding:0 0 8px;
    margin:8px 16px;
    font-size: 18px;
    font-family: "Open Sans";
    font-weight: 600;
    color:#414142;
    line-height: 1.6;
}

.shop-dropdown-menu-list-item {
    /*position: relative;*/
    display:flex;
    align-items: center;
    padding:0;
    line-height: 1.6;
}

.shop-dropdpwn-sub-menu-list-item {
    padding:.25rem 1rem;
}

.shop-dropdown-sub-menu-list-item-link {
    color:#414142;
    font-size: 16px;
    font-family: "Open Sans";
    text-decoration: none;
    padding:8px 16px;
    display:block;
}

.shop-dropdown-sub-menu-list-item-link:hover {
    color:#414142;
    background-color: rgb(240, 239, 239);
}

.shop-dropdown-menu-list-item:hover .shop-dropdown-sub-menu-list-container {
    display:block;
}

.shop-dropdown-menu-list-item-name {
    flex-grow: 1;
}

.shop-dropdown-menu-list-item-link {
    display: flex;
    text-decoration: none!important;
    color:#414142;
    padding:8px 16px;
    width:100%;
    align-items: center;
    font-family: "Open Sans";
}

.shop-dropdown-menu-list-item-link:hover {
    color:#414142;
    background-color: rgb(240, 239, 239);
}

/**pharamcy dropdown list styles**/

.pharmacy-list-caret-up {
    position:relative;
    display:none;
}

.pharmacy-list-caret-up::before, .pharmacy-list-caret-up::after {
    content:'';
    position:absolute;
    top:0;
    right:22px;
    width:0;
    height:0;
    z-index:4;
}

.pharmacy-list-caret-up:before {
    top:-4px;
    border-left:12px solid transparent;
    border-right:12px solid transparent;
    border-bottom:12px solid lightgrey;
}

.pharmacy-list-caret-up::after {
    top:-2px;
    border-left:12px solid transparent;
    border-right:12px solid transparent;
    border-bottom:12px solid white;
}

.pharmacy-menu-list-item:hover .pharmacy-list-caret-up {
    display: block;
}

.pharmacy-menu-list-item:hover .pharmacy-dropdown-menu-list {
    display:block;
}

.pharmacy-dropdown-menu-list {
    position:absolute;
    top:43px;
    right:24px;
    display:none;
    padding:.5rem 0px;
    margin-bottom:0;
    list-style-type: none;
    /*width:220px;*/
    width:250px;
    box-shadow: 0px 1px 5px 0 #777;
    z-index: 3;
    background-color: white;
}

.pharmacy-dropdown-sub-menu-list-container {
    position: absolute;
    top:0;
    /*left:221px;*/
    left:251px;
    box-shadow: 2px 1px 4px 0 #777;
    display:none;
    background-color: white;
}

.pharmacy-dropdown-sub-menu-list {
    width:250px;
    list-style-type: none;
    padding:0;    
}

.pharmacy-dropdown-sub-menu-list-heading {
    border-bottom:1px solid lightgrey;
    padding:.25rem 1rem;
    font-weight: bold;    
}

.pharmacy-dropdown-menu-list-item {
    position: relative;
    display:flex;
    flex-direction: row;
    align-items: center;
    /*padding:.25rem 1rem;*/
    padding:0;
}

.pharmacy-dropdown-sub-menu-list-item {
    /*padding:.25rem 1rem;*/
    padding:0;
}

.pharmacy-dropdown-sub-menu-list-item:hover {
    background-color: rgb(240, 239, 239);
}

.pharmacy-dropdown-sub-menu-list-item-link {
    color:grey;
    text-decoration: none!important;
    padding:.25rem 1rem!important;
    display:block;
}

.pharmacy-dropdown-sub-menu-list-item-link:hover {
    color:#414142;
    background-color: rgb(240, 239, 239);
}

.pharmacy-dropdown-menu-list-item:hover .pharmacy-dropdown-sub-menu-list-container {
    display:block;
}

.pharmacy-dropdown-menu-list-item-name {
    flex-grow: 1;
}

.pharmacy-dropdown-menu-list-item-link {
    display: flex;
    text-decoration: none!important;
    color:#414142;
    padding:.25rem 1rem!important;
    width:100%;
    align-items: center;
}

.pharmacy-dropdown-menu-list-item-link:hover {
    color:#414142;
    background-color: rgb(240, 239, 239);
}

/**shade background style for dropdown list**/
.background-shade-container {
    background-color: rgba(0,0,0,0.4);
    visibility: visible;
    position:fixed;
    top:112px;
    width:100%;
    height:100%;
    opacity:1;
    z-index: 1;
    transition: opacity 100ms linear 100ms, visibility 0s ease 0s;
}


.background-shade-container-hide {
    background-color: rgba(0,0,0,0.4);
    visibility: hidden;
    position:fixed;
    left:0px;
    top:112px;
    width:100%;
    height:0;
    opacity:0;
    z-index: 1;
    /*transition: opacity 100ms linear 100ms, visibility 0s ease 300ms, height 0s linear 300ms;*/
    transition: opacity 100ms linear 100ms, visibility 0s ease 0s, height 0s linear 300ms;
}

/**shade background style for mobile nav**/
.background-shade-container-mobile {
    background-color: rgba(0,0,0,0.4);
    visibility: visible;
    /*position:absolute;*/
    position:fixed;
    left:0px;
    top:0;
    width:100%;
    height:100%;
    opacity:1;
    transition: opacity 50ms linear 50ms, visibility 0s ease 50ms, height 0s linear 50ms;
}


.background-shade-container-mobile-hide {
    background-color: rgba(0,0,0,0.4);
    visibility: hidden;
    position:fixed;
    left:0px;
    top:0;
    width:100%;
    height:0;
    opacity:0;
    transition: opacity 50ms linear 0s, visibility 0s ease 0s, height 0s linear 0s;
}

/**shade background style for keyword dropdown list**/
.keyword-background-shade-container {
    background-color: rgba(0,0,0,0.4);
    visibility: visible;
    position:fixed;
    left:0;
    top:0;
    width:100%;
    height:100%;
    opacity:1;
    /*previous version
    z-index: 2;
    */
    z-index: 5;
    transition: opacity 100ms linear 100ms, visibility 0s ease 0s;
}


.keyword-background-shade-container-hide {
    background-color: rgba(0,0,0,0.4);
    visibility: hidden;
    position:absolute;
    left:0;
    top:0;
    width:100%;
    height:0%;
    opacity:0;
    z-index: 3;
    /*transition: opacity 200ms linear 100ms, visibility 0s ease 300ms, height 0s linear 300ms;*/
    transition: opacity 200ms linear 100ms, visibility 0s ease 0ms, height 0s linear 300ms;
}

.menu-options-list {
    padding:0;
    margin:0;
    display:inline-flex;
    /*align-items: center;*/
    list-style-type: none;
}

.menu-options-list-item:nth-child(1){
    display:none;
}

.menu-options-list-item:nth-child(1),
.menu-options-list-item:nth-child(2),
.menu-options-list-item:nth-child(3) {
    margin-right: 16px;
    position: relative;
}

.menu-options-list-item:nth-child(2),
.menu-options-list-item:nth-child(3) {
    display:flex;
    align-items: center;
}

.mn2-menu-option-link {
    text-decoration: none;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    height:100%;
    display:inline-flex;
    align-items: center;
    font-family: "Open Sans";
    color:#fff;
}

.mn2-menu-option-link:hover {
    color:#fff;
    text-decoration: none;
}

.mn2-menu-option-btn {
    appearance: none;
    background-color: transparent;
    border:none;
    color:#fff;
    font-weight: 400;
    padding:0;
    height:100%;
    font-family: "Open Sans";
}

.admin-login-svg {
    display:none;
}

.lock-container {
    display: flex;
    align-items: center;
    justify-content: center;
    appearance: none;
    border:0;
    background-color: transparent;
    color:black;
}


.or-list-item {
    margin:0 5px;
}

@media screen and (max-width:479px) {
    .menu-list-container {
        justify-content: center;
    }

    .mn2-menu-option-link,
    .mn2-menu-option-btn {
        font-weight: 700;
    }

    .menu-options-list {
        width:100%;
        justify-content: space-evenly;
        padding:0 16px;
    }

    .menu-options-list-item:nth-child(2), 
    .menu-options-list-item:nth-child(3) {
        margin-right: 0px;
    }
    
}


@media screen and (min-width:480px){
    .menu-list-wrapper-background, .menu-list-mobile-nav-wrapper-background {
        background-color: rgba(0,0,0,0.1);
    }

    .menu-list-container {
        padding:0 16px;
    }

}

@media screen and (min-width:1024px){
    .menu-list-container {
        justify-content: space-between;
        padding:0 32px;
        max-width: 1440px;
        margin:0 auto;
    }

    .menu-list {
        display:flex;
    }

    .shop-dropdown-menu-list-item-link-svg {
        width:16px;
        height:16px;
    }

    .menu-list-item-heading-svg {
        display:block;
        width:8px;
        height:8px;
        margin-left: 4px;
    }

    .menu-options-list-item:nth-child(1) {
        display:block;
    }

    .admin-login-svg {
        display:block;
    }

}

@media screen and (max-width:1023px){
    .menu-list-mobile-nav {
        padding:16px 0;
        background-color: #fff;
        margin:0;
        height:100vh;
        position:fixed;
        top:0;
        right:100%;
        z-index: 4;
        width:256px;
        display: flex;
        flex-direction: column;
        overflow-y: auto;
        list-style-type: none;
        overscroll-behavior: contain;
        transform: translateX(0px);
        /*transition:transform .5s ease;*/
    }

    .menu-list-options-mobile {
        padding:0 16px;
        margin-bottom:8px;
        list-style-type: none;
    }

    .menu-list-options-item-mobile {
        width:100%;
    }

    .menu-list-options-item-link-mobile {
        width:100%;
        display:flex;
        align-items: center;
        padding:8px 0;
        text-decoration: none;
        font-size:16px;
        font-family: "Open Sans";
        color:#414142;
        height:32px;
        line-height: 22px;
    }

    .menu-list-options-item-link-svg {
        width:16px;
        height:16px;
        margin-right:8px;
    }

    .menu-list-mobile {
        padding:0;
        margin-bottom:8px;
    }

    .shop-mobile-menu-list-item {
        width:100%;
        margin-bottom: 16px;
    }

    .shop-mobile-menu-list-item-heading-link, .shop-mobile-menu-list-item-heading-link:hover {
        display:flex;
        align-items: center;
        padding:10px 0;
        text-decoration: none;
        font-size:16px;
        font-family: "Open Sans";
        color:#424046;
        font-weight: 800;
        line-height: 24px;
        margin:0 16px;
        border-bottom:1px solid rgb(247, 247,247);
    }

    .shop-mobile-dropdown-menu-list-container {
        width:100%;
    }

    .shop-mobile-dropdown-menu-list {
        margin:0;
        padding:0;
        list-style-type: none;
    }

    .shop-mobile-dropdown-menu-list-item {
        width:100%;
    }

    .shop-mobile-dropdown-menu-list-item-link {
        width:100%;
        text-decoration: none;
        display:flex;
        align-items: center;
        justify-content: space-between;
        padding:8px 16px;
        margin:0;
        color:#414142;
        font-family: "Open Sans";
        cursor: pointer;
        height:32px;
        line-height: 22px;
    }

    .shop-mobile-dropdown-menu-list-item-link:hover {
        width:100%;
        text-decoration: none;
        display:flex;
        align-items: center;
        justify-content: space-between;
        padding:8px 16px;
        margin:0;
        color:#414142;
        font-family: "Open Sans";
        cursor: pointer;
        height:32px;
        line-height: 22px;
        /*background-color: rgb(240, 239, 239);*/
        background-color: rgb(231, 230, 230);
    }

    .shop-mobile-dropdown-menu-list-item-link-svg {
        width:16px;
        height:16px;
        transform:rotate(90deg);
    }

    .rotate-svg-dropdown-arrow {
        transform: rotate(-90deg);
    }

    .shop-mobile-dropdown-sub-menu-list-container {
        width:100%;
        background-color: rgb(245, 245, 245);
        display: none;
    }

    .show-smdsmlc {
        display:block;
    }

    .shop-mobile-dropdown-sub-menu-list {
        width:100%;
        padding:0;
        margin:0;
        list-style-type: none;
    }

    .shop-mobile-dropdown-sub-menu-list-item {
        width:100%;
    }

    .shop-mobile-dropdown-sub-menu-list-item-link {
        color:#414142;
        padding:8px 16px;
        text-decoration: none;
        width:100%;
        display: flex;
        height: 32px;
        line-height: 22px;
    }

    .shop-mobile-dropdown-sub-menu-list-item-link:hover {
        /*background-color: rgb(245, 245, 245);*/
        color:#414142;
        background-color: rgb(231, 230, 230);
        padding:8px 16px;
        text-decoration: none;
        width:100%;
        display: flex;
        height: 32px;
        line-height: 22px;
    }

}