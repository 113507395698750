.shop-product-categoryname-breadcrum-link {
    color:#717172;
    text-decoration: none;
}

.shop-product-name-breadcrum-link {
    color:#414142;
    text-decoration: none!important;
}

.shop-product-heading {
    font-family: "Open Sans";
    font-size: 30px;
    font-weight: 700;
    line-height: 35px;
}

.shop-product-sidebar-container {
    margin:10px 0 0 0;
    flex-basis: 225px;
}

.shop-product-sidebar-add-to-cart-price-section {
    text-align: center;
    margin-top: 10px;
    min-width: 195px;
    background-color: #f7f7f7;
    padding: 32px 24px;
    border-radius: 4px;
}

.shop-product-sidebar-add-to-cart-price-container {
    margin: 0 0 1rem;
    /*border:1px solid black;*/
}

.shop-product-sidebar-add-to-cart-price {
    font-size: 32px;
    font-weight: 800;
    font-family: "Open Sans";
    color:#333333;
}

.shop-product-sidebar-estimated-package-weight-container {
    font-size: 12px;
    margin:0 0 .5rem;
}

.shop-product-sidebar-btn {
    border-radius: .25rem;
    border-style:none;
    outline-style: none!important;
    padding:.5rem 1rem;
    font-size: 14px;
    font-weight: 700;
    width:100%;
}

.shop-product-cart-btn {
    background-color: red;
    transition: background-color .3s ease-in-out;
    margin:0 0 16px 0;
    line-height: 1.5;
    font-weight: 700;
    color:#fff;
    border-radius: 4px;
    font-size: 14px;
    font-family: "Open Sans";
    padding:8px 16px;
    border:none;
    background-color: #dc2a27;
}

.shop-product-cart-btn:hover {
    background-color: rgb(192, 37, 37);
    transition: background-color .3s ease-in-out;
}

.shop-product-list-btn {
    display: block;
    width: 100%;
    margin: 0;
    color: #333;
    background-color: white;
    height: 38px;
    padding: 8px 1rem;
    font-weight: 700;
    border: 1px solid #eeecec;
    border-radius: 4px;
    font-size: 14px;
    font-family: "Open Sans";
    transition: background-color .3s ease-in-out;
}

.shop-product-list-btn:hover {
    background-color: rgb(235, 233, 233);
    transition: background-color .3s ease-in-out;
}

.shop-product-email-print-tools-menu-container {
    margin:30px 0 0 0;
    line-height: 46px;
}

.shop-product-email-print-tools-menu {
    padding:0;
    list-style-type:none;
}

.email-icon-link-container, .print-icon-link-container {
    display:flex;
    align-items: center;
    text-decoration: none!important;
}

.print-icon-link-container {
    border-top:1px solid rgb(235, 233, 233);
}

.email-print-icon-shape {
    font-size: 20px;
    margin:0 10px 0 5px;
    color:red;
}

.shop-product-overview-nutrition-facts-container {
    margin-top:21px;
    /*border:1px solid black;*/
    height:100%;
}

.shop-product-overview-nutrition-facts-title {
    font-weight: 700;
    font-style: normal;
    font-size: 18px;
    line-height: 30px;
    color: #f36b21;
    padding-bottom: 2px;
    font-family: "Open Sans";
}

.shop-product-overview-nutrition-facts-list {
    padding:0;
    list-style-type: none;
}

.shop-product-overview-nutrition-dropdown-container {
    /*border:1px solid black;*/
    display:flex;
    margin-bottom: 6px;
    background-color: #8b8b8b;
    cursor:pointer;
}

.shop-product-overview-nutrition-dropdown-name {
    line-height: 26px;
    color: #FFF;
    margin-bottom: 6px;
    font-weight: 700;
    font-size: 14px;
    width: 100%;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    text-align: left;
    cursor: pointer;
    font-family: "Open Sans";
}

.shop-product-overview-nutrition-dropdown-icon-container {
    display: flex;
    justify-content: center;
    /*border:1px solid red;*/
    flex-basis:40px;
    color:white;
    padding-top:.25rem;
}

.nutrition-list-icon-shape {
    font-size: 20px;
}

.show-nutrition-list-icon {
    display:block;
}

.hide-nutrition-list-icon {
    display:none;
}


.nutrition-table-footer-section {
    margin-bottom: 10px;
}

.footer {
    border-bottom: 3px solid #3c3c3c;
    padding: 3px 0 5px;
    height:10px;
}

.footer-disclaimer {
    border-bottom: 1px solid #3c3c3c;
    padding: 5px 0 10px;
    font-size: 9px;
    line-height: 1.3em;
}

.shop-product-overview-nutrition-table-title {
    background-color: #d5d5d5;
    text-align: right;
    font-weight: 700;
    padding-right: 5px;
    font-size:11px;
    line-height: normal;
}

.shop-product-overview-nutrition-table {
    width: 100%;
    padding: 0;
    margin-bottom: 0;
    border: none;
    border-top: solid 10px #DDDDDD;
    display: table !important;
    line-height: normal;
    font-size:11px;
}

.nutrition-table-aps-data, .nutrition-table-dv-data {
    margin-bottom:0;
}

.nutrition-table-cell-shape {
    text-align: right;
}

.shop-product-overview-nutrition-dropdown-content {
    font-family:"Open Sans";
}

.nutrition-table-serving-stats {
    margin:0;
    font-size: 15px;
}

.shop-product-overview-wrapper {
    margin:60px auto 0;
}

.shop-product-overview-container {
    display:flex;
    padding:0 15px;
    width:100%;
    justify-content: center;
}

.shop-product-overview-details-container {
    display:flex;
    padding:0;
    flex-direction: column;
    flex-basis:750px;
    /*border:1px solid blue;*/
}

.shop-product-overview-details-wrapper {
    /*border:1px solid red;*/
    display:flex;
    /*height:318px;*/
}

.shop-product-overview-details {
    padding:0;
    margin:0 1.5rem 0 0;
    z-index: 0;
    flex-basis:410px;
    display: flex;
    flex-direction: column;
    /*border:1px solid blue;*/
}

.shop-product-overview-description-section {
    /*border:1px solid black;*/
    margin:45px 20px 0;
    font-size: 14px;
    line-height: 21px;
}

.shop-product-overview-description-container {
    padding:10px 20px 11px;
}

.shop-product-overview-description-detail-container {
    margin:0 0 14px;
}

.shop-product-overview-description-detail-heading {
    padding: 0 0 5px;
    font-size: 16px;
    font-weight:800;
    font-family: "Open Sans";
    color:#333333;
}

.shop-product-overview-description-detail-disclaimer {
    font-size: 12px;
    font-style: italic;
    font-weight: 400;
    color: #707070;
    padding-top: 10px;
}

.shop-product-desktop-layout-wrapper {
    display:none;
}

/*
.shop-product-breadcrum-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    line-height: 24px;
    margin: 16px;
    font-family: "Open Sans";
    color: #414142;
    padding: 0;
    list-style-type: none;
}
*/

.shop-product-packing-option {
    color:grey;
}

.shop-product-overview-price-container {
    padding:13px 0 0 0;
    /*border:1px solid red;*/
}

.shop-product-overview-sale-price-container {
    display:flex;
    /*border:1px solid red;*/
    padding:0;
}

.shop-product-overview-list-price-container {
    display:flex;
    /*border:1px solid black;*/
    padding:0;
}

.shop-product-overview-sale-price {
    color:#dc291e;
    font-weight: 800;
    font-size: 32px;
    line-height: 30px;
    font-family: sans-serif;
}

.shop-product-overview-list-price-slashed, .shop-product-overview-list-price {
    display: flex;
    color:#333;
    font-weight: 600;
    font-size: 16px;
    font-family:sans-serif;
}

.shop-product-overview-list-price-slashed{
    text-decoration: line-through;
}

.shop-product-overview-unit-of-measure-sale-price {
    display:flex;
    align-items: flex-end;
    color:#8b8b8b;
    font-size:12px;
    font-family: sans-serif;
    font-weight: 800;
    padding:0 0 0 .5rem;
}

.shop-product-overview-unit-of-measure-list-price-slashed, .shop-product-overview-unit-of-measure-list-price {
    display:flex;
    color:#8b8b8b;
    font-size:12px;
    padding:0 0 0 .5rem;
    font-family: sans-serif;
    align-items: center;
}

.shop-product-overview-unit-of-measure-list-price-slashed {
    text-decoration: line-through;
}

.star-rating-list-group-container-product-options {
    display: flex;
    /*justify-content: center;
    align-items: center;
    border:1px solid black;*/
    padding:0;
    height:21px;
    
}

.star-rating-list-product-options {
    padding:0;
    display:flex;
    align-items: center;
    list-style-type: none;
    margin-bottom: 0;
    box-sizing: border-box;
}

.star-rating-list-product-options li {
    margin-right:.20rem;
}

.product-overview-rating-star-shape {
    font-size:15px!important;
}

.shop-product-overview-rating-container {
    display:flex;
    flex-direction: column;
    padding:11px 0 0 0;
}

.shop-product-overview-write-review-link-container,
.shop-product-overview-rate-read-review-quantity-link-container {
    padding:0;
    font-size:14px;
    color:#009FCE;
}

.shop-product-overview-rate-review-link,
.shop-product-overview-read-reviews-link,
.shop-product-overview-write-review-link {
    text-decoration: none!important;
}

@media screen and (min-width:1024px){
    .shop-product-desktop-layout-wrapper {
        max-width: 1440px;
        margin:0 auto;
        display:flex;
        flex-direction: column;
        display: flex;
        padding:16px 0 0;
    }
}
