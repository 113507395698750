.add-shop-product-rating-list {
    display: flex;
    padding:0;
    list-style-type: none;
    margin:0;
    height:45px;
}

.add-shop-product-rating-list-item {
    margin-left:8px;
    font-size: 30px;
    cursor: pointer;
}

.add-shop-product-rating-list-item:nth-child(n+2) {
    margin-left:30px;
    font-size: 30px;
    cursor: pointer;
}

.add-shop-product-rating-star-color {
    color:blue;
}

.add-shop-product-rating-star-no-color {
    color:lightgrey;
}

.add-shop-product-rating-question {
    color:#333333;
    font-size: 18px;
    font-weight: 700;
    padding:16px 0 0 8px;
    width:100%;
}

label.add-shop-product-rating-question  {
    margin:0;
}

.add-shop-product-rating-form-control {
    display:block;
    width:100%;
    border-radius: .25rem;
    border:1px solid lightgrey;
    padding:.35rem 1rem;
    color:#595959 !important;
    font-size: 15px;
    font-weight: 400;
    margin-bottom: .5rem;
}

.add-shop-product-rating-text {
    margin-bottom:0;
}

.add-shop-product-rating-help-text {
    font-size: 13px;
    padding-left:1rem;
}

.add-shop-product-rating-submit-btn {
    background-color: #d3d1d1;
    color:#3E3C3C;
    border-radius: .25rem;
    display:block;
    border-style: none;
    font-size: 18px;
    font-weight: 700;
    margin:28px 0 20px;
    padding:.35rem 0;
    width:100%;
    transition:background-color .3s ease, color .3s ease;
}

.add-shop-product-rating-submit-btn:hover {
    color:#272626;
    background-color: #a3a1a1;
    transition:background-color .3s ease, color .3s ease;
} 

.darkText {
    color:black!important;
}

.product-rating-title-error-msg, .product-rating-comment-error-msg, .product-rating-error-msg {
    border:2px solid red;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    margin-bottom: 0;;
}


.product-rating-title-error-msg-dropdown, .product-rating-comment-error-msg-dropdown, 
.product-rating-error-msg-dropdown, .product-rating-comment-error-text {
    background-color: rgb(245, 204, 204);
    padding:.35rem .5rem;
    color:rgb(129, 27, 27);
    border-bottom-left-radius: .25rem;
    border-bottom-right-radius: .25rem;
    font-size: 16px;
    font-weight: 500;
}

@media screen and (min-width:768px){
    .add-shop-product-rating-submit-btn {
        width:155px;
    }
}

@media screen and (min-width:1024px) {
    .add-shop-product-rating-list {
        height:30px
    }

    .add-shop-product-rating-list-item {
        margin-left:8px;
        font-size: 20px;
        cursor: pointer;
    }
    
    .add-shop-product-rating-list-item:nth-child(n+2) {
        margin-left:10px;
        font-size: 20px;
        cursor: pointer;
    }    
}