.phone-number-input-container {
    width:100%;
}

.phone-number-input {
    appearance: none;
    height: 40px;
    border: 1px solid rgb(177, 176, 176);
    border-radius: 4px;
    outline: none;
    width:100%;
}