.admin-store-location-wrapper {
    width:100%;
    font-family: "Open Sans";
}

.admin-store-location-container {
    position:relative;
    width:100%;
    padding:16px;
    max-width: 1000px;
    min-height: 900px;
    margin:0 auto;
}

.store-location-search-box-form {
    display: flex;
    border:1px solid green;
}

.store-location-search-secondary-label {
    display: inline-flex;
    align-items: center;
    font-size: 14px;
    margin-bottom: 2px;
}

.store-location-search-box {
    flex-basis: 300px;
    border:1px solid red;
}

.asls-loader-animation-container {
    position:absolute;
    width:100%;
    display:flex;
    justify-content: center;
    top:0;
    left:50%;
    transform:translate(-50%, 100px);
    /*margin-top: 100px;*/
    height:3500px;
}

.asls-loader2-animation-container {
    width:100%;
    display:flex;
    justify-content: center;
    margin-top: 100px;
    height:4500px;
}

/**table styles**/
.asls-table-container {
    width: 100%;
    margin: 0 auto;
    overflow-x: auto;
}

.asls-table {
    table-layout: fixed;
    width: 100%;
    min-width: 968px;
    border-collapse: separate;
    border-spacing:0;
    border-radius: 4px;
    overflow: hidden;
    border:1px solid black;
}

.asls-table th, 
.asls-table td {
    border:1px solid black;
    padding:4px;
    font-family: "Open Sans";
    text-align: left;
}

.asls-td {
    display:-webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 2;
}

.asls-sn {
    text-align: center;
}

.asls-image-container {
    display:flex;
    align-items: center;
    justify-content: center;
}

.asls-table .td-img img {
    max-width: 100%;
    max-height:85px;
}

.asls-table .td-action {
    padding:0;
}

.asls-table th {
    background-color: #d6d4d4;
    color:#414142;
    font-weight: 700;
    padding:8px;
    border-top:0;
    border-right:0;
    border-bottom:0;
}

.asls-table th:first-child {
    border-left:0;
}

.asls-table td {
    border-right:0;
    border-bottom:0;
    height:94px;
}

.asls-table tr td:first-child {
    border-left:0;
}

.asls-table th span {
    display:inline-flex;
}

.asls-table .th-sl-sn {
    width:19%;
}

.asls-table .th-sl-sl {
    width:19%;
}

.asls-table .th-sl-sli {
    width:35%;
    /*width: 327.39px;*/
}

.asls-table .th-sl-date {
    width:15%;
}

.asls-table .th-sl-action {
    width:12%;
}


.asbi:nth-child(1) {
    display:flex;
    flex-direction: row-reverse;
}

.asbi:nth-child(1) svg {
    transform: scaleX(-1);
}

.asbi:nth-child(2) {
    display:none;
}

@media (min-width:420px) {
    .store-location-search-secondary-label {
        display:none;
    }
    
}


@media (min-width:768px){
    .asbi:nth-child(1) {
        flex-direction: row;
    }

    .asbi:nth-child(1) svg {
        transform: unset;
    }

    .asbi:nth-child(2) {
        display:flex
    }
}