.image-slider-wrapper {
    max-width: 1440px;
    width:100%;
    position:relative;
    margin: 0 auto;
}

.image-slider-heading-container {
    text-align: left;
    margin-bottom:24px;
    padding:0 16px;
    color:#414142;
    font-family: "Open Sans";
}

.image-slider-heading {
    font-weight: 800;
    line-height: 28px;
    font-size: 20px;
    margin:0 32px 0 0;
}

.image-slider-caption {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color:#414242;
    font-family: "Open Sans";
}

.image-slider-container {
    width:100%;
    height:100%;
    overflow-x: hidden;
}

.image-slider-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    border:none;
    background-color: #333333;
    color:#fff;
    height:44px;
    width:44px;
    border-radius: 50%;
    z-index: 1;
}

.hide-image-slider-btn {
    display:none;
}

.image-slider-btn:nth-child(1) {
    position:absolute;
    left:-23px;
    top:0;
    bottom:0;
    margin:auto 0;
    transform: scaleX(-1);
}

.image-slider-btn:nth-child(2) {
    position:absolute;
    right:-23px;
    top:0;
    bottom:0;
    margin:auto 0;
}

.slider-image-list {
    display: grid;
    position: relative;
    column-gap: 16px;
    grid-auto-flow: column;
    height:100%;
    grid-template-columns: repeat(25, 210px);
    /*grid-template-columns: repeat(25, minmax(calc(calc(100% - calc((var(--numCols) - 1)* 16px)) / var(--numCols)), 1fr));*/
    padding-left: 16px;
    position:relative;
    --numCols:4;
}

.slider-image-list-3 {
    display: grid;
    grid-template-columns: repeat(3, 210px);
    grid-auto-flow: column;
    column-gap: 16px;
    padding-bottom: 16px;
    padding-left: 16px;
    position: relative;
    --numCols: 4;
}

.slider-image-list-4 {
    display: grid;
    grid-template-columns: repeat(4, 210px);
    grid-auto-flow: column;
    column-gap: 16px;
    padding-bottom: 16px;
    padding-left: 16px;
    position: relative;
    --numCols: 4;
}

.slider-image {
    display:flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    height:100%;
    background-color: rgb(245, 202, 202);
}

.image-slider-scrollbar-wrapper {
    display:flex;
    flex-direction: column;
    justify-content: space-around;
    margin:0 16px 0 0;
    padding:0 16px;
    height:48px;
    width:100%;
    touch-action: none;
}

.hide-image-slider-scrollbar-wrapper {
    display: none;
}

.image-slider-scrollbar-container {
    position: relative;
    /*max-width: 1000px;*/
    height:20px;
    background-color: rgb(238, 236, 236);
    border-radius: 4px;
    width: 100%;
    height: 2px;
}

.isb-expand-full {
    height:8px;
}

.isb-expand-medium {
    height:4px;
}

.image-slider-scrollbar {
    position: absolute;
    width:100%;
    background-color: rgb(65, 65, 66);
    height:100%;
    border-radius: 4px;
}

@media (min-width:0) and (max-height:320px) {
    .slider-image-list {
        grid-template-columns: repeat(25, 175px);
    }
}

@media screen and (max-width:767px) {
    .image-slider-container {
        overflow-x: scroll;
        scrollbar-width: none;
        -ms-overflow-style: none;
        -webkit-overflow-scrolling: touch;
    }

    .image-slider-container::-webkit-scrollbar {
        display:none;
    }

    .image-slider-btn {
        display:none;
    }
    
}


@media screen and (min-width:768px){
    .image-slider-heading {
        line-height: 40px;
        font-size: 28px;
    }

    .image-slider-heading-container {
        margin-bottom: 32px;
        padding: 0px;
    }

    .image-slider-caption {
        font-size: 20px;
        font-weight: 400;
        line-height: 28px;
    }

    .image-slider-scrollbar-wrapper {
        margin:0;
        padding:0;
    }

    .slider-image-list {
        grid-template-columns: repeat(25, minmax(calc(calc(100% - calc((var(--numCols) - 1)* 16px)) / var(--numCols)), 1fr));
        padding-left:0;
    }

    .slider-image-list-3 {
        /*grid-template-columns: repeat( 3, minmax(calc(((100% - (calc(var(--numCols) - 1) * 16px)) / var(--numCols))), 1fr ));*/
        grid-template-columns: repeat(3, minmax(calc(calc(100% - calc((var(--numCols) - 1)* 16px)) / var(--numCols)), 1fr));
        width: unset;
        column-gap: 16px;
        padding-left: 0px;
    }

    .slider-image-list-4 {
        grid-template-columns: repeat(4, minmax(calc(calc(100% - calc((var(--numCols) - 1)* 16px)) / var(--numCols)), 1fr));
        /*grid-template-columns: repeat( 4, minmax( calc(((100% - (calc(var(--numCols) - 1) * 16px)) / var(--numCols) )), 1fr ));*/
        width: unset;
        column-gap: 16px;
        padding-left: 0px;
    }
    
}

@media screen and (min-width:1024px){
    .slider-image-list {
        --numCols:5;
    }

    .slider-image-list-3 {
        --numCols:4;
    }

    .slider-image-list-4 {
        --numCols:4;
    }
}

@media screen and (min-width:1200px){
    .slider-image-list {
        --numCols:6;
    }
}

