.cs-container-wrapper {
    width:100%;
    margin-bottom:64px;
}

.category-shifter-container {
    width:100%;
    margin-top:79px;
    /*margin-top:2rem;*/
    display:flex;
    flex-direction: column;
    align-items: center;
    /*margin-bottom:5rem;*/
}

.category-shifter-list-item {
    position:relative;
    overflow:hidden;
}

.cs-list-item-image-container {
    width:100%;
    position:relative;
}

.category-shifter-list-item-main-heading {
    position:absolute;
    top:40%;
    left:25%;
}


.hover-effect {
    width:100%;
    position: relative;
    /*overflow: hidden;*/
    /*background-color: black;*/
}

/*
.hover-effect-image {
    transition: opacity .4s, transform .2s ease-in-out;
    width:100%;
    height:440px;
}
*/

.hover-effect-image {
    position:absolute;
    width:100%;
    height:100%;
    top:0;
    left:0;
    transform: scale(1);
    transition:transform .35s linear;
}

.category-shifter-overlay-heading {
    color:white;
    font-size: 14px;
    font-family:gotham;
    background-color: rgba(0,0,0,0.7);
    text-transform: uppercase;
    padding:10px 5px;
    text-align: center;
    margin-bottom:0;
    transform: translateY(50px);
    transition: transform .35s linear;
    text-shadow: 0 2px 0 rgba(0,0,0,0.3);
}

.category-shifter-overlay-paragraph {
    text-align: center;
    padding:10px 20px;
    margin-top:2rem;
    margin-bottom:0;
    color:white;
    /*previous version
    text-shadow: 0 1px 3px #000;
    */
    text-shadow: 0 0px 0px rgba(255, 255, 255, 0.7);
    /*transform:translateX(245px);*/
    transform:translateX(100%);
    font-family: "Open Sans";
}

.category-shifter-overlay-btn {
    background-color:#dc2a27;
    padding:10px;
    text-align: center;
    margin-top:.5rem;
    display: inline-block;
    width:100%;
    transition:background-color .25s ease;
    border:none;
}

.category-shifter-overlay-btn:hover {
    background-color: rgb(190, 26, 26);
}

.category-shifter-overlay-btn-link {
    display:flex;
    align-items: center;
    justify-content: center;
    width:100%;
    color:#fff;
    text-decoration: none;
    text-shadow: 0 1px 3px #000;
}

.category-shifter-overlay-btn-link:hover {
    color:#fff;
    text-decoration: none;
}


.category-shifter-overlay{
    position: absolute;
    top:0;
    left:0;
    height:100%;
    transition: opacity .35s linear, background-color .35s linear;
}

/*
.hover-effect:hover .hover-effect-image {
    transform:scale(1.1);
    opacity:.5;
}
*/

/*
.category-shifter-overlay:hover .category-shifter-overlay-heading {
    transform: translateY(0);
}

.category-shifter-overlay:hover .category-shifter-overlay-paragraph {
    transform:translateX(0);
}
*/

/**landscape styles**/
@media screen and (min-width:563px) and (max-width:767px) and (orientation: landscape){
    .cs-608 {
        margin-top:4rem;
    }
}

@media screen and (min-width:563px) and (max-width:767px) and (orientation: landscape){
    .cs-304 {
        /*margin-top:5rem;*/
        margin-top:20rem;
    }
}


/**portrait styles**/
@media screen and (min-width:280px) and (max-width:329px) and (orientation: portrait){
    .cs-608 {
        margin-top:calc(2rem - ((329px - 100vw) * .6531));
    }
}

@media screen and (min-width:280px) and (max-width:767px) and (orientation: portrait){
    .cs-304 {
        margin-top:calc(20rem - ((767px - 100vw) * .46));
    }
}

@media screen and (min-width:330px) and (max-width:767px) and (orientation: portrait){
    .cs-608 {
        margin-top:calc(12rem - ((767px - 100vw) * .3661));
    }
}



@media screen and (min-width:768px) {
    .category-shifter-container {
        flex-direction: row;
        justify-content: center;
    }
}


@media screen and (min-width:768px) and (max-width:890px){
    .category-shifter-overlay-paragraph {
        text-align: center;
        padding:10px 20px;
        margin-top: calc(1rem - ((890px - 100vw) * 0.1311));
        font-size: calc(16px - ((890px - 100vw) * 0.0164));
        line-height: calc(24px - ((890px - 100vw) * .0328));
        margin-bottom:0;
        color:white;
        text-shadow: 0 1px 3px #000;
        transform:translateX(100%);
        font-family: "Open Sans";
    }
}


