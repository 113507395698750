.ksm-show {
    display:flex;
}

.ksm-wrapper {
    position:fixed;
    top:0;
    left:0;
    width:100%;
    z-index: 4;
    height:100%;
    padding-top: 16px;
    background-color: #fff;
    transform:translateY(-25%);
    transition:transform .3s ease;
    border:1px solid black;
}

.ksm-container {
    width:100%;
    padding:8px 0px 8px 16px;
    background-color: #Dc291E;
    
}

.ksm-cancel-btn {
    display:inline-flex;
    justify-content: center;
    align-items: center;
    font-weight: 800;
    color:#fff;
    line-height: 1;
    padding:8px 16px;
    margin:0;
    background-color: transparent;
    font-size: 14px;
    font-family: "Open Sans";
    border:0;
}


@media screen and (max-width:479px) {
    .ksm-list-shape {
        box-shadow: none;
        border-radius: 0;
        top:55px;
    }

    .ksm-list-item-shape:first-child, .ksm-list-item-shape:last-child {
        border-radius: 0;
    }
}
