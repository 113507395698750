.shop-product-detail-qty-limit-msg {
    border-radius: 4px;
    padding: 8px;
    margin-bottom: 16px;
    color: rgb(66, 64, 70);
    background-color: rgb(247, 247, 247);
    border: 1px solid rgb(232, 232, 232);
    font-size: 16px;
    font-family: "Open Sans";
    line-height: 16px;
    text-align: center;
}

.shop-product-detail-qty-limit-individual-msg {
    display:flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    border-radius: 4px;
    padding:8px;
    margin-bottom: 16px;
    color: #FF0000;
    background-color: #F2DFDE;
    border: 1px solid #dc291e;
    font-size: 16px;
    font-family: "Open Sans";
    line-height: 16px;
    min-height: 64px;
    /*background-image: url('http://localhost:9500/oops.png');
    background-size: 25px 25px;
    background-repeat: no-repeat;
    background-position: 115px center;
    min-height: 64px;*/
}

.product-qty-limit-msg-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.product-qty-limit-msg-img {
    display: none;
}

@media (min-width:575px) and (max-width:1023px) {
    .product-qty-limit-msg-img {
        display:inline-block;
        width:25px;
        height:25px;
        margin-right:8px;
    }
}





