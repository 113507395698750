.related-products-layout-wrapper {
    width:100%;
    position: relative;
}

.shop-products-container-wrapper {
    padding-top:16px;
}

.related-product-search-container {
    flex:1;
}

.related-products-search-keyword-heading-wrapper {
    padding:0;
    height:60px;
}

.related-products-search-keyword-heading-container {
    width:100%;
    font-family: "Open Sans";
    text-align: center;
}

.related-products-search-keyword-heading {
    font-weight: 800;
    line-height: 32px;
    min-height: 32px;
    font-size: 24px;
    color:#414142;
    margin:0 0 12px;
}

.related-products-restricted-keyword-heading {
    font-size: 16px;
    color:#414142;
    /*margin:0 0 32px;*/
}

.highlight-search-filter-area-text {
    font-weight: 700;
    line-height: 24px;
    padding: 8px;
    cursor: pointer;
    display: flex;
    align-items: center;
    color: #414142;
}

.no-highlight-search-filter-area-text {
    line-height: 24px;
    padding: 8px;
    cursor: pointer;
    display: flex;
    align-items: center;
    color: #414142;
}

.related-products-category-filter-container {
    padding:0;
    width:100%;
    font-family: "Open Sans";
}

.related-products-browse-by-category-footer-wrapper {
    background-color: rgb(245, 245, 245);
    display:flex;
    justify-content: center;
}

.related-products-no-results-msg-footer-container {
    max-width: 1000px;
    padding: 32px 16px;
    flex-basis: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.related-products-footer-container {
    width:100%;
}

.related-products-no-results-msg-footer-wrapper {
    display:flex;
    justify-content: center;
}

.related-products-browse-by-category-footer-container {
    display:block;
    max-width: 1000px;
    padding:32px 16px;
    width:100%;
}

.related-products-browse-by-category-heading, .related-products-no-results-msg-heading {
    font-size: 24px;
    font-weight: 700;
    color: rgb(0, 0, 0);
    word-break: break-word;
    margin:0;
    font-family: "Open Sans";
}

.related-products-no-results-suggestion-container {
    font-size: 16px;
    color: rgb(66, 64, 70);
    line-height: 24px;
    margin-top: 32px;
    font-family: "Open Sans";
}

.rps-no-results-suggestion {
    margin-bottom:16px;
}

.rps-no-results-suggestion-list {
    padding:0;
    margin:0;
    list-style-type: none;
}

.rps-no-results-suggestion-list-item {
    margin-bottom:16px;
}

.rps-no-results-suggestion-list-item::before {
    content: '.';
    margin-right: 16px;
    width: 5px;
    display: inline-block;
    top: -4px;
    position: relative;
    font-size: 32px;
}

.related-products-store-department-browse-list {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    height:100%;
    margin:32px 0 0 0;
    padding:0;
    list-style-type: none;
}

.related-products-store-department-browse-list-item {
    margin-bottom:14px;
}

.related-products-store-department-browse-list-item-link {
   color:rgb(85, 85, 85);
   line-height: 24px;
   font-size: 16px;
   font-family: "Open Sans";
   text-decoration: none;
}

.related-products-store-department-browse-list-item-link:hover {
    color:rgb(85, 85, 85);
}

.related-product-category-filter-heading {
    display:none;
    align-items: center;
    font-size: 16px;
    font-family: "Open Sans";
    /*border-radius: 4px;*/
    margin-bottom: 16px;
    min-height:58px;
    color:#414142;
    font-weight: 800;
}

.related-product-category-sort-by-heading {
    font-family: "Open Sans";
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 8px 8px;
    margin-bottom: 16px;
    border-bottom: 1px solid rgb(238, 236, 236);
    line-height: 24px;
}

.related-products-store-department-filter-list {
    border-bottom:1px solid rgb(238, 238, 238);
    list-style-type: none;
    padding:0 0 16px 0;
    margin-bottom:32px;
    color:#414142;
}

.related-products-store-department-filter-list-item {
    padding:0;
    cursor: pointer;
}

.related-products-store-department-filter-list-item:first-child {
    font-weight: 800;
    cursor: auto;
    margin: 16px 0 8px;
    padding:0 8px;
}

.related-products-store-department-category-filter-list {
    list-style-type: none;
    padding:0;
    margin-left:1.25rem;
}

.sort-by-results-heading {
    display: inline-flex;
    align-items: center;
    height: 16px;
    font-weight:800;
    color:#414142;
}

.sort-by-form {
    margin-left:.75rem;
}

.sort-by-form-group {
    padding:0;
    margin-bottom:0;
}

.card-body-shape {
    display:flex;
    flex-direction: column;
}

.related-products-price-slider-wrapper {
    display:none;
}

.rp-loader-animation-container {
    
    width:100%;
    display:flex;
    justify-content: center;
    /*margin-top:100px;*/
    /*margin-top:264px;*/
    padding-top:100px;
    height:3500px;
    
    /*
    position:absolute;
    display: flex;
    justify-content: center;
    flex:1 1;
    transform: translate(-50%, 264px);
    top:0;
    left:50%;
    */
}

@media (min-width:768px){
    .related-products-no-results-msg-footer-container {
        padding:64px 16px 0px;
    }

    .related-products-no-results-msg-footer-wrapper {
        padding: 0 0 32px;
    }

    .related-products-browse-by-category-footer-wrapper {
        height:400px;
    }

    .related-products-browse-by-category-footer-container {
        padding:64px 16px 0px;
    }

    .related-products-store-department-browse-list {
        height:215px;
    }
}

@media screen and (min-width:1024px){
    .related-products-restricted-keyword-heading {
        margin-top: -8px;
    }

    .related-product-category-sort-by-heading {
        /*background-color: #F7F7F7;*/
        padding:16px 8px;
        min-height:58px;
    }

    .related-product-category-filter-heading {
        padding:16px 8px;
        /*background-color: rgb(247,247,247);*/
        border-bottom: 1px solid #eeecec;
        display: flex;
    }

    .related-products-category-filter-container {
        padding:0;
        width:220px;
        margin-right:16px;
        font-family: "Open Sans";
    }

    /*
    .highlight-search-filter-area-text:hover {
        background-color: #F7F7F7;
    }
    */



    .related-products-price-slider-wrapper {
        display:block;
    }
}

.no-highlight-search-filter-area-text:hover {
    background-color: #F7F7F7;
}