.store-structure-depts-wrapper {
    width:100%;
}

.store-structure-depts-container {
    position:relative;
    width:100%;
    max-width: 1000px;
    min-height:900px;
    padding:16px;
    margin:0 auto;
}

.ssds-loader-animation-container {
    position:absolute;
    width:100%;
    display:flex;
    justify-content: center;
    top:0;
    left:50%;
    transform:translate(-50%, 470px);
    /*margin-top: 50px;*/
    height:1500px;
}

.ssds-loader2-animation-container {
    width:100%;
    display:flex;
    justify-content: center;
    margin-top: 100px;
    height:4500px;
}

.ssds-loader3-animation-container {
    position:absolute;
    width:100%;
    display:flex;
    justify-content: center;
    margin-top: 110px;
    height:4500px;
    top:0;
    left:0;
    background-color: #fff;
}

/**table styles**/
.ssds-table-container {
    max-width: 1000px;
    margin:0 auto;
    overflow-x: auto;
}

.ssds-table {
    table-layout: fixed;
    width:100%;
    min-width:968px;
    border-collapse: separate;
    border-spacing:0;
    border-radius:4px;
    overflow: hidden;
    border:1px solid black;
}

.ssds-table th, .ssds-table td {
    border:1px solid black;
    padding:4px 8px;
    font-family: "Open Sans";
}

.ssds-table th {
    background-color: #d6d4d4;
    color:#414142;
    font-weight: 700;
    padding:8px;
    border-top:0;
    border-right:0;
    border-bottom:0;
}

.ssds-table th:first-child {
    border-left:0;
}

.ssds-table td {
    border-right:0;
    border-bottom:0;
    height:94px;
}

.ssds-table tr td:first-child {
    border-left:0;
}

.ssds-table .th-dept {
    width:25%;
}

.ssds-table .th-dc {
    width:25%;
}

.ssds-table .th-img {
    width:20%;
}

.ssds-table .th-action {
    width:10%;
}

.ssds-table .th-date {
    width:20%;
}

.ssds-td {
    display:-webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 2;
}

.ssds-table .td-action {
    padding:0;
}

.ssds-img {
    text-align: center;
}

.ssdbi:nth-child(2) {
    display:flex;
    flex-direction: row-reverse;
}

.ssdbi:nth-child(2) svg {
    transform: scaleX(-1);
}

.ssdbi:nth-child(1), .ssdbi:nth-child(3) {
    display:none;
}

.ssds-table-image {
    max-width: 100%;
    max-height:85px;
}

@media (min-width:768px){
    .ssdbi:nth-child(2) {
        flex-direction: row;
    }

    .ssdbi:nth-child(2) svg {
        transform: unset;
    }

    .ssdbi:nth-child(1), .ssdbi:nth-child(3) {
        display:flex
    }
}