.shop-store-department-container {
    display: flex;
}

.shop-store-department-category-container {
    border:1px solid blue;
}

.shop-store-department-subtitle-heading {
    text-align: center;
    margin-top:30px;
    font-family: Arial, Helvetica, sans-serif;
    color:#222222;
}

.shop-store-department-category-list {
    padding:0;
    list-style-type: none;
    display:flex;
    /*justify-content: center;*/
    flex-wrap: wrap;
}

.shop-store-department-category-list-item {
    width:180px;
    height:350px;
    box-sizing: border-box;
}

.shop-store-department-category-name-link-container {
    height:80px;
    box-sizing: border-box;
}

.shop-store-department-category-name-link {
    color:#333;
    font-weight: 700;
    text-align: center;
    text-decoration: none;
    display: block;
}

.shop-store-department-product-category-name-link {
    text-align:center;
    display: block;
    color:#333;
}

.shop-store-department-category-image-link-container{
    text-align: center;
}

.shop-store-department-category-image-link {
    text-decoration: none;
    width:152px;
}

.shop-store-department-category-image {
    display: inline-block;
    vertical-align: middle;
    max-width: 100%;
    height:auto;
}

.shop-store-department-fa-angle-right {
    margin:0 8px;
    color:#717172;
    display: inline-flex;
    align-items: center;
}

.svg-right-angle {
    width:8px;
}

.shop-store-department-breadcrum-container {
    display:flex;
    justify-content: center;
}

.shop-store-department-home-breadcrum-link, .shop-store-department-overview-breadcrum-link {
    color:#717172;
    text-decoration: none;
}

.shop-store-department-home-breadcrum-link:hover, .shop-store-department-overview-breadcrum-link:hover {
    color:#717172;
    text-decoration: underline;
}

.shop-store-department-name-breadcrum-link {
    color:#414142;
    text-decoration: none!important;
}

.shop-store-department-sidebar-list {
    padding:0;
    list-style-type: none;
    margin-left:1rem;
}

.shop-store-department-sidebar-list-refinement {
    list-style-type: none;
    font-family: "Open Sans";
}

.shop-store-department-sidebar-heading {
    font-weight: 700;
    padding:.75rem 0;
}

.shop-store-department-sidebar-list-item-link, .shop-store-department-sidebar-list-item-link:hover {
    text-decoration: none;
    color:#414142;
    padding:.75rem 0;
    display:block;
}

.shop-store-department-category-count {
    margin-left:.25rem;
    color:grey;
}

.shop-special-department-sidebar-list {
    padding:0;
}

.shop-special-department-sidebar-list-item {
    list-style-type: none;
    padding:0;
}

.highlight-department-text {
    font-weight: 800;
    line-height: 24px;
    padding:8px;
    cursor: pointer;
    display: flex;
    align-items: center;
    color:#414142;
}

.shop-special-department-category-sidebar-list-container {
    padding:0;
}

.shop-special-department-category-sidebar-list {
    margin:0;
}

.shop-special-department-category-sidebar-list-item:hover {
    background-color: #F7F7F7;
}

.shop-special-department-sidebar-list-item-link, .shop-special-department-category-sidebar-list-item-link,
.shop-special-department-sidebar-list-item-link:hover, .shop-special-department-category-sidebar-list-item-link:hover  {
    text-decoration: none;
    color:#414142;
    line-height: 24px;
    padding: 8px;
    cursor: pointer;
    display:flex;
    align-items: center;
}

.shop-link-shape-container {
    display:flex;
    align-items: center;
    line-height: 14px;
    padding:16px 0;
    font-family: "Open Sans";
}

.svg-chevron-shape {
    transform: rotate(180deg);
    /*transform: scaleX(-1);*/
    width:12px;
    height:12px;
    margin-right:8px;
}

.shop-link-shape, .shop-link-shape:hover  {
    text-decoration: none;
    color:#414142;
    display: flex;
    align-items: center;
    font-size:14px;
    font-family: "Open Sans";
    font-weight:800;
}

@media screeen and (min-width:768px){
    .svg-right-angle {
        margin:0 8px;
    }
}
