.store-details-check-btn {
    font-family: "Open Sans";
    font-size:14px;
    padding:12px 24px;
    border-radius: 48px;
    min-width:140px;
    border:1px solid rgb(215, 215, 216);
    background-color: transparent;
    color:#007DB3;
    font-weight: 800;
    background-color: transparent;
    appearance: none;
}

.store-details-check-btn-checked {
    font-family: "Open Sans";
    font-size:14px;
    color:#008800;
    font-weight: 800;
    appearance: none;
    border:1px solid rgb(215, 215, 216);
    background-color: #F5F5F5;
    padding:12px 24px;
    border-radius: 48px;
    min-width:140px;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 20px;
}

.store-details-check-btn-checked-svg {
    margin-right:8px;
}