.admin-product-reviews-wrapper {
    width:100%;
}

.admin-product-reviews-container {
    position:relative;
    width:100%;
    max-width: 1000px;
    min-height: 950px;
    margin:0 auto;
    padding:16px;
}

.admin-product-review-container {
    display:flex;
}

.admin-product-reviews-heading {
    text-align: center;
    font-family: "Open Sans";
    font-size: 26px;
    color: #414142;
    font-weight: 800;
    margin: 40px 0 40px
}

.admin-pr-reset-prs-btn-container {
    display:flex;
    width:100%;
    align-items: center;
    justify-content: flex-end;
}

.admin-pr-reset-items-btn {
    padding:8px 32px;
    appearance: none;
    border:none;
    color:#fff;
    background-color: blue;
    border-radius: 4px;
    font-family: "Open Sans";
    flex:1;
    font-weight: 600;
    font-size: 14px;
    margin:0 0 16px;
}

.admin-product-reviews-select-btn-wrapper{
    display: flex;
    align-items: center;
    width:100%;
    justify-content: space-around;
}

.admin-product-reviews-select-btn {
    border-radius: 4px;
    border-style: none;
    background-color: green;
    color:white;
    padding:8px 15px;
    font-weight: 800;
    font-family: "Open Sans";
    font-size: 14px;
}

.admin-product-reviews-select-btn:nth-child(2) {
    background-color: red;
}

/**search box styles**/
.admin-search-form {
    display:flex;
    flex-direction: row;
    flex-wrap: wrap;
    font-family: "Open Sans";
    margin-bottom:32px;
}

.admin-search-box-container {
    flex:1 1 100%;
    display:flex;
    flex-wrap: wrap;
    margin-bottom:8px;
}

.admin-search-box-label {
    flex:1 1 100%;
    margin-bottom:4px;
}

.admin-search-secondary-label {
    display:inline-flex;
    align-items: center;
    font-size: 14px;
    margin-bottom:2px;
}

.admin-search-box {
    appearance: none;
    border:1px solid #707070;
    flex:1 1 100%;
    width:100%;
    max-width: 768px;
    height:40px;
    border-radius: 4px;
    outline:none;
    padding:0 4px;
}

.admin-rating-container {
    flex:1 1 100%;
    display:flex;
    flex-wrap: wrap;
    margin-bottom:16px;
}

.admin-rating-selector-label {
    flex:1 1 100%;
    margin-bottom:4px;
}

.admin-rating-selector-wrapper {
    flex:1 1 100%;
}

.admin-search-form-btn-container {
    display:flex;
    align-items: center;
    width:100%;
}

.admin-add-item-btn-container {
    display:flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom:20px;
}

.admin-search-form-btn, 
.admin-add-item-btn,
.admin-reset-items-btn {
    appearance: none;
    border:none;
    border-radius: 4px;
    color:#fff;
    font-weight: 600;
    font-size:14px;
    background-color: rgb(220, 41, 30);
    transition: background-color .25s ease-in-out;
    padding:8px 32px;
    min-width:120px;
    display:flex;
    align-items: center;
    justify-content: center;
    font-family: "Open Sans";
    flex:1 1 100%;
}


.admin-search-form-btn:hover, 
.admin-add-item-btn:hover {
    background-color: rgb(192, 37, 37);
}

.admin-reset-items-btn {
    margin:8px 0 0;
    background-color:blue;
}

.admin-reset-items-btn:hover {
    background-color:rgb(15, 15, 184);
}

.aprs-generic-error-msg-wrapper {
    width:100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin:16px auto 0;
}

.aprs-loaeder-animation-container {
    width:100%;
    display: flex;
    justify-content: center;
    margin-top: 100px;
    height:3500px;
}

.aprs-loader2-animation-container {
    position:absolute;
    width:100%;
    display: flex;
    justify-content: center;
    /*margin-top: 100px;*/
    top:0;
    left:50%;
    transform:translate(-50%, 100px);
    height:3500px;
}

.pr-generic-info-message-container {
    width:100%;
    display:flex;
    align-items: center;
    justify-content: center;
    font-family: "Open Sans";
    white-space: pre-line;
}

/**table styles**/
.aprs-table-container {
    width: 100%;
    margin: 0 auto;
    overflow-x: auto;
}

.aprs-table {
    table-layout: fixed;
    width: 100%;
    min-width: 968px;
    border-collapse: separate;
    border-spacing: 0;
    border-radius: 4px;
    border:1px solid black;
    overflow: hidden;
}

.aprs-table th, 
.aprs-table td {
    border:1px solid black;
    padding:4px 8px;
    font-family: "Open Sans";
    text-align: left;
}

.aprs-table td {
    height:94px;
}

.aprs-td {
    display:-webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 2;
}

.aprs-table .td-img img {
    object-fit: cover;
    width:100%;
}

.aprs-table .td-action {
    padding:0;
}

.aprs-table th {
    background-color: #d6d4d4;
    color:#414142;
    font-weight: 700;
    padding:8px;
    border-top:0;
    border-right:0;
    border-bottom:0;
}

.aprs-table th:first-child {
    border-left:0;
}

.aprs-table td {
    border-right:0;
    border-bottom:0;
}

.aprs-table tr td:first-child {
    border-left:0;
}

.aprs-table th span {
    display:inline-flex;
}

.aprs-table th:nth-child(1) {
    width:16%;
}

.aprs-table th:nth-child(2) {
    width:19%;
}

.aprs-table th:nth-child(3) {
    width:7%;
}

.aprs-table th:nth-child(4) {
    width:10%;
}

.aprs-table th:nth-child(5) {
    width:24%;
}

.aprs-table th:nth-child(6) {
    width:13%;
}

.aprs-table th:nth-child(7) {
    width:11%;
}

.aprbi:nth-child(1) {
    display:flex;
    flex-direction: row-reverse;
}

.aprbi:nth-child(1) svg {
    transform: scaleX(-1);
}

.aprbi:nth-child(2) {
    display:none;
}


@media (min-width:575px) {
    .admin-search-secondary-label {
        display:none;
    }
    
}

@media (min-width:768px){
    .admin-search-form {
        display:flex;
        flex-direction: column;
    }

    .admin-rating-container {
        max-width:100px;
        display:flex;
        flex-wrap: wrap;
    }

    .aprbi:nth-child(1) {
        flex-direction: row;
    }

    .aprbi:nth-child(1) svg {
        transform: unset;
    }

    .aprbi:nth-child(2) {
        display:flex
    }

    .admin-add-item-btn-container {
        justify-content: space-between;
        flex-wrap:nowrap;
    }

    .admin-search-form-btn, 
    .admin-add-item-btn,
    .admin-reset-items-btn {
        flex:unset;
    }

    .admin-reset-items-btn {
        margin:0;
    }

    .admin-pr-reset-items-btn {
        flex:unset;
        margin:0;
    }

}