.shop-product-mini-cart-category-list-container {
    padding:36px 16px 0px;
    border-left: 1px solid rgb(238, 236, 236);
    flex:1 1 0%;
    overflow-y: auto;
    overscroll-behavior-y: contain;
}

.shop-product-mini-cart-category-list {
    display: flex;
    justify-content: center;
    flex-direction: column;
    list-style-type: none;
    padding:0;
    margin:0;

}

.shop-product-mini-cart-list-wrapper-closed {
    position:absolute;
    right:0;
    /*top:44px;*/
    top:112px;
    /*original version
    top:0;*/
    width:78px;
    max-width:380px;
    height:100vh;
    z-index: 1;
    background-color:#fff;
}

.shop-product-mini-cart-list-wrapper-opened {
    position:absolute;
    right:0;
    top:112px;
    /*original version
    top:0;*/
    width:100%;
    height:100vh;
    background-color: #fff;
    transition: width 0s ease;
    z-index: 1;
    box-shadow: rgb(27 31 35 / 20%) 0px 0px 25px 20px;
}

.shop-product-mini-cart-list-container {
    display: flex;
    flex-direction: column;
    top:0;
    padding:0;
    height:100%;
    overflow:hidden;
}

.shop-product-mini-cart-heading-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color:rgb(220, 41, 30);
    color:#fff;
    padding:12px 8px;
    border-left:1px solid rgb(199, 38, 28);
    line-height: 1;
    font-weight: 800;
    font-size: 16px;
    font-family: "Open Sans";
    border:none;
}

.shop-product-mini-cart-heading-angle-left-icon-container {
    width:100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.mini-cart-left-arrrow {
    width:16px;
    height:16px;
    transform: scaleX(-1);
    margin-right: 8px;
}

.mini-cart-right-arrrow {
    width:16px;
    height:16px;
    margin-right: 8px;
}

/*
.shop-product-mini-cart-heading {
    margin:0 0 0 .25rem;
    display:block;
    font-weight: 600;
    font-size: 18px;
}
*/

.shop-product-mini-cart-category-heading {
    font-size: 20px;
    font-weight: 800;
    font-family: "Open Sans";
    margin: 0 0 8px;
}

.shop-product-mini-cart-view-cart-footer {
    /*position:fixed;
    bottom:0;*/
    display: block;
    padding:16px;
    background-color: #fff;
    border-left: 1px solid rgb(238, 236, 236);
    box-shadow: rgb(0 0 0 / 10%) 0px 2px 10px;
    width:100%;
    flex-direction: column;
    /*position:absolute;
    left:0;*/
    position:fixed;
    bottom:0;
}

.mini-cart-item-total-container {
    display:flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom:12px;
}

.mini-cart-item-total-heading {
    font-family:"Open Sans";
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
}

.mini-cart-item-total-amount {
    font-family: "Open Sans";
    font-size: 20px;
    line-height: 28px;
    font-weight: 800;
}

.shop-product-mini-cart-view-cart-btn-container {
    flex-grow: 1;
    padding:0;
    display:flex;
    justify-content: center;
}

.shop-product-mini-cart-view-cart-btn {
    flex-grow:1;
    font-size: 16px;
    border-radius: 4px;
    padding:8px 16px;
    font-weight: 600;
    line-height: 20px;
    color:#fff;
    background-color: rgb(220, 41, 30);
    transition: background-color 250ms ease-in-out 0s;
    font-family: "Open Sans";
    border:1px solid transparent;
}

.shop-product-mini-cart-view-cart-btn:hover {
    background-color: rgb(192, 37, 37);
    border:1px solid rgb(192, 37, 37);
}

.shop-product-mini-cart-view-cart-btn-link {
    display:flex;
    align-items: center;
    justify-content: center;
    width:100%;
    text-decoration: none;
    color:#fff
}

.shop-product-mini-cart-view-cart-btn-link:hover {
    color:#fff;
}

.shop-product-mini-cart-category-list-item {
    width:100%;
    margin:0 0 32px 0;
}

.shop-product-mini-cart-product-list {
    list-style-type: none;
    padding:0;
    margin-bottom:.375rem;
}

/****test class***/
.shop-product-mini-cart-test-container {
    padding:16px 0;
    display:flex;
    position:relative;
    background-color: white;
    border-top:1px solid rgb(215, 215, 216);
    overflow-x: hidden;
}

.shop-product-mini-cart-test-container {
    border-top:0;
    border-bottom:1px solid rgb(215, 215, 216)
}

.shop-product-mini-cart-test-container:last-child {
    border-bottom:1px solid rgb(215, 215, 216)
}

.shop-product-mini-cart-product-list-item {
    display:grid;
    grid-template-rows: auto auto;
    grid-template-columns: auto 64px 2fr 2fr 2fr 1fr 1fr;
    grid-gap:8px;
    padding:0;
    width:100%;
    background-color: #fff;
    /*previous version
    transition: all .125s ease-in-out;*/
    transition: all 0s ease-in-out;
    position:relative;
}

.shop-product-mini-cart-product-list-item-overlay {
    display:none;
    position:absolute;
    width:100%;
    height:100%;
    background-color:hsla(0,0%,100%,.4);
}

.move-mini-cart-list-item {
    transform:translateX(280px);
    opacity: .4;
}

/*
.shop-product-mini-cart-product-list-item:last-child {
    border-bottom: 1px solid lightgrey;
}
*/

.shop-product-mini-cart-product-list-item-delete-btn-container{
    display: flex;
    justify-content: center;
    align-items: center;
    grid-row-start:1;
    grid-row-end:2;
    grid-column-start: 1;
    grid-column-end: 2;
    margin:0; 
}

.shop-product-mini-cart-product-list-item-delete-btn {
    border:none;
    background-color: transparent;
    color:#909092;
    padding:0;
    cursor: pointer;
}

.mini-cart-list-delete-btn-svg {
    color:rgb(65, 65, 66);
}



.shop-product-mini-cart-product-list-item-image-container {
    display: flex;
    align-items: center;
    grid-row-start:1;
    grid-row-end:2; 
    grid-column-start: 2;
    grid-column-end: 3;
}

.shop-product-mini-cart-product-list-item-name-container {
    grid-row-start:1;
    grid-row-end:2; 
    grid-column-start: 3;
    grid-column-end: 7;
}

.shop-product-mini-cart-product-list-item-image {
    width:48px;
    height:48px;
}

.shop-product-mini-cart-product-list-item-name {
    font-size: 14px;
    color:#414142;
    font-family: "Open Sans";
}

.shop-product-mini-cart-product-list-item-price-container {
    width:100%;
    min-height:37px;
    grid-row-start:1;
    grid-row-end:2; 
    grid-column-start: 7;
    grid-column-end: 8;
}

.shop-product-mini-cart-product-list-item-total-price {
    color:rgb(65,65, 66);
    /*font-weight: 700;
    font-size: 14px;*/
    /*border:1px solid black;*/
    text-align: right;
    font-weight: 800;
    font-size: 16px;
    font-family: "Open Sans";
}

.price-slash {
    position:relative;
}

.price-slash::before {
    position:absolute;
    content:"";
    top:40%;
    border-top:2px solid rgba(12, 12, 12, 0.3);
    right:-4px;
    width:3.5rem;
    transform:rotate(-20deg);
}

.shop-product-mini-cart-product-list-item-total-price-sale-container {
    padding:0;
}

.shop-product-mini-cart-product-list-item-total-price-sale {
    color:red;
    font-weight: 700;
    font-size: 14px;
    /*border:1px solid black;*/
    text-align: right;
}

.shop-product-mini-cart-product-list-item-each-price {
    display: flex;
    color:black;
    justify-content: flex-end;
    /*font-weight: 700;*/
    font-size: 11px;
    /*border:1px solid black;*/
    /*text-align: right;*/
}

.each-forward-slash-shape {
    font-size: 10px;    
}

.shop-product-mini-cart-product-list-item-quantity-container {
    width:120px;
    grid-column-start:4;
    grid-column-end:5;
    display:flex;
    justify-content: flex-end;
    position:relative;
    border-radius: 4px;
    grid-row-start:2;
    grid-row-end:3; 
    grid-column-start: 6;
    grid-column-end: 8;
    
}

.shop-product-mini-cart-product-list-item-quantity-button-container {
    display:flex;
    height:100%;
    position:relative;
}

.expand-item-quantity-button-container-each {
    position:absolute;
    box-shadow: 0 0 4px 0 rgba(0,0,0,.25);
    z-index: 1;
}

.shop-product-mini-cart-product-list-item-quantity-increment-btn, .shop-product-mini-cart-product-list-item-quantity-decrement-btn {
    height:36px;
    width:40px;
    border:1px solid rgb(238, 236, 236);
    text-align: center;
    color:#333;
    background-color: white;
}

.shop-product-mini-cart-product-list-item-quantity-increment-btn:hover, .shop-product-mini-cart-product-list-item-quantity-decrement-btn:hover {
    background-color: rgb(228, 226, 226);
}

.shop-product-mini-cart-product-list-item-quantity-increment-btn {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}

.shop-product-mini-cart-product-list-item-quantity-decrement-btn {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}

.shop-product-mini-cart-product-list-item-quantity-each {
    text-align: center;
    height:36px;
    width:3rem;
    font-weight: 800;
    font-family: "Open Sans";
    border-top: 1px solid rgb(238, 236, 236);
    border-bottom: 1px solid rgb(238, 236, 236);
    border-left:none;
    border-right:none;
    color: rgb(65, 65, 66);

}

.shop-product-mini-cart-product-list-item-quantity-each:focus, 
.shop-product-mini-cart-product-list-item-quantity-increment-btn:focus,
.shop-product-mini-cart-product-list-item-quantity-decrement-btn:focus {
    outline-style: none;
}

.shop-product-mini-cart-product-list-item-quantity-button-container-pound {
    display:flex;
    width:100%;
    height:100%;
    position:relative;
    border-radius: 4px;
    border:1px solid rgb(238, 236, 236);
}

.shop-product-mini-cart-product-list-item-quantity-pound {
    text-align: center;
    height:40px!important;
    /*width:90px;*/
    width:64px;
    font-weight: 800;
    border:1px solid #ccc!important;
    border-radius: .25rem;
    font-family: "Open Sans";
    color: rgb(65, 65, 66);
}

.shop-product-mini-cart-product-list-item-quantity-pound:focus {
    text-align: center;
    height:40px!important;
    /*width:90px;*/
    width:64px;
    font-weight: 800;
    border:1px solid #ccc!important;
    outline-style: none;
    font-family: "Open Sans";
    color: rgb(65, 65, 66);
}

/**may need to remove this style because it doesn't exist in component**/
.shopping-cart-product-quantity-selector-container {
    display:flex;
    width:7rem;
}

/**may need to remove this style**/
.shopping-cart-product-quantity-selector-btn-container {
    position: relative;
    width:120px;
    display:flex;
    align-items: center;
}

.shopping-cart-product-quantity-selector-btn {
    border-radius: 4px;
    height:100%;
    width:100%;
    background-color: transparent;
    appearance: none;
    font-family: "Open Sans";
    color: rgb(65, 65, 66);
    font-weight: 800;
    padding:8px;
    background-image: url(https://server.kando-proto-3.com/down-arrow.png);
    background-repeat: no-repeat;
    background-position: right center;
    background-size: auto 24px;
    background-origin: content-box;
    border:none;
}

.expand-item-quantity-button-container-pound {
    z-index: 2;
    width:64px;
    box-shadow: none;
}

.expand-item-price-container-pound {
    display:flex;
    justify-content: flex-start;
    flex-direction: column;
    width:100%;
}


/*hide arrows for input[type="number"]*/
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}


.delete-mini-cart-product-container {
    position:absolute;
    left:16px;
    background-color: white;
    padding:0;
    z-index: 1;
    display: none;
}

.delete-mini-cart-product-msg {
    padding:0;
    font-size: 14px;
    color:#414142;
    font-family: "Open Sans";
}

.delete-mini-cart-product-btn-container {
    margin-top:8px;
}

.delete-mini-cart-product-remove-btn {
    margin-left:8px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(220, 41, 30);
    border-radius: 4px;
    border:1px solid transparent;
    padding: 8px 16px;
    font-family: "Open Sans";
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    transition: background-color .25s ease-in-out;
    color:#fff;
}

.delete-mini-cart-product-remove-btn:hover {
    background-color: rgb(192, 37, 37);
}



.delete-mini-cart-product-cancel-btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    border-radius: 4px;
    border:1px solid rgb(46, 46, 47);
    padding:8px 16px;
    font-family: "Open Sans";
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    transition: background-color .25s ease-in-out;
    color:rgb(46, 46, 47);
}

.delete-mini-cart-product-cancel-btn:hover {
    background-color: rgb(46, 46, 47);
    color:#fff;
}

.hide-element {
    display:none;
}

.show-element {
    display:block;
}

.show-element-pound {
    display:flex;
    width:128px;
}

@media (min-width:380px) {
    .shop-product-mini-cart-list-wrapper-opened, .shop-product-mini-cart-view-cart-footer  {
        width:380px;
    }

    .shop-product-mini-cart-product-list-item-delete-btn-container {
        margin:0 8px 0 0;
    }
}