.ahpl-wrapper {
    padding:0 16px 200px;
    width:100%;
}

.ahpl-form-container {
    padding:16px;
    border-radius: 8px;
    border:1px solid rgb(177, 176, 176);
    margin:32px 0;
}

.ahpl-banner-form-container {
    display:grid;
    padding:16px;
    border-radius: 8px;
    border:1px solid rgb(177, 176, 176);
    grid-gap: 8px 16px;
    margin:32px 0;
}

.ahpl-form-fieldset {
    display:flex;
    flex-direction: column;
}

.ahpl-form-fieldset-checkbox {
    display:flex;
    align-items: center;
}

.ahpl-slider-image-wrapper {
    display:flex;
    flex-direction: column;
}

.ahpl-slider-image-container {
    width:100%;
    position:relative;
    height: 0;
    padding-bottom:100%;
}

.ahpl-slider-image {
    position: absolute;
    width:100%;
    height:100%;
    top:0;
    left:0;
    border-radius: 24px;
}

.ahpl-heading-caption-wrapper {
    text-align: left;
}

.ahpl-heading-label, .ahpl-caption-label {
    font-weight: 700;
}

.ahpl-heading {
    margin-top:16px;
    font-family: "Open Sans";
    color:#414142;
    font-size: 16px;
    line-height: 24px;
    font-weight: 800;
}

.ahpl-caption {
    margin-top:2px;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    font-family: "Open Sans";
}

.ahplic-view-banner-btn-container {
    text-align: right;
    margin:10px 0 5px;
}

.ahplic-view-banner-btn {
    border:none;
    color:#fff;
    background-color: rgb(45, 148, 45);
    padding:8px 32px;
    border-radius: 4px;
    font-weight: 600;
    font-size:14px;

}

.ahpl-new-item-label {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    margin: 16px 0 0 16px;
    color: #fff;
    font-weight: 600;
    background-color: #187806;
    padding: 0 3px;
    font-size: 14px;
}

.ahpl-no-img-msg-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    margin:32px 0 64px;
}

.ahpl-add-image-btn-container {
    width:100%;
    display:flex;
    align-items: center;
    justify-content: center;
    margin-top:32px;
}

.ahpl-add-image-btn {
    appearance: none;
    border:none;
    color:#fff;
    background-color:green;
    padding:8px 32px;
    border-radius:4px;
    min-height:40px;
    display:flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 14px;
    min-width:120px;
    flex:1;
}

.ahpl-generic-info-msg-container {
    width:100%;
    margin-bottom:64px;
}

@media (min-width:575px){
    .ahpl-banner-form-container {
        grid-template-columns: repeat(2, 1fr);
    }

    .ahpl-form-color-container {
        grid-column: 1/2;
        grid-row: 4/6;
    }
}

@media screen and (min-width:768px){
    .ahpl-wrapper {
        padding:0 32px 200px;
    }

    .ahpl-heading {
        margin-top:24px;
        font-size: 20px;
        line-height: 24px;
    }

    .ahpl-add-image-btn {
        flex:unset;
    }

}

@media (min-width:860px){
    .ahpl-banner-form-container {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media (min-width:1124px){
    .ahpl-banner-form-container {
        grid-template-columns: repeat(4, 1fr);
    }

    .ahpl-form-color-container {
        grid-column: 4/5;
        grid-row: 1/5;
    }
}

@media (min-width:1370px){
    .ahpl-banner-form-container {
        grid-template-columns: repeat(5, 1fr);
    }

    .ahpl-form-color-container {
        grid-column: 4/5;
        grid-row: 1/5;
    }

    .ahpl-form-fieldset-checkbox {
        grid-column: 5/6;
        grid-row:1/2;
    }
}