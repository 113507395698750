.edit-shop-product-review-username-container {
    display: flex;
    align-items: center;
    background-color: #F2F2F2;
    padding: 14px 17px;
    margin:13px 0 5px;
    height:62px;
}

.edit-shop-product-review-username {
    padding-right:1rem;
}

.edit-shop-product-review-username-btn {
    cursor: pointer;
    color:#004EA3;
}

.edit-shop-product-review-username-form-control {
    display:inline-block;
    min-width:228px;
    padding:.35rem 1rem;
    /*margin-right: .75rem;*/
    box-sizing: border-box;
    border-radius: .25rem;
    border:2px solid grey;
    outline-style: none;
}

.edit-shop-product-review-username-form-control-focus {
    border:2px solid black;
}

.edit-shop-product-review-username-form-control-error {
    /*background-color: rgb(242,223,222)!important;*/
    border:2px solid red!important;
}

.edit-shop-product-review-username-form-control-save-btn {
    color:white;
    background-color: blue;
    width:60px;
    padding:.45rem 0;
    margin-left:.25rem;
    border-radius: .25rem;
    border-style: none;
    font-size: 16px;
    font-weight: 600;
    outline-style: none!important;
}

.edit-shop-product-review-username-form-control-save-btn:nth-child(3){
    background-color: green;
    margin-left:.25rem;
    width:74px;
}