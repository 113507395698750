.shop-product-reviews-detail-container {
    border-top:1px solid lightgrey;
    padding:25px 0 15px;
    /*border:1px solid green;*/
}

.shop-product-reviews-detail-title-date-container {
    display: flex;
    justify-content: space-between;
    padding:12px 0;
}

.shop-product-reviews-detail-comment-container {
    padding:0;
    font-size: 13px;
}

.shop-product-reviews-detail-user-comment-container {
    padding:5px 0;
    font-size:12px;
}

.shop-product-reviews-detail-comment {
    margin:0;
}

.shop-product-reviews-detail-user-comment-link {
    font-size:16px;
}

.shop-product-reviews-detail-title {
    font-weight: 700;
}

.shop-product-reviews-detail-date {
    font-style:italic;
    font-size:13px;
}