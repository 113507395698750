.sspscs-wrapper {
    width:100%;
}

.sspscs-container {
    position:relative;
    width:100%;
    max-width: 1440px;
    min-height: 900px;
    padding:16px;
    margin:0 auto;
}

.psc-search-secondary-label {
    display: inline-flex;
    align-items: center;
    font-size: 14px;
    margin-bottom: 2px;
}

.sspscs-table-container {
    width: 100%;
    margin: 0 auto;
    overflow-x: auto;
}

.sspscs-table {
    table-layout: fixed;
    width: 100%;
    min-width: 1408px;
    border-collapse: separate;
    border-spacing: 0;
    border-radius: 4px;
    border:1px solid black;
    overflow:hidden;
}

.sspscs-table th, 
.sspscs-table td {
    border:1px solid black;
    padding:4px 8px;
    font-family: "Open Sans";
    text-align: left;
}

.sspscs-td {
    display:-webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 2;
}

.sspscs-table .td-action {
    padding:0;
}

.sspscs-table .sspscs-img {
    text-align: center;
}

.sspscs-table th {
    background-color: #d6d4d4;
    color:#414142;
    font-weight: 700;
    padding:8px;
    border-top:0;
    border-right:0;
    border-bottom:0;
}

.sspscs-table th:first-child {
    border-left:0;
}

.sspscs-table td {
    border-right:0;
    border-bottom:0;
    height:94px;
}

.sspscs-table tr td:first-child {
    border-left:0;
}

.sspscs-table th span {
    display:inline-flex;
    width:100%;
}

.sspscs-table .th-psc-dept {
    width:12%;
}

.sspscs-table .th-psc-dc {
    width:11%;
}

.sspscs-table .th-psc-pc {
    width:11%;
}
.sspscs-table .th-psc-psc {
    width:12%;
}

.sspscs-table .th-psc-img {
    width:14%;
}

.sspscs-table .th-psc-sf {
    width:12%;
}
.sspscs-table .th-psc-st {
    width:12%;
}
.sspscs-table .th-psc-date {
    width:9%;
}
.sspscs-table .th-psc-action {
    width:7%;
}

.sspscs-table-image {
    max-width:100%;
    max-height:85px;
}

@media (min-width:720px){
    .psc-search-secondary-label {
        display: none;
    }
}