.hplib-wrapper {
    max-width: 1440px;
    margin:0 auto 128px;
    position:relative;
    overflow: hidden;
}

.hplib-item-slider-picker-wrapper {
    width:100%;
    max-width: 1440px;
    padding:0;
    height:538px;
    position: absolute;
    z-index: 5;
    background-color: #fff;
    transform: translateX(0%);
    transition:transform .3s ease-in-out;
    box-shadow: 0px 0px 8px -2px #414142;
    /*box-shadow: none;*/

}

.hplib-img-slider-picker-inner-wrapper {
    width:100%;
    height:100%;
    opacity:1;
    transition:opacity 0s ease-in-out;
}


.hplib-item-slider-picker-wrapper-double {
    width:100%;
    /*height:512px;*/
    height:538px;
    position: relative;
}

.img-slider-picker-btn-show {
    appearance: none;
    border:none;
    color:#fff;
    font-weight: 700;
    padding:8px 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    width:75px;
    height:36px;
    border-radius: 4px;
    background-color: black;
    font-size: 14px;
}

.img-slider-picker-btn-show-container {
    position: absolute;
    right: 100%;
    top: 50%;
    transform: translateY(-50%);
    height: 55px;
    box-shadow: 0px 0px 16px -5px #414142;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    border-top-left-radius: 50%;
    border-bottom-left-radius: 50%;
}

.img-slider-picker-btn-show {
    background-color: rgb(211, 24, 24);
    width: unset;
    height: 100%;
    padding: 0 8px 0 10px;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    border-top-left-radius: 50%;
    border-bottom-left-radius: 50%;
    color: #fff;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;

}

.hplib-empty-item-slider-picker-container {
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width:100%;
    height:100%;
}

.hplib-empty-item-slider-img-container {
    margin-top:16px;
    width:100%;
    text-align: center;
}

.hplib-empty-item-slider-img {
    width:64px;
    filter: opacity(0.3);
}

.hplib-empty-item-slider-msg-container {
    color:#414142;
    padding:0 16px;
}

.hplib-empty-item-slider-msg-heading {
    font-size: 24px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 8px;
    line-height: 1.5;
}

.hplib-empty-item-slider-msg {
    text-align: center;
    font-size: 16px;
    margin-bottom: 8px;
    line-height: 1.5;
}

.hplib-loader-animation-container {
    display:flex;
    width:100%;
    height:100%;
    align-items: center;
    justify-content: center;
}

.hplib-item-slider-picker-overlay-show {
    position: fixed;
    visibility: visible;
    top:0;
    left:0;
    height:100%;
    width:100%;
    opacity:1;
    transition: opacity .3s linear .1s, height 0s linear .1s, visibility .3s ease .1s;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 4;
}

.hplib-item-slider-picker-overlay-hide {
    position: fixed;
    visibility: hidden;
    top:0;
    left:0;
    height:0;
    width:100%;
    opacity:0;
    transition: opacity 0s linear 0s, height 0s linear 0s, visibility 0s linear 0s;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 4;
}

.hplib-list-wrapper {
    width:100%;
}

.hblib-list-btn-counter-wrapper {
    display:flex;
    padding:16px 16px 8px;
}

.hblib-image-slider-heading {
    font-size: 18px;
    color:#212529;
    bottom:0;
    margin:0;
}

.hblib-list-btn-container {
    width:100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    flex:0 1;

}


.hblib-list-close-btn, 
.hblib-list-add-btn,
.img-slider-picker-btn-hide {
    border:none;
    padding:8px 16px;
    color:#fff;
    border-radius: 4px;
    font-weight: 600;
    font-size: 14px;
    width:70px;
    max-height: 37px;
    display:flex;
    align-items: center;
    justify-content: center;

}

.hblib-list-close-btn {
    flex:1;
}

.img-slider-picker-btn-hide {
    display:none;
    background-color: black;
    margin:0 0 4px 0;
}

.hblib-list-close-btn-container {
    display:flex;
    align-items: center;
    padding:0 16px;
}

.hblib-list-close-btn {
    background-color:#007DB3;
} 

.hblib-list-add-btn {
    background-color:#DC2A27;
    margin:0 0 4px 0;
}

.hplib-item-counter-container {
    display:flex;
    flex-direction: column;
    align-items: center;
    width:100%;
    flex:1 1;
    line-height: 20px;
}

.hplib-item-counter-msg:nth-child(2) {
    margin:6px 0 0 0;
}

.hplib-item-counter-limit-msg {
    font-weight: 700;
    background-color: rgb(248, 215, 152);
    margin:6px 0 0 0;
}


.hplib-container {
    width:100%;
    display:grid;
    /*original version*/
    /*grid-template-columns: 100%;*/
    grid-template-columns: repeat(auto-fit, calc((100% - 16px) / 2));
    column-gap:16px;
    row-gap:32px;
    background-color: #fff;
    margin:32px 0 0 0;
    padding:0 16px;
}

.hplib-item-container {
    width:100%;
}

.hplib-heading-caption-wrapper {
    text-align: left;
    margin:4px 0 8px;
    display: none;
}

.hblib-heading-label, .hblib-caption-label {
    font-weight: 700;
}

.hplib-info-msg-wrapper {
    width:100%;
    display:flex;
    align-items: center;
    justify-content: center;
    margin-top:64px;
    padding:0 16px;
    font-family: "Open Sans";
}

.hblib-generic-info-msg-wrapper {
    width:100%;
    padding:0 16px;
    position:absolute;
    top:0;
    left:0;
    margin:0 auto;
    z-index: 5;
}

@media (min-width:653px) and (max-height:599px){
    .hplib-item-counter-container {
        flex-direction: row;
        align-items: flex-end;
        justify-content: flex-start;
        flex:1 1;
    }

    .hplib-item-counter-msg:nth-child(2),
    .hplib-item-counter-limit-msg {
        margin:0 0 0 8px;
    }

    .hblib-list-btn-container {
        flex-direction: row;
    }

    .img-slider-picker-btn-hide,
    .hblib-list-add-btn {
        margin:0 4px 0 0;
    }
}


@media (min-height:600px) {
    .hplib-item-counter-container {
        display:flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-end;
        width:100%;
        line-height: 20px;
    }

    .hplib-heading-caption-wrapper {
        margin-top: 4px;
        display:block;
    }
}


@media (min-width:500px) and (min-height:600px) {
    .hblib-list-btn-counter-wrapper {
        min-height: unset;
    }

    .hplib-item-counter-container {
        display:flex;
        flex-direction: row;
        align-items: flex-end;
        justify-content: flex-start;
        width:100%;
        line-height: 20px;
    }

    .hblib-list-btn-container {
        width:100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: flex-end;
    }

    .hplib-item-counter-msg:nth-child(2),
    .hplib-item-counter-limit-msg {
        margin:0 0 0 8px;
    }

    .img-slider-picker-btn-hide,
    .hblib-list-add-btn {
        margin:0 4px 0 0;
    }
}

@media screen and (min-width:480px) {
    .hplib-container {
        grid-template-columns: repeat(auto-fit, calc((100% - 16px) / 2));
    }
}

@media screen and (min-width:768px) {
    .hplib-item-slider-picker-wrapper {
        padding:0 32px;
    }

    .hblib-list-btn-counter-wrapper {
        padding:16px 0;
    }

    .hplib-container {
        grid-template-columns: repeat(auto-fit, calc((100% - 32px) / 3));
    }

    .hblib-list-close-btn, 
    .hblib-list-add-btn,
    .img-slider-picker-btn-hide {
        font-size: 14px;
        min-width: 120px;
        padding:8px 32px;
    }

    .hblib-list-close-btn {
        flex:0;
    }

}

@media screen and (min-width:1024px) {
    .hplib-container {
        grid-template-columns: repeat(auto-fit, calc((100% - 48px) / 4));
    }
}

/*
@media screen and (min-width:1200px) {
    .hplib-container {
        grid-template-columns: repeat(auto-fit, calc((100% - 48px) / 4));
    }
}
*/

@media screen and (min-width:1400px) {
    .hplib-container {
        grid-template-columns: repeat(auto-fit, calc((100% - 64px) / 5));
    }
}