.customer-relation-response-container {
    border-left:1px solid lightgrey;
    font-size: 12px;
    margin:10px 8px;
    padding:10px;
    /*border:1px solid red;*/
}

.customer-relation-response-heading {
    font-style: italic;
    padding:5px 0;
}

.customer-relation-response-date {
    color:#999999;
    padding:5px 0;
}