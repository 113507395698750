.shop-store-department-heading {
    text-align: center;
    color:#333333;
    font-family: "Open Sans";
    font-weight: 800;
    font-size: 28px;
    line-height: 40px;
    margin-bottom: 24px;
    padding:0 16px;
}

.product-category-filter-heading {
    padding:.95rem 1rem;
    font-family: "Open Sans";
    margin-bottom:.75rem;
    background-color: rgba(78,70,70,0.04);
    border-radius:.25rem;
}

.product-category-sort-by-heading {
    font-family: "Open Sans";
    display:flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom:16px;
    padding:8px;
    border-bottom: 1px solid rgb(238, 236, 236);
}

.product-category-filter-item {
    border:none;
}

.product-category-sort-by-results-heading {
    color:#414142;
    display:inline-flex;
    align-items: center;
    height:16px;
    font-weight: 800;
    font-family: "Open Sans";
}

.product-category-sort-by-results-title {
    color:#414142;
}

.sort-by-form-btn {
    /*border: 1px solid rgb(197, 197, 197);*/
    border:1px solid rgb(51, 51, 51);
    border-radius: 32px;
    padding: 12px 24px;
    /*background-color: rgb(255, 255, 255);*/
    display:inline-flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    font-family: "Open Sans";
    margin:0;
    cursor: pointer;
    position: relative;
    color:#414142;
}

.sort-by-form-btn-text {
    font-size: 13.3333px;
    display:inline-flex;
    align-items: center;
    justify-content: center;
    width:100%;
    height:18px;
    font-weight:800;
}

.sort-by-form-btn-svg {
    max-width: 16px;
    max-height: 16px;
    margin-right: 8px;
}

.sort-by-form {
    margin:-16px 0;
    display:none;
}

.sort-by-form-group {
    display: inline-flex;
    align-items: center;
    min-width: 0px;
    width: 100%;
    border-radius: 4px;
    /*border: 1px solid rgb(197, 197, 197);*/
    background-color: rgb(255, 255, 255);
    /*border-radius: 32px;*/
    width:230px;
}

.sort-by-select-label {
    margin: 0px 8px 0px 0px;
    white-space: nowrap;
    color:#333333;
    font-size:12px;
    font-family:"Open Sans";
    font-weight:800;
}

.sort-by-select {
    /*
    padding: 8px 8px 8px 16px;
    appearance: none;
    background-image: url(https://server.kando-proto-3.com/down-arrow.png);
    font-size: 16px;
    font-family: "Open Sans";
    background-size: 24px auto;
    background-repeat: no-repeat;
    background-position: right center;
    background-origin: content-box;
    background-color: transparent;
    border-radius: 32px;
    width:100%;
    line-height: 20px;
    border: none;
    cursor: pointer;
    */
    appearance: none;
    display: inline-flex;
    width: 100%;
    border-radius: 48px;
    border: 1px solid rgb(215, 215, 216);
    background-color: rgb(255, 255, 255);
    padding: 8px 16px;
    height: 44px;
    font-size: 14px;
    font-family: inherit;
    background-origin: content-box;
    box-sizing: border-box;
    background-image: url("data:image/svg+xml;utf8,<svg width=\"16\" height=\"16\" viewBox=\"0 0 16 16\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\"><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M1.65 4.24a1 1 0 0 0-1.3 1.52l7 6a1 1 0 0 0 1.3 0l7-6a1 1 0 0 0-1.3-1.52L8 9.684 1.65 4.24Z\" fill=\"currentColor\"/></svg>");
    background-position: right center;
    background-size: 14px 14px;
    background-repeat: no-repeat;
}

.shop-by-area-type-breadcrumb-wrapper {
    padding:16px 0 0;
}

.shop-product-category-breadcrum-container {
    display:flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    line-height: 24px;
    margin: 16px;
    font-family: "Open Sans";
    color:#414142;
    padding:0;
    list-style-type: none;
}

.shop-product-category-department-name-breadcrum-link {
    color:#717172;
    text-decoration: none;
}

.shop-product-category-department-name-breadcrum-link:hover {
    color:#717172;
    text-decoration: underline;
}

.shop-product-category-name-breadcrum-link {
    color:#414142;
    text-decoration: none!important;
}

.shop-product-detail-wrapper {
    width:100%;
    padding:4px;
    flex:1 1 50%;
    max-width: 50%;
    /*border:1px solid rgb(219, 215, 215);*/
}

.shop-product-category-product-image {
    height:auto;
    max-width: 100%;
}

.shop-product-category-product-image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding:0;
    height:205px;
}

.shop-product-category-product-details-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.shop-product-category-wrapper {
    display: flex;
    max-width: 1440px;
    margin:0 auto;
    /*padding:16px;*/
    padding:16px 4px;
    min-height: 100vh;
}

.shop-product-category-products-wrapper {
    display:flex;
    flex-wrap:wrap;
}

.shop-products-overall-wrapper {
    position: relative;
    z-index: 4;
}

.shop-products-mobile-nav {
    position: fixed;
    top:0;
    left:0;
    right:0;
    bottom:0;
    /*width: 100%;
    height:100%;*/
    background-color: #fff;
    border: 1px solid black;
    overscroll-behavior:contain;
    transform: translateY(-25%);
    transition: transform .3s ease;
    visibility: hidden;
}

.shop-products-mobile-options-container {
    position: sticky;
    top:0;
    left:0;
    display:flex;
    justify-content: space-between;
    align-items: center;
    width:100%;
    height:56px;
    padding:0 8px;
    background-color: #fff;
    z-index: 1;
}

.shop-products-mobile-options-clear-btn {
    border:none;
    background-color: transparent;
    font-family: "Open Sans";
    color:#414142;
}

.shop-products-mobile-options-heading {
    font-family: "Open Sans";
    color:#414142;
    display:inline-block;
}

.shop-products-mobile-options-close-btn {
    border:none;
    background-color: transparent;
    color: #414142;
}

.shop-products-mobile-sort-by-list-container {
    display:flex;
    flex-wrap: nowrap;
    align-items: center;
    padding:32px 16px;
    background-color: #F7F7F7;
    overflow-x: auto;
    width:100%;
}

.shop-products-mobile-sort-by-list-heading {
    display:inline-block;
    flex-shrink: 0;
    margin:0 4px 0 0;
    color:#414142;
    font-weight: 800;
    font-family: "Open Sans";
    line-height: 16px;
}

.shop-products-mobile-sort-by-list {
    display:flex;
    flex-shrink: 0;
    align-items: center;
    list-style-type: none;
    padding:0;
    margin:0;
}

.shop-products-mobile-sort-by-list-item {
    display: flex;
    align-items: center;
    height:16px;
}

.shop-products-mobile-sort-by-list-item-button {
    color:#414142;
    padding:8px 12px;
    margin:0 4px;
    border-radius: 24px;
    text-decoration: none;
    font-family: "Open Sans";
    background-color: transparent;
    border:none;
    line-height: 22px;
}

.highlight-sort-by-list-item-btn {
    /*background-color: #D2D2D2;*/
    background-color: #333333;
    font-weight: 800;
    color:#fff
}

.shop-products-mobile-search-filter-container {
    width:100%;
    padding:0 16px;
}

.shop-products-mobile-item-count-container {
    position: fixed;
    bottom:0;
    left:0;
    width:100%;
    padding:8px;
    z-index: 1;
    background-color: #fff;
}

.shop-products-mobile-view-items-btn {
    width:100%;
    border:1px solid transparent;
    border-radius: 4px;
    background-color: #DC291E;
    color:#fff;
    padding:8px 16px;
    font-weight: 600;
    font-family: "Open Sans";
    font-size: 14px;
    line-height: 20px;
    min-height: 38px;
}

.shop-store-department-sidebar-container {
    display:block;
    padding:0;
    height:100%;
}

.shop-store-department-sidebar-link-container {
    padding:16px 0;
    font-family: "Open Sans";
    font-size: 16px;
    line-height: 14px;
    display: flex;
    align-items: center;
}

.shop-store-department-sidebar-list-container {
    padding:0;
    margin:0;
    list-style-type: none;
}

.shop-store-department-sidebar-list-refinement {
    border-bottom:1px solid rgb(238, 238, 238);
    padding:0 0 16px;
    margin: 0px 0px 32px;
}

.shop-store-department-sidebar-list {
    margin-bottom:0;
}

.shop-store-department-sidebar-container-wrapper {
    display:none;
}

.shop-product-category-container {
    width:100%;
}

.shop-product-category-products-error-message-container {
    margin:16px 0;
    padding:0;
}

.shop-product-category-products-error-message {
    display:flex;
    align-items: center;
    background-color: #f2dfde;
    color:red;
    border:1px solid #dc291e;
    border-radius:.25rem;
    padding:8px 25px;
    min-height:64px;
}

.exclamation-triangle-size {
    font-size: 16px;
    margin-right:24px;
}

.highlight-pc-text {
    font-weight:800;
}

@media screen and (min-width:280px){
    .shop-product-category-wrapper {
        padding-left: calc(15px + ((100vw - 301px) * .5238));
        padding-right: calc(15px + ((100vw - 301px) * .5238));
    }
}

@media screen and (min-width:302px){
    .shop-product-category-wrapper {
        padding:16px;
    }
}

@media screen and (min-width:768px){
    .shop-product-category-wrapper {
        padding:16px 32px;
    }

    .shop-product-detail-wrapper {
        flex:1 1 33.3333%;
        max-width: 33.3333%;
    }

    .exclamation-triangle-size {
        font-size: 28px;
    }
}

@media screen and (min-width:1024px){
    .shop-product-category-breadcrum-container{
        margin-top: 16px;
    }

    .shop-store-department-heading {
        margin:8px 0 40px;
        font-size: 36px;
        line-height: 40px;
    }

    .product-category-sort-by-heading {
        /*background-color: #F7F7F7;*/
        padding:16px 8px;
        min-height:58px;
    }

    .sort-by-form-btn {
        display:none;
    }

    .sort-by-form {
        display:block;
    }

    .shop-products-mobile-nav {
        position:relative;
        display:block;
        visibility: visible;
        margin-right:16px;
        width:220px;
        transform:translateY(0%);
        transition:none;
        border:none;
    }

    .shop-products-mobile-options-container {
        display:none;
    }

    .shop-products-mobile-sort-by-list-container {
        display:none;
    }

    .shop-products-mobile-search-filter-container {
        padding:0;
    }

    .shop-products-mobile-item-count-container {
        display:none;
    }

    .shop-store-department-sidebar-container {
        display:block;
        padding:0;
        height:100%;
    }

    .shop-product-category-container {
        flex:1 1;
    }

    .shop-store-department-sidebar-container-wrapper {
        width:220px;
        margin-right:16px;
        display:block;
    }

    .shop-product-category-wrapper {
        min-height: 800px;
    }


}

@media screen and (min-width:1280px){
    .shop-product-detail-wrapper {
        flex:1 1 25%;
        max-width: 25%;
    }
}

@media screen and (min-width:1400px){
    .shop-product-detail-wrapper {
        flex:1 1 20%;
        max-width: 20%;
    }
}
