.add-product-heading {
    text-align: center;
    font-family: "Open Sans";
    font-size: 26px;
    color: #414142;
    font-weight: 800;
    margin: 40px 0 40px;
}

.add-product-form {
    position:relative;
    border:1px solid black;
}

.add-product-input-form-group {
    /*border:1px solid black;*/
    display:flex;
    flex-direction: column;
    margin-bottom:8px;
}

.add-product-fieldset-group legend {
    font-size: 20px;
    background-color: rgb(227 223 223);
    width: 100%;
    border-radius: 4px;
    padding: 8px;
    margin-bottom: 16px;
}

.add-product-fieldset-group:nth-child(2),
.add-product-fieldset-group:nth-child(3){
    margin-top:8px;
}

/*pricing styles*/

.total-price-dollar-sign {
    display: inline-block;
    margin:0;
    font-size: 18px;
    font-weight: 700;
}

.total-price-decimal-container {
    display:inline-flex;
    justify-content: center;
    width:20px;
    position: relative;
}

.total-price-decimal {
    position: absolute;
    background-color: black;
    border-radius: 50%;
    bottom:-10px;
    width:5px;
    height:5px;
}

.total-price-amount-dollars {
    width:75px;
}

.total-price-amount-cents {
    width:50px;
}

/*file upload styles*/
.product-image-search,
.product-image-solo,
.product-image-mobile,
.product-image-mini-cart {
    display:none;
}

/*need to apply these three styles below for all image elements in the form*/
.product-image-search-label-link, 
.product-image-solo-label-link, 
.product-image-mobile-label-link, 
.product-image-mini-cart-label-link {
    display:flex;
    flex-direction: column;
    cursor: pointer;
    color:rgb(43, 43, 247);
}

.no-color-label-link {
    cursor: default;
    color:black;
}

.product-image-search-list-container {
    margin-bottom:16px;
    min-height: 40px;
}

.product-image-search-list {
    margin:0;
    padding:0;
    list-style-type: none;
}

.image-list-item-close-btn {
    font-size: 12px;
    font-weight: 700;
    display: inline-block;
    margin-left: 5px;
    /*border:1px solid red;*/
    cursor: pointer;
}

.product-image-list-item {
    width:100%;
    height:40px;
    overflow: hidden;
    display:flex;
}

.image-list-item {
    width:100%;
    min-height:40px;
    display:flex;
}

.image-list-item-url-container {
    display:flex;
    align-items: center;
    flex-wrap: wrap;
    word-break: break-all;
}

.product-thumbnail-image {
    visibility: hidden;
}

/**form error styles**/
.product-info-error {
    border:4px solid rgb(49,211,71)!important;
}

.pricing-error {
    border:4px solid rgb(48,66,230)!important;
}

.image-error {
    border:4px solid rgb(252,160,39)!important;
    border-radius: 4px;
    width:100%;
}

.product-form-submit-btn-container {
    margin:32px 0;
    display:flex;
    flex-wrap: wrap;
    align-items: center;
    position:relative;
}

.product-form-submit-add-btn, 
.product-form-submit-save-btn,
.product-form-submit-cancel-btn {
    appearance: none;
    border: none;
    background-color: red;
    color:#fff;
    font-family: "Open Sans";
    font-weight: 600;
    font-size: 14px;
    padding:8px 32px;
    border-radius: 4px;
    min-width:120px;
}

.product-form-submit-save-btn,
.product-form-submit-add-btn {
    background-color: green;
    flex:1 1 100%;
}
.product-form-submit-cancel-btn {
    background-color: blue;
    margin:8px 0 0;
    flex:1 1 100%;
}

.promotions-fieldset,
.verifications-fieldset,
.available-sfg-fieldset,
.existing-sfg-fieldset {
    margin-bottom:16px;
}

.promotions-fieldset legend,
.verifications-fieldset legend,
.available-sfg-fieldse legend,
.existing-sfg-fieldset legend {
    margin-bottom:0;
}

.add-product-sub-category-form-info-message-container {
    width:100%;
    max-width: 768px;
    top:125px;
    left:50%;
    transform:translateX(-50%);
    padding:0 16px;
}

.error-msg-top-adj {
    top:175px;
}

.exceed-character-limit-error,
.one-letter-minimum-error {
    display: flex;
    align-items: center;
    width:100%;
    background-color: rgb(252, 198, 198);
    color: rgb(122, 9, 9);
    font-weight: 600;
    padding: 0 4px;
    border-radius: 4px;
    min-height: 36px;
    line-height: 16px;
    font-size: 14px;
    margin:0;
}

@media (min-width:350px){
    .product-image-search-label-link, 
    .product-image-solo-label-link, 
    .product-image-mobile-label-link, 
    .product-image-mini-cart-label-link {
        display:block;
        cursor: pointer;
        color:rgb(43, 43, 247);
        
    }
}

@media (min-width:768px){
    .product-form-submit-btn-container {
        margin:32px 0;
        display:flex;
        align-items: center;
        justify-content: center;
    }

    .product-form-submit-save-btn,
    .product-form-submit-add-btn {
        flex:unset;
    }

    .product-form-submit-cancel-btn {
        flex:unset;
        margin:0 0 0 8px;
    }
}
