.store-search-message {
    border-bottom: 2px solid rgb(215, 215, 216);
    padding: 8px 0px;
    margin-top: 8px;
    color:#414142;
    font-family: "Open Sans";
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
}

.show-store-search-message {
    display: block;
}

.hide-store-search-message {
    display: none;
}