.shopping-cart-btn-product-total-products-addded {
    position:absolute;
    height:20px;
    min-width:20px;
    border-radius: 10px;
    border: 2px solid rgb(220, 41, 30);
    z-index:1;
    background-color:#fff;
    text-align: center;
    color:#C6251B;
    font-size:12px;
    top:-5px;
    /*right:-11px;*/
    line-height:16px;
    font-weight: 800;
    padding:0 4px;
    font-family: "Open Sans";
    display:flex;
    justify-content: center;
    align-items: center;
}

@media screen and (min-width:480px){
    .shopping-cart-btn-product-total-products-addded {
        border:2px solid #C6251B;
        /*right:-5px;*/
    }
}