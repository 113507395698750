.shop-product-detail-checkout-btn-container {
    display:flex;
    justify-content: center;
    padding:0;
}

.shop-product-detail-checkout-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    color:#fff;
    background-color: rgb(220, 41, 30);
    border:1px solid transparent;
    cursor: pointer;
    transition: background-color .25s ease-in-out;
    outline-style: none!important;
    border-radius: 4px;
    padding:8px 10px;
    width:100%;
    font-size: 14px;
    position:relative;
    min-height:38px;
    font-family: "Open Sans";
    font-weight: 600;
    line-height:20px;
}

.shop-product-detail-checkout-btn:hover {
    background-color: rgb(192, 37, 37);
    border:1px solid rgb(192, 37, 37);
}

.shop-product-detail-subtract-btn-container {
    display: flex;
    justify-content: center;
    align-items: center;
    color:white;
    background-color: red;
    border:1px solid red;
    cursor: pointer;
    transition: background-color .3s ease-in;
    outline-style: none!important;
    border-top-left-radius: .25rem;
    border-bottom-left-radius: .25rem;
    border-right:1px solid white;
    padding:.5rem .5rem;
    font-size: .875rem;
}

.shop-product-detail-subtract-btn-container:hover {
    background-color: rgb(192, 37, 37);
    border:1px solid rgb(192, 37, 37);
    border-right:1px solid white;
}

.shop-product-detail-checkout-btn-select-btn-container {
    position: relative;
    width:100%;
}

.shop-product-detail-checkout-btn-select-btn {
    position:absolute;
    opacity:0!important;
    top:-1px;
    left:0;
    right:0;
    bottom:0;
    background-color: white!important;
    cursor: pointer;
    padding:4px 28px 4px 6px;
}


.shop-product-detail-checkout-btn-select-btn option {
    font-size:14px;
}

.checkout-btn-shopping-cart-shape, .checkout-btn-plus-icon-shape {
    font-size: .875rem;
}

/**original btn text when no items added**/
.shop-product-detail-checkout-btn-text {
    font-weight: 600;
    font-size: .875rem;
    text-align: center;
}

/**test btn text when no items added**/
.shop-product-detail-checkout-btn-text-test {
    display:flex;
    align-items: center;
    justify-content: center;
    width:100%;
}

.shop-product-detail-check-mark-btn {
    display:flex;
    justify-content: center;
    align-items: center;
    color:white;
    background-color: red;
    border:1px solid red;
    cursor: pointer;
    transition: background-color .3s ease-in;
    outline-style: none!important;
    border-radius: .25rem;
    border-radius: .25rem;
    padding:.5rem .5rem;
    font-size: .875rem;
    width:100%;
}

.shop-product-detail-check-mark-btn:hover {
    background-color: rgb(192, 37, 37);
    border:1px solid rgb(192, 37, 37);
}


/**test area for pound product dropdown**/
.shop-product-detail-checkout-btn-dropdown-list-down {
    padding:0;
    list-style-type: none;
    position:absolute;
    top:37px;
    color:black;
    left:0;
    border:1px solid grey;
    width:100%;
    background-color: white;
    z-index: 1;
    height:390px;
    overflow-y: scroll;
}

.shop-product-detail-checkout-btn-dropdown-list-up {
    padding:0;
    list-style-type: none;
    position:absolute;
    top:-390px;
    color:black;
    left:0;
    border:1px solid grey;
    width:100%;
    background-color: white;
    z-index: 1000;
    height:390px;
    overflow-y: scroll;
}

.shop-product-detail-checkout-btn-dropdown-list-item-first {
    background-color: lightgrey;
    color:grey;
}

.shop-product-detail-checkout-btn-dropdown-list-item {
    border:1px solid transparent;
}

.shop-product-detail-checkout-btn-dropdown-list-item:hover {
    border:1px solid black;
    background-color: rgb(38, 38, 253);
    color:white;
}