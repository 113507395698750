.store-structure-option-select-container {
    padding:0;
    width:100%;
}

.show-store-structure-option-select-error-border {
    width:100%;
    /*border-top:3px solid red;
    border-right:3px solid red;
    border-left:3px solid red;
    border-bottom:1px solid red;*/
    height:40px;
    border-radius: 4px;
    background-color: #fff;
    appearance: none;
    background-image: url('data:image/svg+xml;utf8,<svg width=\"16\" height=\"16\" viewBox=\"0 0 16 16\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\"><path fillRule=\"evenodd\" clipRule=\"evenodd\" d=\"M1.65 4.24a1 1 0 0 0-1.3 1.52l7 6a1 1 0 0 0 1.3 0l7-6a1 1 0 0 0-1.3-1.52L8 9.684 1.65 4.24Z\" fill=\"currentColor\"/></svg>');
    background-repeat: no-repeat;
    background-size: 14px 14px;
    /*background-position: right center;*/
    padding:0 4px;
    -webkit-appearance: none;
    color:#414142;
}

.hide-store-structure-option-select-no-error-border {
    width:100%;
    height:40px;
    border-radius: 4px;
    background-color: #fff;
    border: 1px solid rgb(177, 176, 176);
    outline: none;
    appearance: none;
    background-image: url('data:image/svg+xml;utf8,<svg width=\"16\" height=\"16\" viewBox=\"0 0 16 16\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\"><path fillRule=\"evenodd\" clipRule=\"evenodd\" d=\"M1.65 4.24a1 1 0 0 0-1.3 1.52l7 6a1 1 0 0 0 1.3 0l7-6a1 1 0 0 0-1.3-1.52L8 9.684 1.65 4.24Z\" fill=\"currentColor\"/></svg>');
    background-repeat: no-repeat;
    background-size: 14px 14px;
    /*background-position: right center;*/
    padding:0 4px;
    -webkit-appearance: none;
    color:#414142;
}

.show-store-structure-option-select-error-msg {
    display:flex;
    align-items: center;
    /*border-left:3px solid red;
    border-right:3px solid red;
    border-bottom:3px solid red;*/
    background-color: rgb(252, 198, 198);
    color:rgb(122, 9, 9);
    font-weight: 600;
    font-size:14px;
    font-family: "Open Sans";
    padding:4px;
    border-radius: 4px;
    height:36px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}

.hide-store-structure-option-select-error-msg {
    display: none;
}

@media (min-width:768px){
    .store-structure-option-select-container {
        width:unset;
    }
}
