.sfat, .sfg{
    position:relative;
    /*height:24px;*/
    height:50px;
    /*transition:height .3s ease;*/
    background-color: rgb(179, 162, 143);
    overflow-y: hidden;
}

.search-filter-group {
    background-color:rgb(252, 158, 158);
    /*color:#fff;*/
    padding-left:5px;
    height:35px;
    display: flex;
    align-items: center;
}

.search-filter-group-category-name {
    padding-left: 10px;
    height:35px;
    display:inline-flex;
    align-items: center;
}

.search-filter {
    background-color:rgb(246, 253, 183);
    /*color:#fff;*/
    padding-left:5px;
    height:35px;
    display: flex;
    align-items: center;
}

.search-term {
    background-color:rgb(214, 202, 214);
    /*color:#fff;*/
    padding-left:5px;
    height:35px;
    display: flex;
    align-items: center;
}

.search-filter-group-label {
    padding-left:5px;
    margin:0;
}

.search-filter-group-search-filters {
    margin-left:25px;
    /*display:none;*/
    height:35px;
}

.search-filter-group-search-terms {
    margin-left:25px;
    overflow-y: hidden;
    height:35px;
    /*display:none;*/
}

.show-item {
    display: block;
}

/**product verification styles**/
.product-verification-container {
    padding-left:5px;
    display:flex;
    align-items: center;
}

.product-verification-label {
    padding-left:5px;
}

/**product promotion styles**/
.product-promotion-container {
    padding-left:5px;
    display:flex;
    align-items: center;
}

.product-promotion-label {
    padding-left:5px;
}

/**style for search filter group error messages**/
.no-sfs-selected:nth-child(2){
    border-top:3px solid rgb(142, 53, 184);
    border-left:3px solid rgb(142, 53, 184);
    border-right:3px solid rgb(142, 53, 184);
}
.no-sfs-selected:nth-child(n+0){
    border-left:3px solid rgb(142, 53, 184);
    border-right:3px solid rgb(142, 53, 184);
}
.no-sfs-selected:last-child {
    border-bottom:3px solid rgb(142, 53, 184);
    border-left:3px solid rgb(142, 53, 184);
    border-right:3px solid rgb(142, 53, 184);
}

.no-sts-selected:nth-child(2){
    border-top:3px solid rgb(245, 31, 102);
    border-left:3px solid rgb(245, 31, 102);
    border-right:3px solid rgb(245, 31, 102);
}
.no-sts-selected:nth-child(n+0){
    border-left:3px solid rgb(245, 31, 102);
    border-right:3px solid rgb(245, 31, 102);
}
.no-sts-selected:last-child {
    border-bottom:3px solid rgb(245, 31, 102);
    border-left:3px solid rgb(245, 31, 102);
    border-right:3px solid rgb(245, 31, 102);
}
