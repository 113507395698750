.store-details-wrapper {
    position:relative;
    width:100%;
    min-height: 950px;
}

.store-details-container {
    display:flex;
    flex-direction: column;
    justify-content: center;
    max-width: 1000px;
    padding:16px;
    margin:0 auto;
}

.store-details-breadcrum-container {
   display:flex;
   list-style-type: none;
   padding:0;
   margin:0 0 16px;
   width:100%;
   font-family: "Open Sans";
   font-size: 14px;
}

.store-details-breadcrum-link {
    text-decoration: none;
    color:#717172;
}

.store-details-breadcrum-link:hover {
    text-decoration: underline;
    color:#717172;
}

.store-details-breadcrum-link-store-name {
    color:#414142;
    text-decoration: none;
}

.sdbi:nth-child(1), .sdbi:nth-child(3){
    display:none;
}

.sdbi:nth-child(2) {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
}

.sdbi:nth-child(2) svg {
    transform: scale(-1);
}

.store-details-heading {
    color:#414142;
    font-family: "Open Sans";
    margin-bottom:16px;
    font-size: 24px;
    line-height: 32px;
    font-weight: 800;
}

.store-details-location-wrapper {
    display:flex;
    flex-direction: column;
}

.store-details-image-operation-wrapper {
    width:100%;
    padding:0;
}

.store-details-location-image-container {
    position:relative;
    width:100%;
    height:0;
    padding-bottom: 29.63%;
    margin-bottom:16px;
    padding-left:8px;
    padding-right:8px;
}

.store-details-location-image {
    position:absolute;
    left:0;
    top:0;
    width:100%;
    height:auto;
}

.store-details-location-map-image-details-wrapper {
    display:flex;
    width:100%;
    padding:0;
    margin-bottom:32px;
}

.store-details-location-map-image-container-wrapper {
    flex:0 0 120px;
    margin-right:16px;
}

.store-details-location-map-image-container {
    position: relative;
    width:100%;
    height:0;
    padding-bottom:70.84%;
}

/*
.store-details-location-map-image {
    position: absolute;
    left:0;
    top:0;
    width:100%;
    height:100%;
}
*/

.store-details-location-operation-info {
    padding:0;
}


.store-details-location-address {
    font-family: "Open Sans";
    font-size: 14px;
}

.store-details-full-address {
    font-weight: 800;
    margin: 0;
}

.store-details-store-number {
    margin:0;
}

.store-details-get-directions-link {
    margin-bottom:16px;
    color:#007DB3;
    text-decoration: none;
}

.store-details-get-directions-link:hover {
    margin-bottom:16px;
    color:#007DB3;
    text-decoration: underline;
}

.store-details-location-general-info-wrapper {
    display:flex;
    flex-direction: column;
}

.store-details-location-general-info-container {
    flex:1 1%;
    padding:0;
}

.store-details-nearby-locations-wrapper {
    flex:1 1;
    padding:0;
}

.store-details-curbside-delivery-heading {
    color:#414142;
    line-height: 24px;
    font-family: "Open Sans";
    margin-bottom: 8px;
    font-size: 16px;
    font-weight: 800;
}

.store-details-curbside-grid {
    display:flex;
    flex-wrap: wrap;
}

.curbside-circle-details {
    font-size: 70px!important;
    color:rgb(36,154,194);
    position:relative;
    box-sizing: border-box;
}

.curbside-car-details {
    font-size: 28px!important;
    color:white;
    position: absolute;
    left:24%;
    top:25%;
    box-sizing: border-box;
}

.curbside-details-link, .delivery-details-link {
    font-family: "Open Sans";
    display:flex;
    width:100%;
    flex-direction: row;
    box-sizing: border-box;
    align-items: center;
    text-decoration: none;
    appearance: none;
    color:rgb(65, 65, 66);
    font-size: 16px;

}

.curbside-details-link-container, .delivery-details-link-container {
    border:1px solid rgb(215, 215, 216);
    display:flex;
    align-items:center;
    background-color: rgb(245, 245, 245);
    border-radius: 4px;
    margin-bottom: 16px;
    width:100%;
    padding:8px;
}

.curbside-details-link-container {
    margin:0 0 16px;
}

.curbside-details-text, .delivery-details-text {
    font-family: "Open Sans";
    font-size: 15px;
    font-weight: 400;
    line-height: 18px;
    color:rgb(51, 51, 51);
}

.curbside-details-heading, .delivery-details-heading {
    font-size: 16px;
    font-family: "Open Sans";
    font-weight: 800;
    line-height: 24px;
    margin:0;
    color:#414142;
}

.curbside-details-heading-text-container, .delivery-details-heading-text-container {
    flex-grow:1;
    margin:0 16px;
}

.delivery-circle-details {
    font-size: 70px!important;
    color:grey;
    position: relative;
    box-sizing: border-box;
}

.delivery-home-details {
    font-size: 36px!important;
    color:white;
    position: absolute;
    left:22%;
    top:22%;
    box-sizing: border-box;
}

.fa-chevron-right-container {
    padding:0;
}

.store-details-fa-chevron-right {
    color:black!important;
}

.store-details-weekly-add-coupon-link-group {
    display:flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom:16px;
    font-family: "Open Sans";
    font-size: 14px;
    gap:16px;
}

.store-details-weekly-add-link, .store-details-weekly-coupon-link {
    color:#007DB3;
    text-decoration: none;
}

.store-details-weekly-add-link:hover, .store-details-weekly-coupon-link:hover {
    color:#007DB3;
    text-decoration: underline;
}

.store-details-nearby-locations-heading-group-container {
    display:flex;
    flex-direction: column;
    padding:0 8px 0 0;
}

.store-details-nearby-locations-heading-group {
    display:flex;
    justify-content: space-between;
    padding-bottom: 8px;
    border-bottom: 2px solid rgb(215, 215, 216);
    font-family: "Open Sans";
    color:#414142;
}

.store-details-nearby-locations-heading-group-heading {
    color: rgb(65, 65, 66);
    font-family: "Open Sans";
    margin-bottom: 0px;
    font-size: 16px;
    line-height: 24px;
    font-weight: 800
}

.store-details-nearby-locations-heading-group-link {
    color:#007DB3;
    appearance: none;
    border: 0px;
    padding: 0px;
    margin: 0px;
    text-decoration: none;
    font-weight: 400;
    font-size: 14px;
    font-family: "Open Sans";
}

.store-details-nearby-locations-heading-group-link:hover {
    text-decoration: underline;
    color:#007DB3;
}

.store-details-ordered-list-shape {
    list-style-type: none;
    padding:0;
}

.store-details-department-service-list {
    padding:0;
}

.store-services-area-list, .store-services-list {
    list-style-type: none;
    padding:0 0 0 16px;
}

.store-services-area-list {
    padding:0;
}

.pharmacy-list-info-heading {
    color:#414142;
    font-size: 16px;
    font-weight: 800;
    line-height: 24px;
    font-family: "Open Sans";
    margin-bottom:8px
}

.pharmacy-list-info-container {
    display:flex;
    margin-bottom:16px;
}

.pharmacy-details-list, .pharmacy-services-list {
    list-style-type: none;
    padding:0;
    margin:0;
    display:grid;
    row-gap: 4px;
}

.pharmacy-details-list-item {
    display:grid;
    column-gap: 16px;
    grid-template-columns: 126px 1fr;
    font-size: 14px;
    font-family:"Open Sans";
    color:#414142;
}

.pharmacy-details-list-item-heading {
    font-weight: 800;
}

.pharmacy-details-list-item-detail {
    line-height: 20px;
    font-weight: 400;
}

.pharmacy-services-list-item {
    font-size: 15px;
    line-height: 19px;
}

.store-details-available-services-heading {
    color:#414142;
    font-size: 16px;
    font-family:"Open Sans";
    font-weight: 800;
    line-height: 24px;
    margin-bottom: 8px;
}

.store-services-area-list-item-description {
    display:flex;
    align-items: center;
    font-size: 14px;
    line-height: 20px;
    margin-bottom:2px;
    font-weight: 800;
    color:#414142;
    font-family:"Open Sans";
}

.store-services-list-item-description {
    color: rgb(65, 65, 66);
    font-family: "Open Sans";
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
}

.store-operation-info-list {
    list-style-type: none;
    padding:0;
    display:grid;
    row-gap: 4px;
    font-family: "Open Sans";
    color:#414142;
    font-size:14px;
}

.store-operation-info-list-item {
    display:grid;
    column-gap: 16px;
    grid-template-columns: 126px 1fr;
}

.store-operation-info-list-item-heading {
    font-weight: 800;
}

.store-operation-info-list-item:nth-child(2) .store-operation-info-list-item-detail,
.store-operation-info-list-item:nth-child(3) .store-operation-info-list-item-detail {
    color:#007DB3;
}


.store-details-error-msg-container {
    display:flex;
    align-items: center;
    justify-content: center;
    max-width: 768px;
    width:100%;
    margin:32px auto 16px;
    /*border:2px solid rgb(220, 41,30);
    border-radius: 8px;
    padding:16px;
    text-align: left;
    background-color: rgb(255,242,242);*/
}

.store-details-error-msg {
    width:16px;
    height:16px;
    margin-right:16px;
    color:rgb(65,65,66);
    flex-shrink:0;
}

.store-details-msg-wrapper {
    margin:128px 0 0;
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.store-details-msg-btn {
    appearance: none;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;
    cursor: pointer;
    background: rgb(220, 41, 30);
    margin: 32px 0 0;
    border-radius: 4px;
    border: 1px solid transparent;
    padding: 8px 16px;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    transition: background-color .25s ease-in-out 0s;
    color: #fff;
    padding: 8px 32px;
    width:164px;
}

.store-details-img-container {
    width:100%;
    text-align: center;
    margin-bottom:8px;
}

.sd-loader-animation-container {
    display:flex;
    justify-content: center;
    width:100%;
    margin-top:100px;
    min-height: 2000px;
}

@media  (min-width:768px) {
    .sdbi:nth-child(1), .sdbi:nth-child(3){
        display:flex;
        align-items: center;
    }
    
    .sdbi:nth-child(2) {
        flex-direction: row;
    }
    
    .sdbi:nth-child(2) svg {
        transform: none;
    }

    .store-details-location-wrapper {
        display:flex;
        flex-direction: row;
        flex-wrap: wrap;
    }

    .store-details-image-operation-wrapper {
        flex:1 1 66.6667%;
        padding:0 8px 0 0;
    }
    
    .store-details-location-map-image-details-wrapper {
        display:flex;
        flex-direction: column;
        flex:1 1 33.3333%;
        padding:0 0 0 8px;
        margin-bottom:0 0 32px;
    }

    .store-details-location-map-image-container-wrapper {
        width:100%;
        margin:0 0 16px;
    }

    .store-details-location-map-image-container {
        width:100%;
        height:0;
        padding-bottom:61.25%;
    }

    .store-details-location-general-info-wrapper {
        display:flex;
        flex-direction: row;
    }
    
    .store-details-curbside-grid {
        flex-wrap: nowrap;
    }

    .curbside-details-link-container, .delivery-details-link-container {
        flex:1 1 50%;
    }

    .curbside-details-link-container {
        margin:0 16px 16px 0;
    }

    .store-details-location-general-info-container {
        flex:1 1 66.6667%;
        padding:0 8px 0 0;
    }
    
    .store-details-nearby-locations-wrapper {
        flex:1 1 33.3333%;
        padding:0 0 0 8px;
    }

    
}
