.login-container {
    width:100%;
}

.login-display-container {
    position: relative;
    width:100%;
    max-width:500px;
    padding:32px 16px;
    margin:0 auto;
    min-height: 850px;
}

.login-heading {
    font-family: "Open Sans";
    text-align: center;
    font-weight: 800;
    font-size:36px;
    color:#414142;
    margin-bottom:32px;
}

.email-address-container,
.password-container {
    border:1px solid #414142;
    position:relative;
    width:100%;
    padding:0 16px;
    border-radius:4px;
}

.password-container {
    display:flex;
}

.admin-email-address-input {
    width:100%;
    height:50px;
    border:none;
    outline: none;
    background-color: #fff;
}

.admin-email-address-label-reg {
    position: absolute;
    padding:0 16px;
    top:0;
    left:0;
    width:100%;
    height:100%;
    background-color: transparent;
    /*border:1px solid orange;*/
    display:flex;
    align-items: center;
    font-family: "Open Sans";
    font-size:16px;
    font-weight: 600;
    transition: font-size .25s ease, font-weight .25s ease, top .25s ease, left .25s ease, padding .25s ease, width .25s ease, height .25s ease, background-color 0s ease;
}

.admin-email-address-label-sm {
    position: absolute;
    top:-10px;
    left:16px;
    height:20px;
    /*width:87px;*/
    width:unset;
    /*border:1px solid orange;*/
    color:#414142;
    background-color: #fff;
    padding:0 4px;
    display:flex;
    align-items: center;
    font-size: 12px;
    font-family: "Open Sans";
    transition: font-size .25s ease, font-weight .25s ease, top .25s ease, left .25s ease, padding .25s ease, width .25s ease, height .25s ease, background-color .8s ease;
}

.admin-password-label-reg {
    position: absolute;
    padding:0 16px;
    top:0;
    left:0;
    width:100%;
    height:100%;
    background-color: transparent;
    /*border:1px solid orange;*/
    display:flex;
    align-items: center;
    font-family: "Open Sans";
    font-size:16px;
    font-weight: 600;
    transition: font-size .25s ease, font-weight .25s ease, top .25s ease, left .25s ease, padding .25s ease, width .25s ease, height .25s ease, background-color 0s ease;
}

.admin-password-label-sm {
    position: absolute;
    top:-10px;
    left:16px;
    height:20px;
    /*width:62px;*/
    width:unset;
    /*border:1px solid orange;*/
    color:#414142;
    background-color: #fff;
    padding:0 4px;
    display:flex;
    align-items: center;
    font-size: 12px;
    font-family: "Open Sans";
    font-weight: 400;
    transition: font-size .25s ease, font-weight .25s ease, top .25s ease, left .25s ease, padding .25s ease, width .25s ease, height .25s ease, background-color .8s ease;
}

.eye-open-image-btn-container,
.eye-closed-image-btn-container {
    display:flex;
    align-items: center;
    justify-content: center;
    border:1px solid black;
    border-radius: 6px;
    appearance: none;
    border:none;
    z-index: 1;
}

.eye-close-image-btn,
.eye-open-image-btn {
    display:flex;
    align-items: center;
    justify-content: center;
    border:1px solid black;
    border-radius: 6px;
    appearance: none;
    border:none;
    width:20px;
    height:12px;
}

.eye-open-image-btn-container {
    display:none;
}

.login-error-msg-container {
    margin:32px 0;
    background-color: #FFECEE;
    border-radius: 4px;
}

.login-error-msg {
    margin:0;
    text-align: center;
    font-size: 16px;
    font-family: "Open Sans";
    color:#590A0F;
    font-weight: 600;
    padding:16px;
}

.admin-login-error-msg {
    color:#DC291E;
    font-size: 12px;
    font-family: "Open Sans";
    padding-top:2px;
    margin:0;
}

.admin-email-address-wrapper,
.admin-login-password-wrapper,
.admin-login-btn-wrapper {
    height:50px;
}

.admin-email-address-wrapper {
    margin-bottom:40px;
}

.admin-login-password-wrapper {
    margin-bottom:60px;
}

.admin-login-btn-wrapper {
    margin-bottom:120px;
}

.admin-login-btn {
    width: 100%;
    height: 100%;
    appearance: none;
    border: none;
    background-color: #0672cb;
    border-radius: 4px;
    color: #fff;
    font-family: "Open sans";
    font-weight: 600;
    transition:background-color .3s ease-in-out;
}

.admin-login-btn:hover {
    width: 100%;
    height: 100%;
    appearance: none;
    border: none;
    background-color: #0866b3;
    border-radius: 4px;
    color: #fff;
    font-family: "Open sans";
    font-weight: 600;
}