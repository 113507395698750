.ssdcs-wrapper {
    width:100%;
}

.ssdcs-container {
    position:relative;
    width:100%;
    max-width: 1000px;
    min-height: 900px;
    padding:16px;
    margin:0 auto;
}

.store-structure-dpts-categories-heading {
    text-align: center;
    font-family: "Open Sans";
    font-size: 26px;
    color: #414142;
    font-weight: 800;
    margin: 40px 0 40px;
}

.department-category-search-secondary-label {
    display: inline-flex;
    align-items: center;
    font-size: 14px;
    margin-bottom: 2px;
}

.ssdcs-loader-animation-container {
    width:100%;
    display:flex;
    justify-content: center;
    margin-top:100px;
    min-height: 4500px;
}

/**table styles**/
.ssdcs-table-container {
    max-width: 1000px;
    margin:0 auto;
    overflow-x: auto;
}

.ssdcs-table {
    table-layout: fixed;
    width:100%;
    min-width:968px;
    border-collapse: separate;
    border-spacing:0;
    border:1px solid black;
    border-radius: 4px;
    overflow: hidden;
}

.ssdcs-table th, .ssdcs-table td {
    border:1px solid black;
    padding:4px 8px;
    font-family: "Open Sans";
}

.ssdcs-table th {
    background-color: #d6d4d4;
    color:#414142;
    font-weight: 700;
    padding:8px;
    border-top:0;
    border-right:0;
    border-bottom:0;
}

.ssdcs-table th:first-child {
    border-left:0;
}

.ssdcs-table td {
    border-right:0;
    border-bottom:0;
    height:94px;
}

.ssdcs-table tr td:first-child {
    border-left:0;
}

.ssdcs-table .td-action {
    padding:0;
}

.ssdcs-table .th-dc-dept {
    width:23%;
}

.ssdcs-table .th-dc-dc {
    width:23%;
}

.ssdcs-table .th-dc-img {
    width:24%;
}

.ssdcs-table .th-dc-date {
    width:14%;
}

.ssdcs-table .th-dc-action {
    width:10%;
}

.ssdcs-table-image {
    max-width:100%;
    max-height:85px;
}

@media (min-width:420px){
    .department-category-search-secondary-label{
        display:none;
    }
}

@media (min-width:1024px) {
    .ssdcs-loader-animation-container {
        height:unset;
    }
}


