.loader-animation-wrapper {
    position:relative;
    display:inline-block;
}

.loader-animation-container {
    position:relative;
    background-color:#DC291E;
    width:50px;
    height:50px;
    padding:3px;
    border-radius: 50%;
}

.loader-animation-spin-text {
    position:relative;
    display:flex;
    align-items: center;
    justify-content: center;
    color:#fff;
    font-family: "Open Sans";
    font-weight: 800;
    font-size: 24px;
    width:100%;
    height:100%;
    border-radius: 50%;
    /*animation: spinText 3s linear infinite;*/
}

@keyframes spinText {
    0% { color: #DC291E;}
    25% { color:#fff;}
    50% {color:#DC291E;}
    75% {color:#fff}
    100% { color:#DC291E;}
}


.loader-animation-spin-right {
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    border-top: 3px solid transparent;
    border-right:3px solid transparent;
    border-bottom:3px solid #fff;
    border-left:3px solid #fff;
    color:#fff;
    font-size: 32px;
    font-weight: 800;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius:50%;
    /*animation:spinRight 3s cubic-bezier(.03,.68,.35,1) infinite;*/
    /*animation: spinRight 3s cubic-bezier(.04,.56,.27,.56) infinite;*/
    animation: spinRight 3s cubic-bezier(.32,1.59,.63,-1.34) infinite reverse;
}

@keyframes spinRight {
    0% { transform: rotate(0deg);}
    100% { transform: rotate(360deg);}
}

.loader-animation-spin-left {
    position:absolute;
    top:0;
    left:0;
    border-top:3px solid #fff;
    border-right:3px solid #fff;
    border-bottom:3px solid transparent;
    border-left:3px solid transparent;
    width:100%;
    height:100%;
    border-radius:50%;
    animation: spinLeft 3s cubic-bezier(.32,1.59,.63,-1.34) infinite;
    display: flex;
    align-items: center;
    justify-content: center;
    color:#fff;
}

@keyframes spinLeft {
    0% { transform: rotate(0deg); } 
    100% { transform: rotate(360deg);}
}


