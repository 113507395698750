/**shade background style for keyword dropdown list**/
.empty-cart-alert-msg-background-shade-container {
    display:flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0,0,0,0.4);
    visibility: visible;
    position:fixed;
    left:0;
    top:0;
    width:100%;
    height:100%;
    opacity:1;
    z-index: 4;
    transition: opacity 100ms linear 100ms, visibility 0s ease 0s, height 0s linear 0s;
}


.empty-cart-alert-msg-background-shade-container-hide {
    display:flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0,0,0,0.4);
    visibility: hidden;
    position:fixed;
    left:0;
    top:0;
    width:100%;
    height:0;
    opacity:0;
    z-index: 4;
    transition: opacity 200ms linear 50ms, visibility 0s ease 50ms, height 0s linear 50ms;
}

.empty-cart-alert-msg-container {
    margin:32px;
    background-color: rgb(255, 255, 255);
    border-radius: 24px;
    padding: 32px;
    max-width: 544px;
    max-height: calc(100% - 64px);
    overflow: auto;
}

.empty-cart-alert-msg-heading {
    font-family: "Open Sans";
    font-size: 24px;
    line-height: 32px;
    font-weight: 800;
    color: rgb(0, 0, 0);
    margin-bottom: 8px;
}

.empty-cart-alert-msg {
    color: rgb(65, 65, 66);
    font-family: "Open Sans";
    margin-bottom: 32px;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
}

.empty-cart-alert-msg-close-btn-container {
    display:flex;
    margin:0 -4px;
}

.empty-cart-alert-msg-cancel-btn, .empty-cart-alert-msg-empty-btn {
    flex:1 1;
    margin:4px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;
    cursor: pointer;
    background: rgb(220, 41, 30);
    border-radius: 4px;
    border: 1px solid transparent;
    padding: 8px 16px;
    font-family: "Open Sans";
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    transition: background-color 250ms ease-in-out 0s;
    color: rgb(255, 255, 255);
}

.empty-cart-alert-msg-empty-btn:hover {
    background-color: rgb(192, 37, 37);
}

.empty-cart-alert-msg-cancel-btn {
    background-color: transparent;
    border: 1px solid rgb(46, 46, 47);
    color: rgb(46, 46, 47);
}

.empty-cart-alert-msg-cancel-btn:hover {
    background-color: rgb(46, 46, 47);
    color:#fff;
}