.user-rating-shape {
    padding:0!important;
    border:none!important;
}

.star-rating-list-individual-container {
    padding:0;
}

.star-rating-list {
    padding:0;
    display:flex;
    list-style-type: none;
}

.star-rating-list-item-shape-individual {
    font-size: 15px;
    color:rgb(30, 133, 218);
}

.star-rating-list-item-shape-individual-no-fill {
    font-size:15px;
}