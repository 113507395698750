.ssps-wrapper {
    width:100%;
}

.ssps-container {
    position: relative;
    width:100%;
    max-width: 1440px;
    min-height: 900px;
    padding:16px;
    margin:0 auto;
}

.product-search-secondary-label {
    display: inline-flex;
    align-items: center;
    font-size: 14px;
    margin-bottom: 2px;
}

.ssps-table-container {
    width: 100%;
    margin: 0 auto;
    overflow-x: auto;
}

.ssps-table {
    table-layout: fixed;
    width: 100%;
    min-width: 1408px;
    border-collapse: separate;
    border-spacing:0;
    border-radius:4px;
    border:1px solid black;
    overflow:hidden;
}

.ssps-table th, 
.ssps-table td {
    border:1px solid black;
    padding:4px 8px;
    font-family: "Open Sans";
    text-align: left;
}

.ssps-td {
    display:-webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 2;
}

.ssps-table .td-action {
    padding:0;
}

.ssps-table .sspscs-img {
    text-align: center;
}

.ssps-table th {
    background-color: #d6d4d4;
    color:#414142;
    font-weight: 700;
    padding:8px;
    border-top:0;
    border-right:0;
    border-bottom:0;
}

.ssps-table th:first-child {
    border-left:0;
}

.ssps-table td {
    border-right:0;
    border-bottom:0;
    height:94px;
}

.ssps-table tr td:first-child {
    border-left:0;
}

.ssps-table th span {
    display:inline-flex;
    width:100%;
}

.ssps-table .th-ps-p {
    width:12%;
}

.ssps-table .th-ps-dept {
    width:11%;
}

.ssps-table .th-ps-d-esf {
    width:4%;
}
.ssps-table .th-ps-d-asf {
    width:4%;
}

.ssps-table .th-ps-dc {
    width:10%;
}

.ssps-table .th-ps-dc-esf {
    width:4%;
}
.ssps-table .th-ps-dc-asf {
    width:4%;
}
.ssps-table .th-ps-pc {
    width:10%;
}
.ssps-table .th-ps-pc-esf {
    width:4%;
}
.ssps-table .th-ps-pc-asf {
    width:4%;
}
.ssps-table .th-ps-ql {
    width:4%;
}
.ssps-table .th-ps-price {
    width:7%;
}
.ssps-table .th-ps-img {
    width:7%;
}
.ssps-table .th-ps-date {
    width:9%;
}
.ssps-table .th-ps-action {
    width:6%;
}

.ssps-table-image {
    max-width: 100%;
    max-height:85px;
}


.product-search-box-form-fieldset {
    display:flex;
    flex-direction: column;
}

.product-price-search-container {
    flex: 1 1 100%;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 8px;
}

.product-price-search-amt-container {
    flex: 1 1 100%;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 8px;
}

.product-price-search-operator-label,
.product-price-search-amt-label {
    flex: 1 1 100%;
    margin-bottom: 4px;
}

.product-price-layout-wrapper {
    width:100%;
}

.product-price-search-layout-wrapper {
    width:100%;
}

.table-keyword-search-layout-wrapper {
    width:100%;
    max-width: 768px;
}

.product-price-search-dollar-amount-wrapper {
    display:flex;
    width:100%;
    align-items: center;
    border-radius:4px;
    border: 1px solid rgb(177, 176, 176);
    padding:0 4px;
}

.product-price-search-dollar-symbol {
    font-size: 18px;
    font-weight: 700;
}

.product-price-search-amt, .product-qty-limit {
    height:40px;
    width:100%;
    outline:none;
    border:none;
}

.process-request-msg {
    position:absolute;
    left:50%;
    transform: translateX(-50%);
    bottom: 200px;
    font-family: "Open Sans";
    font-weight: 600;
    background-color: #f7dd8d;
    padding: 8px 16px;
    border-radius: 4px;
    z-index: 1;
}

.process-request-msg-fixed {
    position:fixed;
    top:35%;
    left:50%;
    transform: translate(-50%);
    font-family: "Open Sans";
    font-weight: 600;
    background-color: #f7dd8d;
    padding: 8px 16px;
    border-radius: 4px;
    z-index: 1;
}

@media (min-width:670px){
    .product-search-secondary-label {
        display:none;
    }
}

@media (min-width:768px){
    .product-price-layout-wrapper {
        width:450px;
    }

    .product-price-search-layout-wrapper {
        width:285px;
    }

    .process-request-msg {
        bottom: 150px;
    }

}