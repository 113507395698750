.card-box-wrapper {
    width:100%;
    max-width: 1440px;
    margin:0 auto;
    /*padding-top:32px;
    padding-bottom:32px;*/
}

.card-box-heading-container {
    text-align: left;
    margin-bottom:24px;
    padding:0 16px;
}

.card-box-heading {
    font-weight: 800;
    line-height: 28px;
    font-size: 20px;
    margin:0 32px 0 0;
    color:#414242;
    font-family: "Open Sans";
}

.card-box-caption {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #414242;
    font-family: "Open Sans";
}

.card-box-container {
    display: grid;
    gap: 24px 16px;
    grid-template-columns: 1fr 1fr;
    position: relative;
    width: 100%;
    padding: 0px 16px;
}

.card-box-item {
    max-width: 431px;
    display:flex;
    flex-direction: column;
    align-items: center;
}

.card-box-item-link {
    position: relative;
    margin-bottom: 24px;
    text-decoration: none;
    color:black;
    width:100%;
}

.card-box-item-image-container {
    width:100%;
}

.card-box-item-image {
    background-color: green;
    border-radius: 24px;
    width: 100%;
    max-height: 100%;
    max-width: 431px;
}

.card-box-item-caption-container {
    margin-top:16px;
    text-align: left;
}

.card-box-item-caption-heading {
    margin-bottom:0px;
    margin-top:16px;
    font-size: 16px;
    line-height: 24px;
    font-weight: 800;
    font-family: "Open Sans";
    color:#414142;
}

.card-box-item-caption {
    margin-bottom:0;
    margin-top:2px;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    color:#414142;
    font-family: "Open Sans";
}

@media screen and (min-width:768px){
    .card-box-container {
        grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
        column-gap: 32px;
        padding: 0px;
    }

    .card-box-item-caption-heading {
        margin-top:24px;
        font-size: 20px;
    }

    .card-box-heading-container {
        text-align: left;
        margin-bottom:32px;
        padding:0;
    }

    .card-box-heading {
        line-height: 40px;
        font-size: 28px;
    }

    .card-box-caption {
        font-size: 20px;
        line-height: 28px;
    }
    
}

@media screen and (min-width:959px) {
    .card-box-item {
        max-width: 800px;
        max-height: 316px;
    }

    .card-box-item-link {
        margin-bottom: 0;
        height:100%;
        border-radius: 24px;
        background-color: rgb(245,245,245);
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        justify-content: space-between;
    }

    .card-box-item-image-container {
        max-width: 316px;
        max-height: 316px;
        min-height: 200px;
        height:100%;
    }

    .card-box-item-image {
        height:100%;
        border-radius: 0px 24px 24px 0px;
    }

    .card-box-item-caption-container {
        margin:0;
        padding:48px 20px 24px 24px;
    }

    .card-box-item-caption-heading {
        margin-top:0;
    }
}


@media screen and (min-width:1240px) {
    .card-box-item-caption-container {
        margin:0;
        padding:32px 20px 32px 32px;
    }
}