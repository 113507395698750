.generic-info-msg-wrapper {
    border: 2px solid rgb(220, 41,30);
    border-radius: 8px;
    width: 100%;
    align-items: center;
    padding:20px 16px;
    text-align: left;
    background-color: rgb(255,242,242);
    font-family: "Open Sans";
    position:relative;
}

.generic-info-msg-container {
    width:100%;
    display:flex;
    align-items: center;
}

.generic-info-msg {
    margin:0;
    font-weight:600;
}

.generic-info-msg-list {
    padding:0;
    list-style-type: none;
    font-weight: 400;
}

.generic-info-msg-list-item::before {
    display: inline-flex;
    content: '.';
    width:4px;
    height:4px;
    margin-right:8px;
    color:transparent;
    align-items: center;
    border:1px solid black;
    background-color: black;
}


.generic-info-msg-close-btn-container {
    display:none;
}

.generic-info-msg-close-btn  {
    appearance: none;
    border:none;
    background-color: transparent;
}

.generic-info-close-btn-path {
    stroke-width: 40;
    stroke: black;
}

.generic-info-extra-msg-btn-container {
    display:flex;
    align-items: center;
    justify-content: center;
    margin:16px 0 0;
    flex:1;
}

.giemc-btn {
    appearance: none;
    border:none;
    padding:8px 16px;
    color:#fff;
    background-color: rgb(220, 41,30);
    font-family: "Open Sans";
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    font-size: 14px;
}

@media (min-width:1024px){
    .generic-info-msg-close-btn-container {
        display:flex;
        align-items: center;
        justify-content: center;
        position:absolute;
        right:0;
        top:0;
    }

    .generic-info-extra-msg-btn-container {
        display:none;
    }

}
