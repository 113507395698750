.shop-product-reviews-container {
    margin:64px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    width:100%;
}

.shop-product-reviews-border-container {
    /*border-top:2px solid #eee;*/
    width: 100%;

}

.shop-product-reviews-heading-container {
    width:100%;
    margin:30px 0 40px;
}

.shop-product-reviews-heading {
    text-align: center;
    color:#333333;
    font-weight: 800;
    font-family: "Open Sans";
    margin:0;
}

.shop-product-reviews-details-container {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    padding:0;
}

.shop-product-reviews-ratings-heading-container {
    display:flex;
    justify-content: space-between;
}

.shop-product-reviews-details-sub-heading {
    font-family: "Marydale Bold" !important;
    font-size:30px;font-style:normal;
    -webkit-font-smoothing: antialiased;
    -webkit-text-stroke-width: 0.2px;
    -moz-osx-font-smoothing: grayscale;
}


@media (min-width:768px){
    .shop-product-reviews-details-container {
        position:relative;
        display: flex;
        flex-direction: row;
        justify-content: center;
        padding:0;
    }
}