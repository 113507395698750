.sssfs-wrapper {
    width:100%;
}

.sssfs-container {
    position: relative;
    width:100%;
    max-width: 1000px;
    min-height: 900px;
    padding:16px;
    margin:0 auto;
}

.sf-search-secondary-label {
    display: inline-flex;
        align-items: center;
        font-size: 14px;
        margin-bottom: 2px;
    }

.store-structure-search-filters-search-term-qty {
    text-align: center;
}

.sssfs-loader-animation-container {
    width:100%;
    display:flex;
    justify-content: center;
    margin-top: 100px;
    height:4500px;
}

.sssfs-table-container {
    width: 100%;
    margin: 0 auto;
    overflow-x: auto;
}

.sssfs-table {
    table-layout: fixed;
    width: 100%;
    min-width: 968px;
    border-collapse: separate;
    border-spacing:0;
    border-radius: 4px;
    border:1px solid black;
    overflow: hidden;
}

.sssfs-table th, 
.sssfs-table td {
    border:1px solid black;
    padding:4px 8px;
    font-family: "Open Sans";
    text-align: left;
}

.sssfs-td {
    display:-webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 2;
}

.sssfs-table .td-action {
    padding:0;
}

.sssfs-table th {
    background-color: #d6d4d4;
    color:#414142;
    font-weight: 700;
    padding:8px;
    border-top:0;
    border-right:0;
    border-bottom:0;
}

.sssfs-table th:first-child {
    border-left:0;
}

.sssfs-table td {
    border-right:0;
    border-bottom:0;
    height:94px;
}

.sssfs-table tr td:first-child {
    border-left:0;
}

.sssfs-table th span {
    display:inline-flex;
}

.sssfs-table .th-sf-ft {
    width:20%;
}

.sssfs-table .th-sf-fa {
    width:20%;
}

.sssfs-table .th-sf-fn {
    width:20%;
}

.sssfs-table .th-sf-st {
    width:15%;
}

.sssfs-table .th-sf-date {
    width:15%;
}
.sssfs-table .th-sf-action {
    width:10%;
}

@media (min-width:360px){
    .sf-search-secondary-label {
        display:none;
    }
}