/*original version
.db-error-message-container {
    position: fixed;
    top:-170px;
    width:485px;
    left:50%;
    transition:top .3s linear;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    background-color: rgb(241, 225, 205);
    z-index: 1;
}
*/

.db-error-message-container {
    position: fixed;
    top:-170px;
    /*margin:0!important;
    left:0!important;*/
    left:50%;
    transform: translateX(-50%);
    width:100%;
    transition:top .3s linear;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    background-color: rgb(241, 225, 205);
    z-index: 8;
    font-family: "Open Sans";
    display:flex;
    flex-wrap: wrap;
    font-family: "Open Sans";
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}

.db-error-message-title {
    flex:1 1 100%;
    font-size: 26px;
    text-align: center;
    background-color: black;
    font-weight: 800;
    padding:8px 16px;
    min-height: 45px;
    color:#fff;
    display: flex;
    align-items: center;
    justify-content: center;
}

.db-error-message-list-container-wrapper {
    width:100%;
    display:block;
    padding:0 6px 0 0;
}

.db-error-message-list-container {
    flex:1 1 100%;
}

.db-error-message-list-title {
    /*border:1px solid blue;*/
    font-size: 22px;
    font-weight: 700;
    margin:0;
    padding:0 16px;
}

.db-error-message-list {
    padding:4px 10px 16px 10px;
    margin:0;
}

.db-error-message-list-item {
    list-style-type: none;
    position: relative;
    /*border:1px solid blue;*/
    padding-left:25px;
    font-size: 14px;
    margin-bottom:5px;
    display: flex;
    align-items: center;
    word-break: break-all;
}

.pie::before {
    content:'';
    position:absolute;
    width:15px;
    height:15px;
    border-radius: 50%;
    background-color: rgb(49, 211, 71);
    margin:1px 0 0 -20px;

}

.ie::before {
    content:'';
    position:absolute;
    width:15px;
    height:15px;
    border-radius: 50%;
    background-color: rgb(252, 160, 39);
    margin:1px 0 0 -20px;
}


.db-error-msg-close-btn-container-reg {
    /*position:absolute;
    top:99%;
    left:0;
    bottom:0;*/
    display:flex;
    align-items: center;
    background-color: rgb(241, 225, 205);
    width:100%;
    height:60px;
    padding:0 16px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}

.db-error-msg-close-btn-container-sm {
    /*position:absolute;
    right:0;
    bottom:0;*/
    display:flex;
    align-items: center;
    background-color: transparent;
    /*width:33%;*/
    height:60px;
    width:100%;
    padding:16px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}

.db-error-msg-close-btn {
    appearance: none;
    background-color: #DC291E;
    color:#fff;
    font-family: "Open Sans";
    font-weight: 600;
    display:flex;
    align-items: center;
    justify-content: center;
    padding:8px 16px;
    min-width:120px;
    border:none;
    border-radius: 4px;
    flex-grow:1;
    font-size: 14px;
    /*margin:16px 8px;*/
}

@media (min-width:330px) {
    .db-error-message-list-container {
        flex:1 1 25%;
    }

    /*
    .db-error-message-list-item {
        white-space: nowrap;
    }
    */
}


@media (min-width:768px){
    .db-error-message-list-container-wrapper {
        display:flex;
    }

    .db-error-msg-close-btn-container-sm {
        justify-content: flex-end;
    }

    .db-error-msg-close-btn-container-reg {
        justify-content: center;
    }

    .db-error-msg-close-btn {
        width:100px;
        flex-grow:0;
    }
}