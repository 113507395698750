.hplid-wrapper {
    position: relative;
    /*border:1px solid black;*/
    width:100%;
    height:100%;
}

.hplid-img-wrapper {
    position:relative;
    width:100%;
    height:100%;
}

.hplid-checkbox-form {
    display: flex;
    position: absolute;
    left:0;
    top:0;
    z-index: 1;
    margin:16px 0 0 16px;
}

.hplid-checkbox-control {
    width:25px;
    height:25px;
}

.hplid-img-container-wrapper {
    width:100%;
    height:0;
    padding-bottom:116.7488%;
    background-color: black;
    border-radius: 24px;
}

.hplid-img-container {
    position:relative;
    width:100%;
    height:0;
    padding-bottom:29.043%;
}

.hplid-img-adj {
    padding-bottom: 100%;
}

.hplid-img {
    position: absolute;
    width:100%;
    height:100%;
    left:0;
    top:0;
    border-radius: 24px;
    object-fit: cover;
    object-position: center center;
}

.hplid-heading-caption-wrapper {
    margin:16px 0 0;
}

.hplid-heading-container {
    /*background-color: #fff;*/
    /*margin:0;*/
    margin:16px 0 0;
}

.hplid-caption-container {
    margin:8px 0 0;
}

.hplid-heading-label, .hplid-caption-label {
    font-weight: 700;
}
