.generic-error-msg-container {
    display:flex;
    border:2px solid rgb(220, 41,30);
    border-radius: 8px;
    width:100%;
    /*margin:32px auto 16px;*/
    align-items: center;
    padding:16px;
    text-align: left;
    background-color: rgb(255,242,242);
}

.generic-error-msg {
    width:16px;
    height:16px;
    margin-right:16px;
    color:rgb(65,65,66);
    flex-shrink:0;
}

.generic-error-msg-text {
    margin:0;
}