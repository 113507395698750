.shop-product-reviews-details-wrapper {
    margin:0 0 20px;
    padding:0 16px;
    font-family: "Open Sans";
}

.shop-product-reviews-select-btn-container {
    display:flex;
    flex-direction: column;
    padding:0 0 16px;
    align-items: center;
}

.shop-product-reviews-group-rating-container {
    margin:0 0 16px;
}

.shop-product-reviews-group-rating-container, .shop-product-reviews-details-select-container {
    padding:0;
}

.shop-product-reviews-details-select-form {
    width:180px;
}

.shop-product-review-ratings-heading {
    font-family: "Marydale Bold" !important;
    font-size:30px;font-style:normal;
    -webkit-font-smoothing: antialiased;
    -webkit-text-stroke-width: 0.2px;
    -moz-osx-font-smoothing: grayscale;
    color:#057DC2;
}



.shop-product-review-ratings-sub-heading {
    font-size: 18px;
    font-weight: 700;
    padding-left:20px;
}

.shop-product-reviews-details-select {
    border-radius: .25rem;
    padding:.25rem .25rem!important;
    width:100%;
}

.shop-product-reviews-details-load-more-reviews-btn {
    border:1px solid grey;
    padding:16px 0;
    font-weight: 700;
    border-radius: 4px;
    outline-style: none!important;
}

.shop-product-reviews-details-load-more-reviews-btn-container {
    display:flex;
    justify-content: center;
    margin-bottom:8px;
}

.star-rating-list-group-container-product-reviews-details-options {
    display: flex;
    justify-content: center;
    align-items: center;
    /*border:1px solid black;*/
    padding:0;
    height:21px;
    
}

.star-rating-list-product-reviews-details-options {
    padding:0;
    display:flex;
    align-items: center;
    list-style-type: none;
    margin-bottom: 0;
    box-sizing: border-box;
}

.star-rating-list li {
    color:rgb(196, 193, 193);
}

.star-rating-list-item-shape {
    color:rgb(30, 133, 218)!important;
}

.product-reviews-details-rating-star-shape {
    font-size:15px!important;
}

@media screen and (min-width:400px) {
    .shop-product-reviews-select-btn-container {
        display:flex;
        flex-direction: row;
        padding:0 0 16px;
        justify-content: space-between;
        align-items: center;
    }

    .shop-product-reviews-group-rating-container {
        margin:0;
    }
}

@media screen and (min-width:768px) {
    .shop-product-reviews-details-wrapper {
        flex-basis:490px;
        margin:0;
        font-family: "Open Sans";
    }
}