.generic-error-msg-2-container {
    display:flex;
    flex-direction: column;
    align-items: center;
}

.generic-error-msg-2-svg {
    width:72px;
    height:72px;
    color:rgb(51,51,51);
    margin-bottom:16px;
}

.generic-error-msg-2-heading {
    font-family: 'Open Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;
    font-size: 20px;
    line-height: 28px;
    font-weight:800;
    margin-bottom: 8px;
    color:rgb(65,65,66);
}

.generic-error-msg-2-text {
    color:#414142;
    font-family: inherit;
}

.generic-error-msg-2-link {
    text-decoration: none;
    color:#007DB3;
    font-size: inherit;
    font-weight: 800;
}

.generic-error-msg-2-link:hover {
    text-decoration: underline;
    color:#007DB3;
}