.approve-reject-product-reviews-form-container {
    margin-top:15px;
}

.approve-reject-product-reviews-form {
    border:1px solid rgb(177, 176, 176);
    border-radius: 8px;
    max-width: 1000px;
    padding: 16px;
    width: 100%;
    margin: 0 auto;
    font-family: "Open Sans";
}

.approve-reject-product-review-form-control-container {
    margin-bottom:8px;
}

.approve-reject-product-review-form-control {
    font-weight: 700;
    font-size: 18px;
    display:block;
    width:100%;
    border-radius: 4px;
    border:1px solid rgb(177, 176, 176);
}

button.close {
    border-style: none;
    outline-style: none;
}

.approve-reject-product-review-form-control-container-btns {
    margin:32px 0;
    display:flex;
    flex-direction: column;
    justify-content: center;
    position:relative;
}

.approve-reject-product-review-approve-btn,
.approve-reject-product-review-reject-btn,
.approve-reject-product-review-close-btn {
    display:flex;
    align-items: center;
    justify-content: center;
    appearance: none;
    border:none;
    background-color:black;
    font-family: "Open Sans";
    font-weight: 600;
    font-size: 14px;
    color:#fff;
    padding:8px 32px;
    border-radius: 4px;
    width:100%;
}

.approve-reject-product-review-approve-btn {
    background-color:green;
}

.approve-reject-product-review-reject-btn {
    background-color: red;
    margin:16px 0 0;
}

.approve-reject-product-review-close-btn {
    background-color:black;
    margin:16px 0 0;
}

.approve-reject-product-review-title {
    text-align: center!important;
}

.show-approve-reject-product-review-spinner {
    display:block;
}

.hide-approve-reject-product-review-spinner {
    display:none;
}

@media (min-width:480px) {
    .approve-reject-product-review-form-control-container-btns {
        flex-direction: row;
        align-items: center;
    }

    .approve-reject-product-review-approve-btn,
    .approve-reject-product-review-reject-btn,
    .approve-reject-product-review-close-btn {
        width:100px;
    }

    .approve-reject-product-review-reject-btn {
        margin:0 0 0 8px;
    }
    
    .approve-reject-product-review-close-btn {
        background-color:black;
        margin:0 0 0 8px;
    }
}