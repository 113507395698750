:root {
    --bmvw:767px;
    --hm1:1%;
    --hm2:99%;
}


.banner-mobile-view-wrapper,
.banner-dekstop-view-wrapper {
    position: relative;
    margin-bottom:64px;
}

.banner-mobile-view-container {
    position: relative;
    max-width: 767px;
    width:100%;
    margin: 0 auto;
}

.bmv-option-list-container {
    position:absolute;
    top:0;
    left:0;
    opacity:1;
    height:100%;
    width:100%;
    background-color: rgb(230, 230, 233);
    display: flex;
    align-items: center;
    border-radius: 8px;
}

/*previous version without grid
.bmv-option-list {
    display:flex;
    align-items: center;
    justify-content: space-between;
    padding:0;
    margin:0;
    flex:1;
    list-style-type: none;
}
*/

.bmv-option-list {
    display:grid;
    grid-template-columns: repeat(auto-fit, minmax(50px, 1fr));
    padding:0;
    margin:0;
    flex:1;
    list-style-type: none;
}

.bmv-option-list-item {
    width:60px;
    height:60px;
    border-radius: 50%;
    display:flex;
    align-items: center;
    justify-content: center;
    justify-self: center;
    font-size: 14px;
    font-weight: 700;
    font-family: "Open Sans";
}

.bmv-option-list-item:nth-child(1) {
    grid-column: 1/2;
    grid-row: 1/2;    
}

.bmv-option-list-item:nth-child(2) {
    grid-column: 2/3;
    grid-row: 2/3;
}

.bmv-option-list-item:nth-child(3) {
    grid-column: 3/4;
    grid-row: 1/2;
}

.bmv-option-list-item:nth-child(4) {
    grid-column: 4/5;
    grid-row: 2/3;
}

.bmv-option-list-item:nth-child(5) {
    grid-column: 5/6;
    grid-row: 1/2;
}

.bmv-option-list-item:nth-child(6) {
    grid-column: 6/7;
    grid-row: 2/3;
}

.bmv-option-list-item:nth-child(7) {
    grid-column: 7/8;
    grid-row: 1/2;
}


.bmv-option-list-item-btn {
    appearance: none;
    border:none;
    width:100%;
    height:100%;
    border-radius: 50%;
    background-color: transparent;
    display:flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 700;
    font-family: "Open Sans";
    background-color: red;
    color:#fff;
}   


.banner-mobile-width-measure-container {
    position: absolute;
    display: flex;
    align-items: center;
    left: 0;
    top: -26px;
    width: 100%;
    border-left: 1px solid red;
    border-right: 1px solid red;
}

.banner-mobile-height-measure-container {
    position: absolute;
    display: flex;
    align-items: center;
    left:0;
    top: 0;
    width:280px;
    border-left: 1px solid red;
    border-right: 1px solid red;
    transform: rotate(90deg);
    z-index: 1;
}

.hide-banner-mobile-measure-container {
    display:none;
}

.banner-mobile-width-measure-line {
    border-top:2px dashed red;
    flex:1 1 100%;
}

.banner-mobile-height-measure-line-1 {
    border-top:2px dashed red;
    flex:1 1 var(--hm1);
}

.banner-mobile-height-measure-line-2 {
    border-top:2px dashed red;
    flex:1 1 var(--hm2);
}

.banner-mobile-width-measure {
    width:49px;
    text-align: center;
    border:0;
    margin:0 12.5px;
    background-color: #fff;
    color:red;
    font-weight: 700;
    padding:0;
    outline: none;
}

.banner-mobile-height-measure {
    width:49px;
    text-align: center;
    border:0;
    transform:rotate(-90deg);
    background-color: #fff;
    color:red;
    font-weight: 700;
    padding:0;
    outline: none;
}

.banner-mobile-view-option-wrapper {
    display:flex;
    flex-direction: column;
    align-items: center;
    margin-bottom:60px;
}

.banner-mobile-view-option-form {
    width:100%;
    display:flex;
    justify-content: center;
    display:none;
}

.hide-banner-mobile-view-option-form {
    display:none;
}

.banner-mobile-view-option-container {
    position:relative;
    display: flex;
    max-width:768px;
    width:100%;
    height:150px;
    flex-direction: column;
    align-items: flex-end;
}

.banner-mobile-view-option-label {
    text-align: left;
}

.banner-mobile-view-option-range {
    position:absolute;
    left:0;
    top:50%;
    transform: translateY(-50%);
    appearance: none;
    background-color: rgb(201, 197, 197);
    width:100%;
    height:10px;
    border-radius: 24px;
    cursor: pointer; /* Cursor on hover */
    margin-top:10px;
    display:none;
}

.banner-mobile-view-option-range::-webkit-slider-thumb {
    -webkit-appearance: none; /* Override default look */
    appearance: none;
    width: 35px; /* Set a specific slider handle width */
    height: 35px; /* Slider handle height */
    border-radius: 24px;
    background-color:rgb(245, 102, 7);
  }
  
  .banner-mobile-view-option-range::-moz-range-thumb {
    width: 35px; /* Set a specific slider handle width */
    height: 35px; /* Slider handle height */
  }

.banner-wrapper {
    width:100%;
    position:relative;
    /*padding:16px 16px;*/
}

/*add to display wrapper on home page*/
.banner-wrapper-full-length {
    padding: 16px 0;
}

.banner-link {
    display: flex;
    position: relative;
    width:100%;
    height:0;
    padding-bottom:116.618%;
}

.banner-image {
    position:absolute;
    left:0;
    top:0;
    width:100%;
    height:100%;
    border-radius: 24px;
}

.banner-img-full-length {
    border-radius: 0;
}

.banner-heading-btn-wrapper {
    inset:0;
    display: flex;
    position:absolute;
}

.bhbw-center{
    justify-content: center;
}

.bhbw-left{
    justify-content: flex-start;
}

.banner-heading-btn-container {
    display: flex;
    flex-direction: column;
    position:absolute;
}

.bhbc-mobile-top-center, .bhbc-desktop-top-center {
    align-items: center;
    top:10%;
    max-width: 81%;
}

.bhbc-desktop-left-center, .bhbc-mobile-left-center {
    align-items: flex-start;
    top:50%;
    transform:translateY(-50%);
    margin:0 0 0 4.65%;
    max-width: 72%;;
    text-align: left;
}

.bhbc-mobile-left-top {
    align-items: flex-start;
    top:10%;
    margin:0 7%;
    max-width: 72%;;
    text-align: left;
}

/*
.banner-heading-single-container {
    display: block;
}

.banner-heading-double-container {
    display:none;
}
*/

.banner-heading {
    font-weight: 800;
    line-height: 1.1;
    color:#414142;
    font-family:"Open Sans";
}

.bh-lg {
    font-size: 24px;
}

.bh-reg {
    font-size: 20px;
}

.banner-caption {
    font-weight: 400;
    line-height: 1.4;
    margin: 7px 0 0 0;
    color:#414142;
    font-size: 15px;
    font-family: "Open Sans";
}

.logo-container-shop-btn {
    border-style:none;
    cursor: pointer;
    margin-top:12px;
    border-radius: 50px;
    display:flex;
    justify-content: center;
    align-items: center;
    color:#fff;
    background-color: rgb(0, 125, 179);
    min-width:140px;
    height:40px;
    font-size: 14px;
    line-height: 20px;
    padding:10px 20px;
    box-shadow: none;
    outline:none!important;
    transition:background-color .25s cubic-bezier(0.4, 0, 0.2, 1) 0s;
    font-family: "Open Sans";
    font-weight: 800;

}

.logo-container-shop-btn:hover {
    background-color: rgb(17, 99, 134);
}

.logo-container-shop-btn-link, .logo-container-shop-btn-link:hover {
    text-decoration: none;
    color:#fff;
}

.shop-whats-new-btn-container {
    position:absolute;
    top:2%;
    right:2%;
}

.shop-whats-new-btn {
    padding:8px 32px;
    border-radius: 4px;
    background-color: red;
    color:#fff;
    border:none;
    outline: none!important;
    transition:background-color .25s ease;
    font-family: "Open Sans";
    font-weight: 800;
    font-size: 14px;
}


.shop-whats-new-btn:hover {
    background-color: rgb(187, 41, 41);
}


.shop-whats-new-banner-container {
    position:relative;
    height:auto;
    max-width:200px;
    display:flex;
    justify-content: center;
    align-items: center;
    border:1px solid solid green;
}

.shop-whats-new-banner {
    max-width:100%;
    height:auto;
}

.banner-form-fieldset-checkbox {
    display:flex;
    align-items: center;
}

.banner-form-label-checkbox {
    text-align: left;;
    margin:0 0 0 5px;
}

.banner-form-checkbox-control {
    width:22px;
    height:22px;
    margin:8px 0;
}

.banner-form-fieldset {
    display:flex;
    flex-direction: column;
    margin-bottom:8px;
}

.banner-form-select-control {
    height: 36px;
    border: 1px solid rgb(177, 176, 176);
    background-color: transparent;
    border-radius: 4px;
    outline: none;
    appearance: none;
    background-image: url('data:image/svg+xml;utf8,<svg width=\"16\" height=\"16\" viewBox=\"0 0 16 16\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\"><path fillRule=\"evenodd\" clipRule=\"evenodd\" d=\"M1.65 4.24a1 1 0 0 0-1.3 1.52l7 6a1 1 0 0 0 1.3 0l7-6a1 1 0 0 0-1.3-1.52L8 9.684 1.65 4.24Z\" fill=\"currentColor\"/></svg>');
    background-repeat: no-repeat;
    background-size: 14px 14px;
    /*background-position: right center;*/
    background-position: calc(100% - 14px);
    padding:0 4px;
    -webkit-appearance: none;
    color:#414142;
}

.banner-form-label {
    text-align: left;;
}

.banner-btn-desktop {
    display:none;
}

.banner-btn-mobile {
    margin-top:12px;
}

.bh-desktop-on-mobile-view-reg {
    font-size: calc(20px - ((768px - 100vw) * .0164));
}

.bh-desktop-on-mobile-view-lg {
    font-size: calc(24px - ((768px - 100vw) * .0246));
}

.ch-desktop-on-mobile-view {
    font-size: calc(15px - ((768px - 100vw) * .0143));
    margin-top: calc(7px - ((768px - 100vw) * .0143));
    margin-bottom: calc(16px - ((768px - 100vw) * .0328));
}

.bb-desktop-on-mobile-view {
    margin-top: calc(12px - ((768px - 100vw) * .0246));
    height: calc(40px - ((768px - 100vw) * .082));
    font-size: calc(14px - ((768px - 100vw) * .0143));
    padding-left:calc(20px - ((768px - 100vw) * .0164));
    padding-right: calc(20px - ((768px - 100vw) * .0164));
}

.bh-mobile-view-sm {
    font-size: 20px;
}

.bh-mobile-view-sm {
    font-size: 24px;
}

.ch-mobile-view-sm {
    font-size: 15px;
}

.banner-view-header-container {
    margin:40px 0;
}

.banner-view-heading {
    text-align: center;
    font-family: "Open Sans";
    font-size: 26px;
    color: #414142;
    font-weight: 800;
}

@media (min-width:360px) {
    .banner-mobile-view-option-form {
        display:flex;
    }
}


@media screen and (min-width:375px) {
    .bh-lg {
        font-size: calc(24px + 29 * ((100vw - 375px) / 393));
    }
    .bh-reg {
        font-size: calc(20px + 20 * ((100vw - 375px) / 393));
    }
    
    .banner-caption {
        font-size: calc(15px + 9 * ((100vw - 375px) / 393));
    }

    .bh-desktop-on-mobile-view-reg {
        font-size: calc(20px - ((768px - 100vw) * .0164));
    }

    .bh-desktop-on-mobile-view-lg {
        font-size: calc(24px - ((768px - 100vw) * .0246));
    }

    .ch-desktop-on-mobile-view {
        font-size: calc(15px - ((768px - 100vw) * .0143));
        margin-top: calc(7px - ((768px - 100vw) * .0143));
        margin-bottom: calc(16px - ((768px - 100vw) * .0328));
    }

    .bb-desktop-on-mobile-view {
        margin-top: calc(12px - ((768px - 100vw) * .0246));
        height: calc(40px - ((768px - 100vw) * .082));
        font-size: calc(14px - ((768px - 100vw) * .0143));
        padding-left:calc(20px - ((768px - 100vw) * .0164));
        padding-right: calc(20px - ((768px - 100vw) * .0164));
    }

}

@media screen and (min-width:768px){
    /*uncomment when testing homepage layout with banner display*/
    /*
    .banner-wrapper {
        padding:32px;
    }
    */

    .banner-wrapper-full-length {
        padding: 32px 0;
    }

    .banner-link {
        padding-bottom:29.0124%;
    }

    .bhbc-desktop-top-center {
        align-items: center;
        top:10%;
        /*30% is the default max-width based on heb banner specs
        put this back after testing*/
        /*max-width: 30%;*/
    }

    .bh-mobile-on-desktop-view {
        max-width: 81%;
    }

    .bhbc-desktop-left-top {
        align-items: flex-start;
        top:10%;
        margin:0 0 0 4.65%;
        max-width: 72%;;
        text-align: left;
    }

    .banner-btn-mobile {
        display:none;
    }

    .banner-btn-desktop {
        display:block;
        margin-top:12px;
    }

    /*
    .banner-heading-single-container {
        display: none;
    }
    */

    /*
    .banner-heading-double-container {
        display:block;
    }
    */

    .bh-lg {
        font-size: calc(24px + 20 * ((100vw - 768px) / 532));
    }

    .bh-reg {
        font-size: calc(20px + 20 * ((100vw - 768px) / 532));
    }

    .banner-caption{
        font-size:calc(15px + 1 * ((100vw - 768px) / 532));
    }

    .bh-mobile-on-desktop-view-reg {
        font-size: calc(20px + 20 * ((100vw - 375px) / 393));
    }

    .bh-mobile-on-desktop-view-lg {
        font-size: calc(24px + 29 * ((100vw - 375px) / 393));
    }

    .ch-mobile-on-desktop-view {
        font-size: calc(15px + 9 * ((100vw - 375px) / 393));
    }

    .bh-mobile-view-reg {
        font-size: calc(calc(20px + 20 * ((var(--bmvw) - 375px) / 393)));
    }

    .bh-mobile-view-lg {
        font-size: calc(calc(24px + 29 * ((var(--bmvw) - 375px) / 393)));
    }

    .ch-mobile-view {
        font-size: calc(15px + 9 * ((var(--bmvw) - 375px) / 393));
    }
    
}

@media screen and (min-width:1024px){
    .banner-mobile-view-option-container {
        width:248px;
    }

    .bmv-option-list-container {
        display:none;
    }

    .banner-mobile-view-option-range {
        display:flex;
    }

}

@media screen and (min-width:1300px){
    .bh-lg {
        font-size:44px;
    }

    .bh-reg {
        font-size:40px;
    }

    .banner-caption {
        font-size: 16px;
    }

    .bh-mobile-on-desktop-view-reg {
        font-size: calc(20px + 20 * ((100vw - 375px) / 393));
    }

    .bh-mobile-on-desktop-view-lg {
        font-size: calc(24px + 29 * ((100vw - 375px) / 393));
    }

    .ch-mobile-on-desktop-view {
        font-size: calc(15px + 9 * ((100vw - 375px) / 393));
    }

    .bh-mobile-view-reg {
        font-size: calc(calc(20px + 20 * ((var(--bmvw) - 375px) / 393)));
    }

    .bh-mobile-view-lg {
        font-size: calc(calc(24px + 29 * ((var(--bmvw) - 375px) / 393)));
    }

    .ch-mobile-view {
        font-size: calc(15px + 9 * ((var(--bmvw) - 375px) / 393));
    }
}

/*styles for maintaining aspect ratio less than 768px*/

.bh-mobile-on-mobile-view-reg {
    font-size: calc(calc(20px + 20 * ((var(--bmvw) - 375px) / 393)));
}


.bh-mobile-on-mobile-view-lg {
    font-size: calc(calc(24px + 29 * ((var(--bmvw) - 375px) / 393)));
}

.ch-mobile-on-mobile-view {
    font-size: calc(15px + 9 * ((var(--bmvw) - 375px) / 393));
}