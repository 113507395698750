.edit-search-filter-heading {
    text-align: center;
    font-family: "Open Sans";
    font-size: 26px;
    color: #414142;
    font-weight: 800;
    margin: 40px 0 40px;
}

.edit-search-filter-form {
    border:1px solid black;
}

.edit-search-filter-input-form-group {
    display:flex;
    flex-direction: column;
    margin-bottom: 8px;
}

.edit-search-filter-input-form-group:nth-child(2) {
    margin-bottom: 16px;
}

.edit-search-filter-input-form-group > input {
    border-radius: 4px;
}

.edit-search-filter-input-form-group > .edit-search-filter-name-error-msg {
    /*border:2px solid red;*/
    border-left:5px solid red;
}

.edit-search-filter-checkbox-form-group {
    display:flex;
    align-items: center;
    margin-bottom: 8px;
}

.edit-search-filter-checkbox-form-group > label {
    margin:0;
    padding-left:8px;
}

.show-edit-search-filter-name-error-msg {
    display:flex;
    align-items: center;
    /*border-left:3px solid red;
    border-right:3px solid red;
    border-bottom:3px solid red;*/
    background-color: rgb(252, 198, 198);
    color:rgb(122, 9, 9);
    font-weight: 600;
    font-size:14px;
    font-family:"Open Sans";
    padding:4px;
    border-radius: 4px;
    height:36px;

}

.hide-edit-search-filter-name-input-error-border {
    border:1px solid black;
}

.show-edit-search-filter-name-input-error-border {
    border-left:3px solid red;
    border-right:3px solid red;
    border-top:3px solid red;
    border-bottom:1px solid red;
}


.add-search-filter-save-btn,
.add-search-filter-cancel-btn {
    appearance: none;
    border: none;
    background-color: black;
    color: #fff;
    font-family: "Open Sans";
    font-weight: 600;
    font-size:14px;
    padding: 8px 16px;
    border-radius: 4px;
    min-width:120px;
    display:flex;
    align-items: center;
    justify-content: center;
}

.add-search-filter-cancel-btn {
    margin:8px 0 0 0;
}

.add-search-filter-save-btn,
.add-search-filter-cancel-btn {
    flex:1 1 100%;
}

.add-search-filter-save-btn {
    background-color: green;
}

.add-search-filter-cancel-btn  {
    background-color: blue;
}

@media (min-width:768px){
    .add-search-filter-save-btn,
    .add-search-filter-cancel-btn  {
        flex:unset;
    }

    .add-search-filter-cancel-btn {
        margin:0 0 0 8px;
    }   
}