.recaptcha-wrapper {
    padding:15px;

}

.recaptcha-caption-logo-wrapper {
    display:flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    background-color: #333333;
    padding:30px 15px;
}

.recaptcha-caption-container {
    font-family: "Open Sans";
    width:100%;
    display:flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin:0;
}

.recatpcha-text {
    margin:0;
    font-size: 18px;
    color:#fff;
}

.recaptcha-logo-container-wrapper {
    width:100%;
    display:flex;
    align-items: center;
    justify-content: center;
    margin:16px 0 0 0;
}

.recaptcha-logo-container {
    background-color: red;
    width:81px;
    height:31px;
    border-radius: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.recaptcha-logo {
    width:73px;
    height:25px;
    font-size: 18px;
}

.recaptcha-element-wrapper {
    padding: 40px 15px;
    height:224px;
    background-color: #F5F5F5;
    display: flex;
    justify-content: center;
}

.recaptcha-generic-error-msg-container {
    position:fixed;
    top:0;
    left:50%;
    transform:translate(-50%, 25%);
    padding:15px;
    width:100%;
    max-width: 768px;
}

@media (min-width:364px) {
    .recaptcha-caption-container {
        align-items: center;
    }
}

@media (min-width:461px) {
    .recaptcha-caption-container {
        width:unset;
        display:flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        margin:0 16px 0 0;
    }

    .recaptcha-logo-container-wrapper {
        width:unset;
        display:flex;
        align-items: center;
        justify-content: center;
        margin:0;
    }
}

@media (min-width:768px) {
    .recaptcha-wrapper {
        width:693px;
        transform: translate(-50%, 40%);
        position:fixed;
        top:0;
        left:50%;
    }

    .recaptcha-caption-logo-wrapper {
        padding:30px 40px;
    }

    .recaptcha-caption-container {
        margin: 0;
    }
    
    .recatpcha-text {
        font-size: 24px;
        line-height: 30px;
    }

    .recaptcha-logo-container {
        width:100px;
        height:34px;
    }

    .recaptcha-logo {
        width:92px;
        height:26px;
    }

}