.store-location-list-container {
    width:100%;
}

.store-location-list {
    list-style-type: none;
    padding:0;
    margin:0;
}

.store-location-list-item-font-shape {
    font-family: "Open Sans";
    display:flex;
    align-items: center;
}

.store-location-list-item-operation-hours-wrapper {
    display:grid;
    grid-template-columns: auto 1fr;
    gap:4px 16px;
    color: rgb(65, 65, 66);
    font-family: "Open Sans";
    margin-bottom: 0px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    margin-bottom:16px;
}

.store-location-list-item-operation-hours-container {
    display:flex;
    flex-direction: column;
}

.store-location-list-item-operation-hours-store-phone {
    color:#007DB3;
}

.store-location-phone-hours-item-shape {
    display:block;
    width:115px;
}

.store-location-phone-hours-value-shape {
    display:block;
    margin-left: left 115px;
    padding-left:2%;
}

.store-location-list-item {
    border-bottom: 2px solid rgb(215, 215, 216);
    padding: 16px 0px;
}

.store-location-list-item:first-child {
    border-top:none;
}

.store-location-list-item:last-child {
    border-top:none;
}

.store-location-marker-container {
    position: relative;
    display:flex;

}

.store-location-marker {
    position:absolute;
    display:none;
    /*top:-4px;*/
    top:50%;
    transform: translateY(-50%);
    left:-30px;
    /*display: block;*/
    background: url('https://server.kando-proto-3.com/store-location-marker.png');
    background-repeat: no-repeat;
    background-position-x:center;
    background-position-y: center;
    background-size: 20px 34px;
    height: 34px;
    padding-top: 0.25rem;
    margin: 0 5px;
    text-align: center;
    width: 20px;
    font-weight: bold;
    font-size: 11px;
    color: #FFF;
}

.map-location-marker-header {
    position: absolute;
    font-size:13px;
    font-weight:bold;
    color:white;
    /*background-color: rgb(79, 182, 79);*/
    /*padding:.15rem .35rem;*/
    border-radius: 5rem;
    /*border:1px solid white;*/
    top:-1px;
    margin:auto;
    width:1.15rem;
    height:1.15rem;
}

.map-location-marker-header-sm {
    padding:.15rem .35rem;
    left:28%;
}

.map-location-marker-header-lg {
    padding:.15rem .15rem;
    left:27%;
}

.store-location-flag-shape {
    height:35px;
    width:45px;
}

.store-location-list-heading-link {
    appearance: none;
    text-decoration: none;
    color:#414142;
    font-size: 16px;
    line-height: 24px;
    font-weight: 800;
}

.store-location-list-heading-link:hover {
    text-decoration: none;
    color:#414142;
}

.store-location-list-item-location-detail-container {
    display:flex;
    margin-bottom:16px;
}

.store-location-list-item-location-address-container {
    font-family: "Open Sans";
    display: flex;
    justify-content: center;
    flex-direction: column;
    flex:1 1;
}

.store-location-list-item-location-address {
    color: rgb(65, 65, 66);
    margin-bottom: 0px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    padding-right:16px;
}

.store-location-list-item-location-distance-container {
    display:flex;
    justify-content: flex-end;
    font-family: "Open Sans";
}

.store-location-list-item-location-distance {
    color:#717172;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
}

.store-location-list-item-curbside-pickup-container {
    display:flex;
    align-items: center;
    margin-bottom: 16px;
}

.store-location-list-item-curbside-pickup-svg {
    margin-right: 8px;
    width: 24px;
    height: 24px;
}

.store-location-list-item-curbside-pickup-text {
    font-family: "Open Sans";
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    color:#414142;
}

.store-location-list-item-curbside-store-detail-container {
    display:flex;
    flex-wrap: wrap;
    align-items: center;
    gap:16px;
}

.store-location-details-link {
    color:#007DB3;
    font-size: 14px;
    text-decoration: none;
    font-family:"Open Sans";
    appearance: none;
}

.store-location-details-link:hover {
    color:#007DB3;
    font-size: 14px;
    text-decoration: underline;
}

.store-location-list-error-msg-container {
    margin-top:16px;
    width:100%;
}

.sll-loader-animation-container {
    display: flex;
    justify-content: center;
    width:100%;
    margin-top:20px;
}


@media (min-width:1024px) {
    .store-location-marker {
        display:flex;
        justify-content: center;
    }
}

.hide-location-marker {
    display:none;
}