.store-locator-wrapper {
    position: relative;
    width:100%;
    min-height:950px;
}

.store-locator-container {
    display: flex;
    flex-direction: column;
    padding:16px;
    max-width: 1440px;
    width:100%;
    margin:0 auto;
}

.store-locator-input-control-container{
    display:flex;
    width:100%;
    margin-right: 8px;
    align-items: center;
    border-radius: 4px;
    border:1px solid rgb(197, 197, 197);
}

.store-locator-input-control {
    font-size: 16px;
    font-family: "Open Sans";
    margin: 0px;
    padding: 8px;
    border-radius: 4px;
    border:none;
    appearance: none;
    height: 44px;
    background-color: rgb(255, 255, 255);
    outline: none;
    flex:1 1;
    width:100%;
}

.store-locator-input-control:focus {
    outline: #007DB3 solid 2px;
}

.store-locator-input-form {
    display:flex;
    margin-bottom:16px;
    font-family: "Open Sans";
}

.store-locator-input-form-btn {
    background-color: #007DB3;
    color:#fff;
    border-radius: 4px;
    appearance: none;
    border: none;
    padding:8px 16px;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    transition:background-color .25s ease-in-out;
}

.store-locator-input-form-btn:hover {
    background-color: #0270a0;
}

.store-locator-input-image-container {
    height:0;
    width:100%;
    padding-bottom:20%;
    position: relative;
}

.store-locator-input-image {
    position: absolute;
    width:100%;
    height:100%;
}

.store-locatation-not-found-message-container {
    background-image: url('https://server.kando-proto-3.com/oops.png');
    background-size:30px 30px;
    background-repeat: no-repeat;
    background-position-x: 25px;
    background-position-y: center;
    min-height: 4rem;
    background-color: #F2DFDE;
    border:1px solid #dc291e;
    color:red;
    border-radius: 4px;
    margin:1rem 0;
    padding:5px 25px 8px 80px;
}

.slbi:nth-child(1){
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
}

.slbi:nth-child(1) svg {
    transform: scaleX(-1);
}

.slbi:nth-child(2) {
    display:none;
}

.store-locator-heading {
    color: rgb(65, 65, 66);
    font-family: "Open Sans";
    margin-bottom: 16px;
    font-size: 24px;
    line-height: 32px;
    font-weight: 800;
}

.store-locator-loader-animation-container {
    display:flex;
    justify-content: center;
    width:100%;
    margin-top: 264px;
    min-height: 2000px;
    background-color: orange;
}


@media (min-width:768px) {
    .store-locator-container {
        padding:16px 32px;
    }

    .slbi:nth-child(1){
        display: flex;
        flex-direction: row;
    }
    
    .slbi:nth-child(1) svg {
        transform: none;
    }
    
    .slbi:nth-child(2) {
        display:flex;
        align-items: center;
    }
}