.list-changer-container {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(247, 247, 247);
    padding: 16px;
    margin: 8px 4px;
    border-radius: 4px;
    font-family: "Open Sans";
}

.list-changer-main-btn {
    color:#057dc2;
    background-color: transparent;
    border-style: none;
    font-weight: bold;
    outline-style: none!important;
}

.list-changer-main-btn-color {
    color:red;
    font-weight: bold;
    background-color: transparent;
    border-style: none;
    outline-style: none!important;    
}

.list-changer-main-btn-hide {
    display:none;
}

.list-changer-next-btn, .list-changer-previous-btn {
    color:#057dc2;
    background-color: transparent;
    border-style: none;
    outline-style: none!important;
    font-weight: bold;
}

.list-changer-ellipsis-btn {
    color:#057dc2;
    background-color: transparent;
    border-style: none;
    outline-style: none!important;
    font-weight: bold;
}

.list-changer-ellipsis-btn-hide {
    display:none;
}

@media (min-width:768px) {
    .list-changer-container {
        margin:8px 0;
    }
    
}
