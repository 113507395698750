.product-search-wrapper {
    width:100%;
    background-color: #DC291E;
    position:relative;
    /*previous version
    z-index: 4;
    */
    z-index: 7;
}

.product-search {
    display:flex;
    width:100%;
    max-width: 1440px;
    background-color: transparent;
    align-items: center;
    justify-content: center;
    margin:0 auto;
}

.product-search-mobile-menu-btn {
    padding:0;
    border:none;
    background-color: transparent;
    font-family: inherit;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width:24px;
    height:24px;
    margin:0 8px 0 0;
    color:#fff;
}

.product-search-mobile-menu-svg-container {
    width:100%;
    display:inherit;
    align-items: inherit;
    justify-content: inherit;
}

.search-container-shape {
    position: relative;
    background-color:#fff;
    border-radius: 24px;
}

.search-container-shape-focus {
    border-radius: 24px;
    /*border:2px solid rgb(36, 154, 194);*/
    border: none;
    box-shadow: rgb(5 125 194) 0px 0px 0px 2px, rgb(255 255 255) 0px 0px 0px 4px;
}

.product-search-input-form {
    width:100%;
}

.product-search-input-container {
    display: inline-flex;
    align-items: center;
    width:100%;
    background-color: #fff;
    border-radius: 24px;
    border:none;
}

.product-search-input-shape{
    width:100%;
    box-sizing: border-box;
    line-height: 20px;
    padding:0 0 0 16px;
    border-radius: 24px;
    border:none;
    outline: none;
    color: rgb(65,65,66);
}

.product-search-input-shape-focus{
    /*border-top-right-radius: 0;
    border-bottom-right-radius: 0;*/
    border-radius: 24px!important;
    border-style: none;
    outline-style: none;
    border-color:rgb(36, 154, 194)!important;
}

.btn-search-shape {
    border-style:none;
    outline: none;
    background-color: transparent;
    padding:0;
    color: rgb(66, 64, 70);
    border-radius: 0px 24px 24px 0px;
    display:flex;
    justify-content: center;
    align-items: center;
}

.btn-search-shape-highlight {
    background-color: #E8E8E8;
}

.btn-search-svg-container {
    width:44px;
    height:36px;
    display:inline-flex;
    justify-content: center;
    align-items: center;
}

.btn-close-shape {
    border-style:none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    box-shadow: none!important;
    outline-style: none;
    background-color: transparent;
    padding:0;
    display:flex;
    align-items: center;
    color:rgb(66,64,70);
}

.btn-close-svg-container {
    width:44px;
    height:36px;
    display:inline-flex;
    justify-content: center;
    align-items: center;
}

.btn-close-shape-remove {
    display:none;
}

.btn-search-shape-clicked {
    border-style:none;
    border-top-right-radius: 24px;
    border-bottom-right-radius: 24px;
    box-shadow: none!important;
    outline-style: none;
    background-color: transparent;
    padding:0;
    display:flex;
    align-items: center;
    justify-content: center;
    background-color:#E8E8E8;
    width:44px;
    height:36px;
}

.fa-times {
    font-size:1.25rem;
    border-style:none;
    box-shadow: none;
    outline-style: none;
    background-color: transparent;
}

.search-options-container {
    margin-top:1rem;
}

.find-a-store {
    font-family: sans-serif;
    justify-content: center;
    align-items: center;
    padding:0!important;
    display:flex;
    align-items: center;
    margin-right: 15px;
}

.find-a-store-heading , .find-a-store-link {
    font-size:14px;
}

.find-a-store-heading {
    font-weight: bold;
}

/*
.find-a-store-link {
    font-size:14px;
}
*/

.fa-map-marker {
    font-size:38px!important;
    color:rgb(79, 182, 79);
    position: relative;
}

.map-marker-header {
    position: absolute;
    font-size:20px;
    font-weight:bold;
    color:white;
    background-color: rgb(79, 182, 79);
    padding:0 .25rem;
    border-radius: 5rem;
    top:10%;
    left:8%;
    margin:auto;
}

.curbside-delivery-container{
    display:none;
    padding:0;
    /*border:1px solid lightgrey;*/
    border-radius:.25rem;
    font-family: sans-serif;
    margin-right: 15px;
}

.curbside-btn-container:hover {
    background-color: lightgrey;
}

.curbside-btn {
    position: relative;
    border:1px solid lightgrey;
    border-radius:.25rem;
    display:flex;
    align-items: center;
}

.delivery-btn {
    border:1px solid lightgrey;
    border-radius:.25rem;
    margin-left:.375rem;
    font-family: sans-serif;
    display:flex;
    align-items: center;
}

.delivery-btn:hover {
    background-color: lightgrey;
    align-items: center;
}

.product-search-mobile-search-btn {
    display:none;
}

.shopping-cart-btn-container {
    position:relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-grow:1;
}

.shopping-cart-btn {
    /*padding:6px 12px;*/
    border-radius:24px;
    line-height: 24px;
    background-color: #0000001A;
    color:white;
    font-weight: 800;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    cursor: pointer;
}

.shopping-cart-btn-total-order-amount {
    margin:0 8px 0 0;
    font-size:16px;
    font-family: "Open Sans";
    display: none;
}

.fa-home {
    font-size:22px!important;
    color:white;
    position:absolute;
    left:12%;
    top:15%;
}

.delivery-circle {
    font-size:32px!important;
    color:grey;
    position: relative;
}

.fa-car {
    font-size:18px!important;
    color:white;
    position:absolute;
    left:10%;
    top:15%;
}

.curbside-circle {
    font-size:30px!important;
    color: rgb(36, 154, 194);
    position: relative;
}

.fa-chevron-right {
    color:lightgrey;
}

.fa-shopping-cart {
    font-size:18px;
}

.product-search-logo-container {
    position:relative;
    background-color: transparent;
}

.product-search-logo-link {
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
}

.product-search-logo {
    box-shadow: rgb(255 255 255) 0px 0px 0px 2px;
    border-radius: 24px;
    width:116px;
    display: flex;
    align-items: center;
    justify-content: center;
    color:#fff;
    font-size: 24px;
    font-weight: 800;
    font-family: "Open Sans";
}

.show-store-search, .show-change-store-search {
    display:block;
}

.hide-store-search, .hide-change-store-search {
    display:none;
}

.adj-mobile-search {
    overflow:hidden;
}


@media screen and (max-width:479px) {
    .product-search-grid {
        display:grid;
        grid-template-columns: 1fr 1fr 1fr;
        padding:8px 16px;
    }

    .product-search-logo {
        width:106px;
        height:32px;
    }

    .product-search-mobile-search-btn {
        padding:8px;
        border:0;
        display:inline-flex;
        align-items: center;
        justify-content: center;
        background-color: transparent;
    }
    
    .product-search-mobile-search-btn-svg-container {
        flex-basis: 24px;
        width:100%;
        display: inherit;
        align-items: inherit;
        justify-content: inherit;
    }
    
    .product-search-mobile-search-btn-svg {
        width:16px;
        height:16px;
        margin-right:8px;
    }

    .keyword-search-wrapper {
        display:none;
    }
}


@media screen and (min-width:480px){
    .product-search {
        padding:16px;
    }

    .shopping-cart-btn {
        padding:6px;
    }

    .keyword-search-wrapper {
        display:flex;
        width:100%;
    }
}

@media screen and (min-width:768px){
    .shopping-cart-btn {
        padding:6px 12px;
    }

    .shopping-cart-btn-total-order-amount {
        display: flex;
    }
}

@media screen and (min-width:1024px){
    .product-search {
        padding:16px 32px;
    }

    .product-search-mobile-menu-btn {
        display:none;
    }

}



