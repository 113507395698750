.add-shop-product-review-btn-container {
    /*border:1px solid black;*/
    flex-basis:490px;
    padding:0 15px;
    font-family: "Open Sans";

}

.add-shop-product-review-btn {
    color:#fff;
    font-weight: bold;
    font-size: 18px;
    background-color: red;
    padding: .75rem 0;
    border-radius: .25rem;
    border: 1px solid black!important;
}

.add-shop-product-review-heading {
    padding:16px 0 0 8px;
    margin:0;
    font-size: 26px;
    line-height: 30px;
    font-weight: 700;
    color:#333333;
}

.add-shop-product-review-sub-heading {
    font-family: "Marydale Bold" !important;
    font-size:30px;font-style:normal;
    -webkit-font-smoothing: antialiased;
    -webkit-text-stroke-width: 0.2px;
    -moz-osx-font-smoothing: grayscale;
}