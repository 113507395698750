.keyword-list-shape {
    position:absolute; 
    list-style-type: none;
    background-color:white;
    border-radius: 24px;
    /*box-shadow: 0px 3px 11px rgba(0,0,0,0.25);*/
    box-shadow: rgb(0 0 0 / 12%) 0px 1px 1px, rgb(0 0 0 / 12%) 0px 2px 2px, rgb(0 0 0 / 12%) 0px 4px 4px, rgb(0 0 0 / 12%) 0px 8px 8px, rgb(0 0 0 / 12%) 0px 16px 16px;
    z-index: 3;
    top:43px;
    left:0px;
    width:100%;
    box-sizing: border-box;
    padding:0;
    font-family: "Open Sans";
    margin:0;
}

.keyword-list-item-shape {
    width:100%;
    line-height: 20px;
    cursor: pointer;
    color:#000000;
}

.keyword-shape-wrapper {
    display:flex;
    align-items: center;
    font-size: 14px;
    line-height: 24px;
    margin-left: 16px;
    height:48px;
}

.keyword-list-item-shape:first-child {
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
}

.keyword-list-item-shape:last-child {
    border-bottom-left-radius: 24px;
    border-bottom-right-radius: 24px;
}

.keyword-list-item-shape:hover {
    background-color: #F3F6F7;
    /*background-color: rgb(240, 239, 239);*/
}

.keyword-list-item-shape-color {
    font-family: "Open Sans";
}

.keyword-no-list-item-shape-color {
    color:#000000;
    font-weight: 700;
    font-family: "Open Sans";
}