/** {box-sizing: border-box;}*/

/*
.shop-product-image-container {
    border:1px solid blue;
    display:flex;
    justify-content: center;
    flex-direction: column;
}
*/

.image-zoom-img-container {
    width:296px;
    height:296px;
    display:flex;
    align-items: center;
    justify-content: center;
}


.shop-product-image {
    /*original dimenstions*/
    /*width:296px;
    height:296px;*/
    /*original height*/
    /*height:auto;*/
    /*backface-visibility: visible;
    -webkit-backface-visibility: visible;
    -webkit-font-smoothing: antialiased !important;
    -webkit-transform: translateZ(0) scale(1.0, 1.0);
    transform: translateZ(0);*/
    max-width: 100%;
    max-height:100%;
}


.shop-product-image-directions {
    text-align: center;
    font-size: 14px;
    color:#333333;
    font-family: "Open Sans";
}

.shop-product-image-zoom-container {
    position: relative;
    display:flex;
    flex-direction: column;
    align-items: center;
    padding:0;
    /*border:2px solid purple;*/
    flex-basis:300px;
    margin:0 25px 0 0;
    z-index: 2;
}

.img-zoom-lens {
    position: absolute;
    border: 1px solid #007DB3;
    background-color: #f3f3f3;
    /*set the size of the lens:*/
    width: 222px;
    height: 222px;
    z-index: 12;
    opacity:0.2;
    transition: opacity .5s ease;
    cursor: pointer;
}

.hide-img-zoom-lens {
    position: absolute;
    border: 1px solid #d4d4d4;
    /*set the size of the lens:*/
    width: 222px;
    height: 222px;
    z-index: 12;
    opacity:0;
    transition: opacity .5s ease;
}

.img-zoom-result {
    position: absolute;
    /*border: 1px solid black;*/
    /*set the size of the result div:*/
    width: 650px;
    height: 650px;
    top:0;
    left:320px;
    opacity:1;
    z-index: 10;
    visibility: visible;
    transition: opacity .5s ease, visibility .5s ease;
    box-shadow: 0px 0px 16px -2px;
    background-repeat: no-repeat;
    background-color: #fff;
    
}

.hide-img-zoom-result {
    position: absolute;
    /*border: 1px solid black;*/
    /*set the size of the result div:*/
    width: 650px;
    height: 650px;
    top:0;
    left:320px;
    opacity:0;
    z-index: 10;
    visibility: hidden;
    /*previous version*/
    /*transition: opacity .5s, visibility .5s;*/
    transition: opacity 0s ease, visibility 0s ease;
    box-shadow: 0px 0px 16px -2px;
    background-repeat: no-repeat;
}