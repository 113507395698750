.shop-overview-department-container-wrapper {
    width:100%;
    min-height: 900px;
}

.shop-overview-department-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width:980px;
    margin:0 auto;
    padding:15px 0 0;
}

.shop-overview-breadcrum-container {
    color:#414142;
    font-size: 14px;
    font-family: "Open Sans";
    display:flex;
    align-items: center;
    justify-content: flex-start;
    width:100%;
    padding:0;
    margin:16px 0 16px;
    list-style-type: none;
}

.shop-overview-store-department-heading-container {
    color:#414142;
    text-align: center;
    word-wrap: break-word;
    margin:8px 0 16px;
    padding:0 16px;
}

.shop-overview-store-department-heading {
    text-align: center;
    font-family: "Open Sans";
    font-weight: 800;
    font-size:26px;
    line-height: normal;
    display:inline-block;
    color:#414142;
    margin:0;
}

.shop-overview-store-department-list-container {
    width:100%;
    display:flex;

}

.shop-overview-store-department-list {
    padding:0;
    list-style-type: none;
    display:flex;
    flex-wrap: wrap;
    /*width:760px;*/
}

.shop-overview-store-department-list-item {
    /*width:186px;*/
    margin:0 0 12px;
    padding:20px 5px 13px 0;
    box-sizing: border-box;
    flex: 1 1 25%;
    max-width: 25%;
}

.shop-overview-store-department-name-link-container {
    height:4.8em;
    box-sizing: border-box;
    padding-top:12px;
    min-height: 19px;
    margin:0 15px;

}

.shop-overview-store-department-name-link,
.shop-overview-store-department-name-link:hover {
    color:#333;
    font-weight: 700;
    text-decoration: none;
    text-align: center;
    display: block;
    font-size:14px;
    line-height: 16px;
    font-family: "Open Sans";
}

.shop-overview-store-department-category-name-link-container {
    padding:3px;
}

.shop-overview-store-department-category-name-link,
.shop-overview-store-department-category-name-link:hover {
    color:#333;
    text-align: center;
    text-decoration: none;
    display: block;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    font-family: "Open Sans";
    padding-top:6px;
}

.shop-overview-store-department-image-link-container{
    text-align: center;
}

.shop-overview-store-department-image-link {
    text-decoration: none;
    width:152px;
}

.shop-overview-store-department-image {
    display: inline-block;
    vertical-align: middle;
    max-width: 100%;
    height:auto;
}

.shop-overview-promotion-content-container {
    display:flex;
    flex-direction: column;
    width:200px;
    padding:20px 0 0 16px;
}

.sopc-item-container {
    min-height: 275px;
    width:100%;
    background-color: #fff;
    margin-bottom: 17px;
}

.sopc-item-container:nth-child(1) {
    background: url('https://server.kando-proto-3.com/bottom-border-purple.png');
    background-repeat: no-repeat;
    background-position: center bottom;
    background-color: #fff;
}

.sopc-item-container:nth-child(2) {
    background: url('https://server.kando-proto-3.com/bottom-border-orange.png');
    background-repeat: no-repeat;
    background-position: center bottom;
    background-color: #fff;
}

.sopc-img {
    width:100%;
    height:auto;
}

.sopc-img-content-info-container {
    color:#333;
    max-width: 198px;
    margin:0 auto;
    border-left: thin solid #D5D7D8;
    border-right: thin solid #D5D7D8;
    padding-bottom: 14px;
}

.sopc-promotion-discount {
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    font-family: "Open Sans";
    padding:11px 0 9px;
    width:170px;
    margin:0 auto;
}

.sopc-promotion-description {
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
    min-height: 30px;
    border-bottom: 2px solid #eee;
    font-family: "Open Sans";
    width:170px;
    margin:0 auto;
}

.sopc-promotion-shop-now-container {
    padding:13px 0 7px;
    font-weight: 400;
    font-size:14px;
    line-height: 16px;
    font-family: "Open Sans";
    color:#333;
    text-align: right;
    width:170px;
    margin:0 auto;
}

.sopc-promotion-shop-now-container a {
    font-weight: 400;
    text-decoration: none;
    color:#333;
    text-align: right;
}

.sopc-promotion-shop-now-container a span {
    display:inline-block;
    width:6px;
    height:9px;
    background:url('https://server.kando-proto-3.com/right-arrow-red.png');
    background-repeat: no-repeat;
    background-position: right 55%;
}

.shop-overview-home-breadcrum-link {
    color:#717172;
    text-decoration: none;
}

.shop-overview-home-breadcrum-link:hover {
    color:#717172;
    text-decoration: underline;
}

.sobli:nth-child(1) {
    display:flex;
    align-items: center;
    flex-direction: row-reverse;
}

.sobli:nth-child(1) span {
    transform: scale(-1);
}

.sobli:nth-child(2){
    display:none;
    align-items: center;
    color:#414142;
}

.so-generic-error-msg-wrapper {
    width:100%;
    display:flex;
    align-items: center;
    justify-content: center;
    margin:16px 0 0;
}

.so-img-container {
    margin:16px 0 0;
    width: 100%;
    text-align: center;
}

.so-add-department-btn {
    appearance: none;
    border: none;
    color: #fff;
    background-color: green;
    padding:0;
    border-radius: 4px;
    min-height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    min-width: 120px;
    font-size: 14px;
    flex:1;
}

.so-add-department-btn-link,
.so-add-department-btn-link:hover {
    padding:8px 32px;
    text-decoration: none;
    color:#fff;
    font-family: "Open Sans";
}



.so-loader-animation-container {
    display:flex;
    width:100%;
    justify-content: center;
    margin-top: 100px;
    height:3500px;
}

.lc-loader-animation-container {
    display:flex;
    width:100%;
    justify-content: center;
    margin-top: 280px;
    height:3500px;
}

@media (min-width:768px){
    .sobli:nth-child(1) {
        flex-direction: row;
    }
    
    .sobli:nth-child(1) span {
        transform: unset;
    }
    
    .sobli:nth-child(2){
        display:flex;
    }

    .so-add-department-btn {
        flex:unset;
    }

}

@media screen and (min-width:1024px){
    .lc-loader-animation-container {
        padding-left:236px;
    }
}


@media screen and (max-width:1023px){
    .shop-overview-department-container {
        width:100%;
    }

    .shop-overview-breadcrum-container {
        width:100%;
        padding:0 8px 0 8px;
        flex-wrap:wrap;
    }

    .shop-overview-home-breadcrum-link {
        color:#717172;
        text-decoration: none;
    }

    .shop-overview-home-breadcrum-link:hover {
        color:#717172;
    }

    .shop-overview-store-department-list-container {
        flex-direction: column;
    }

    .shop-overview-store-department-list {
        width:100%;
        display:grid;
        grid-template-columns: repeat(auto-fill, minmax(186px, 1fr));
        padding:0;
    }

    .shop-overview-store-department-list-item {
        width:100%;
        max-width: 100%;
    }

    .shop-overview-promotion-content-container {
        width:100%;
        display:grid;
        justify-content: center;
        padding:16px 0 0 0;
        margin-top:32px;
        background-color: #e6e6e6;
    }

    .sopc-img {
        max-width:100%;
        width:auto;
        height:auto;
    }

    .sopc-img-container {
        display:flex;
        justify-content: center;
    }

    .so-generic-error-msg-wrapper {
        padding:0 8px 0 8px;
    }
    
}