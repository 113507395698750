.sspcs-wrapper {
    width:100%;
}

.sspcs-container {
    position:relative;
    width:100%;
    max-width: 1000px;
    min-height: 850px;
    padding:16px;
    margin:0 auto;
}

.product-category-search-secondary-label {
    display: inline-flex;
    align-items: center;
    font-size: 14px;
    margin-bottom: 2px;
}

.sspcs-table-container {
    width: 100%;
    margin: 0 auto;
    overflow-x: auto;
}

.sspcs-table {
    table-layout: fixed;
    width: 100%;
    min-width: 968px;
    border-collapse: separate;
    border-spacing: 0;
    border-radius: 4px;
    border:1px solid black;
    overflow: hidden;
}

.sspcs-table th, 
.sspcs-table td {
    border:1px solid black;
    padding:4px 8px;
    font-family: "Open Sans";
    text-align: left;
}

.sspcs-td {
    display:-webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 2;
}

.sspcs-table .td-action {
    padding:0;
}

.sspcs-table .sspcs-img {
    text-align: center;
}

.sspcs-table th {
    background-color: #d6d4d4;
    color:#414142;
    font-weight: 700;
    padding:8px;
    border-top:0;
    border-right:0;
    border-bottom:0;
}

.sspcs-table th:first-child {
    border-left:0;
}

.sspcs-table td {
    border-right:0;
    border-bottom:0;
    height:94px;
}

.sspcs-table tr td:first-child {
    border-left:0;
}

.sspcs-table th span {
    display:inline-flex;
    width:100%;
}

.sspcs-table .th-pc-dept {
    width:20%;
}

.sspcs-table .th-pc-dc {
    width:20%;
}

.sspcs-table .th-pc-pc {
    width:20%;
}

.sspcs-table .th-pc-img {
    width:18%;
}

.sspcs-table .th-pc-date {
    width:12%;
}
.sspcs-table .th-pc-action {
    width:10%;
}

.sspcs-table-image {
    max-width:100%;
    max-height:85px;
}

@media (min-width:555px){
    .product-category-search-secondary-label {
        display:none;
    }
}