.hplb-wrapper {
   width:100%;
   /*margin-bottom:32px;*/
}

.hplb-layout-container {
   max-width: 1440px;
   min-height: 950px;
   margin:0 auto;
   position:relative;
   padding:16px 0 0;
   font-family: "Open Sans";
}

.hplb-container {
   width: 100%;
   height:100%;
   display:grid;
   row-gap: 64px;
   margin:64px auto;
}

.hplb-breadcrum-container {
   display: flex;
    list-style-type: none;
    padding: 0 16px;
    margin: 0 0 16px;
    width: 100%;
    font-family: "Open Sans";
    font-size: 14px;
}

.hplb-item-container {
   width:100%;
   overflow: hidden;
}

.home-page-layout-builder-heading {
   text-align: center;
    font-family: "Open Sans";
    font-size: 26px;
    color: #414142;
    font-weight: 800;
    margin: 40px 0 40px;
    padding:0 16px;
}

.hplb-add-layout-btn-container {
   display:flex;
   align-items: center;
   padding:0 16px;
   margin-bottom:32px;
}

.hplb-info-message {
   top:105px;
}

.hplb-no-results-msg-container {
   display:flex;
   align-items: center;
   justify-content: center;
   margin:32px auto 0;
   padding:0 16px;
}

.hplb-generic-error-msg-wrapper {
   width:100%;
   max-width:768px;
   display:flex;
   align-items: center;
   justify-content: center;
   padding:0 16px 0;
   position:absolute;
   top:30px;
   left:50%;
   transform: translateX(-50%);
}

.hplb-loader-animation-container {
   width:100%;
   display:flex;
   justify-content: center;
   margin-top:100px;
   min-height: 4500px;
}

@media (min-width:768px){
   .hplb-breadcrum-container {
      padding:0 32px;
   }

   .hplb-add-layout-btn-container {
      padding:0 32px;
   }

   .hplb-generic-error-msg-wrapper {
      padding:0 32px 0;
   }
}

@media (min-width:840px){
   .hplb-generic-error-msg-wrapper {
      margin:16px auto 0;
      padding:0;
   }
}
