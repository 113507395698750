.add-product-category-heading {
    text-align: center;
    font-family: "Open Sans";
    font-size: 26px;
    color: #414142;
    font-weight: 800;
    margin: 40px 0 40px;
}

.add-product-category-form {
    border:1px solid black;
}

.add-product-category-input-form-group {
    /*border:1px solid black;*/
    display:flex;
    flex-direction: column;
}

.add-product-category-checkbox-form-group {
    /*border:1px solid black;*/
    display:flex;
    align-items: center;
}

.add-product-category-checkbox-form-group > label {
    margin:0;
    padding-left:.5rem;
}

.add-product-category-thumbnail-image {
    max-width:40px;
    max-height:40px;
}