.admin-wrapper{
    width:100%;
    min-height: 950px;
}

.admin-layout-wrapper {
    position:relative;
    padding:16px;
    width:100%;
    max-width: 1440px;
    margin:0 auto;
    min-height:900px;
}

.admin-heading {
    text-align: center;
    font-family: "Open Sans";
    font-size: 26px;
    color:#414142;
    font-weight: 800;
    margin:40px 0 40px;
}

.admin-nav-list-container {
    font-family: "Open Sans";
    width:100%;
}

.admin-nav-list-heading {
    font-size: 24px;
    font-weight: 700;
}

.admin-nav-list {
    padding:0;
    margin:0;
    list-style-type: none;
    width:100%;
    display:grid;
    grid-gap:16px;
    align-items: start;
}

.admin-nav-list-ss {
    padding:0;
    margin:0;
    list-style-type: none;
}

.anli:nth-child(1) {
    background-color: rgb(220, 252, 220);
}

.anli:nth-child(2) {
    background-color: rgb(248, 248, 206);
}

.anli:nth-child(3) {
    background-color: rgb(252, 238, 212);
}

.anli:nth-child(4) {
    background-color: rgb(218, 218, 250);
}

.admin-access-btn-container {
    display:flex;
    align-items: center;
    width:100%;
    padding:8px;
    margin-bottom:8px;
}

.admin-more-overview-btn-container-show {
    position:absolute;
    display:flex;
    align-items: center;
    width:100%;
    left:0;
    bottom:0;
    background-color: rgb(217, 32, 32);
    padding:2px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}

.admin-more-overview-btn-container-hide {
    display:none;
}

.admin-access-btn, 
.admin-more-overview-btn {
    appearance: none;
    border:none;
    border-radius:4px;
    background-color: lightgrey;
    color:#fff;
    font-weight: 700;
    padding:0;
    min-height:40px;
    display:flex;
    align-items: center;
    justify-content: center;
    flex-grow:1;
    font-family: "Open Sans";
}

.anli {
    border-radius:8px;
}

.anli:nth-child(1) .admin-access-btn {
    background-color: rgb(66, 175, 66);
    /*background-color: #5d5d5f;*/
}

.anli:nth-child(2) .admin-access-btn {
    background-color: rgb(196, 196, 7);
    /*background-color: #5d5d5f;*/
}

.anli:nth-child(3) .admin-access-btn {
    background-color:  rgb(243, 166, 23);
    /*background-color: #5d5d5f;*/
}

.anli:nth-child(4) .admin-access-btn {
    background-color: rgb(67, 67, 187);
    /*background-color: #5d5d5f;*/
}

.admin-more-overview-btn {
    /*background-color: red;*/
    background-color: rgb(217, 32, 32);
}

.admin-nav-list-item-link, .admin-nav-list-item-link:hover {
    display: flex;
    flex-direction: column;
    text-decoration: none;
    color:#fff;
    padding:8px 16px;
    flex-grow:1;
}

.admin-nav-list-item-heading {
    text-align: center;
    font-size: 18px;
    padding:8px 16px;
    font-weight: 700;
    margin:0;
}

.admin-nav-item-list-container {
    position:relative;
    width:100%;
    overflow-y: hidden;
    height:300px;
    transition:height 1s ease-in-out .3s;
    padding-bottom:40px;
}

.admin-nav-item-list {
    margin:0;
    list-style-type:square;
    padding:0 8px 0 25px;
}

.admin-list-wrapper {
    width:100%;
}

.admin-list-total-continer {
    display:flex;
    align-items: center;
    justify-content: space-between;
    background-color: #F7F7F7;
    border:1px solid grey;
    padding:8px;
    margin-bottom: 8px;
    border-radius: 4px;
}

.admin-list-total {
    display:flex;
    align-items: center;
    justify-content: center;
    font-family: "Open Sans";
    line-height: 16px;
    font-weight: 600;
}

.admin-results-per-page-container {
    display:flex;
    align-items: center;
}

.admin-results-per-page-label {
    margin-right:4px;
}

.admin-checkbox-control {
    width:18px;
    height:18px;
    cursor: pointer;
}

.admin-date-added-header-container {
    display:flex;
    align-items: center;
    justify-content: space-between;
}

.admin-date-added-svg-btn-container {
    display: flex;;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
}

.admin-date-added-svg-btn {
    appearance: none;
    border:none;
    background-color: transparent;
    padding:0;
    display:flex;
    align-items: center;
    justify-content: center;
}

.admin-date-added-svg-btn:nth-child(2) {
    margin-top:4px;
}

.sort-up:hover, .sort-down:hover {
    fill:#414142;
    cursor: pointer;
}

.admin-loader-animation-container {
    width:100%;
    display:flex;
    justify-content: center;
    margin-top:100px;
    min-height: 4500px;
    /*background-color: purple;*/
}

.admin-content-filler {
    width:100%;
    height:100vh;
}

/**table styles**/
.admin-table-container {
    max-width: 1000px;
    margin:0 auto;
    overflow-x: auto;
}

.admin-table {
    table-layout: fixed;
    width:100%;
    min-width:950px;
    border-collapse: collapse;
}

.admin-table th, .admin-table td {
    border:1px solid black;
    padding:4px 8px;
    font-family: "Open Sans";
}

.admin-table th {
    background-color: #d6d4d4;
    color:#414142;
    font-weight: 700;
    padding:8px;
}

.admin-table .th-dept {
    width:25%;
}

.admin-table .th-dc {
    width:25%;
}

.admin-table .th-img {
    width:20%;
}

.admin-table .th-action {
    width:10%;
}

.admin-table .th-date {
    width:20%;
}

.admin-td {
    display:-webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 2;
}

.admin-table .td-action {
    padding:0;
}

.admin-img {
    text-align: center;
}

.table-item-btn-container {
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    width:100%;
    border-radius: 4px;
    padding:4px;
}

.edit-table-item-btn, 
.remove-table-item-btn,
.select-table-item-btn {
    display:flex;
    align-items: center;
    justify-content: center;
    appearance: none;
    border:none;
    background-color:black;
    font-family: "Open Sans";
    font-weight: 600;
    font-size: 14px;
    color:#fff;
    padding:8px 32px;
    width:100%;
    border-radius: 4px;

}

.remove-table-item-btn {
    margin-top:8px;
    background-color: black;
}

.select-table-item-btn,
.edit-table-item-btn {
    background-color: green;
}

.abi:nth-child(1) {
    display:flex;
    flex-direction: row-reverse;
}

.abi:nth-child(1) svg {
    transform: scaleX(-1);
}

.abi:nth-child(2) {
    display:none;
}

@media (min-width:500px){
    .admin-nav-list {
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
        grid-gap:16px;
    }
}

@media (min-width:768px){
    .abi:nth-child(1) {
        flex-direction: row;
    }

    .abi:nth-child(1) svg {
        transform: unset;
    }

    .abi:nth-child(2) {
        display:flex
    }
}
