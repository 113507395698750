.map-display-wrapper {
    position:relative;
    width:100%;
}

.map-display-sticky-container {
    width:100%;
    position: sticky;
    top:0;
}

.map-display-container {
    width:100%;
    height:100%;
}

.info-window-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height:100px;
    padding:8px;
}

.info-window-store-name, .info-window-store-name:hover  {
    text-decoration: none;
    border:none;
    outline: none;
    appearance: none;
    color:#414142;
    font-weight: 800;
    font-size:16px;
    line-height: 24px;
    margin-bottom:8px;
    font-family: "Open Sans";
}

.info-window-details-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.info-window-details-address {
    font-family: "Open Sans";
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
}

.info-window-details-btn {
    text-decoration: none;
    border-radius: 4px;
    margin-top:8px;
    font-size:14px;
    font-weight: 400;
    font-family:"Open Sans";
    color:#007DBE;
}

.info-window-details-btn:hover {
    text-decoration: underline;
    color:#007DBE;
}

.map-location-control-container {
    width:24px;
    height:24px;
    background-image: url('https://server.kando-proto-3.com/gps-locator-24x24.png');
    background-repeat: no-repeat;
    margin-right:.6rem;
    cursor: pointer;
    background-position: center;
    position: relative;
    display:none;
}