.add-search-term-container {
    position:relative;
}

.add-search-term-heading {
    text-align: center;
    font-family: "Open Sans";
    font-size: 26px;
    color: #414142;
    font-weight: 800;
    margin: 40px 0 40px;
}

.add-search-term-form {
    border:1px solid black;
}

.add-search-term-input-form-group {
    display:flex;
    flex-direction: column;
    margin-bottom:8px;
}

.add-search-term-input-form-group:nth-child(3) {
    margin-bottom: 16px;
}

.add-search-term-file-form-group {
    display:flex;
    flex-direction: column;
}

.add-search-term-file-form-group > input:focus {
    outline-style: none;
}

.add-search-term-checkbox-form-group {
    display:flex;
    align-items: center;
    margin-bottom:8px;
}

.add-search-term-checkbox-form-group > label {
    margin:0;
    padding-left:8px;
}

.search-term-disable-control {
    background-color: #efebeb;
}

.show-add-search-term-name-error-msg {
    display:flex;
    width:100%;
    align-items: center;
    /*border-left:3px solid red;
    border-right:3px solid red;
    border-bottom:3px solid red;*/
    background-color: rgb(252, 198, 198);
    color:rgb(122, 9, 9);
    font-weight: 600;
    font-size:14px;
    font-family:"Open Sans";
    padding:4px;
    border-radius: 4px;
    height:36px;

}

.hide-add-search-term-name-input-error-border {
    border:1px solid black;
}

.show-add-search-term-name-input-error-border {
    border-left:3px solid red;
    border-right:3px solid red;
    border-top:3px solid red;
    border-bottom:1px solid red;
}

.add-search-term-back-btn,
.add-search-term-save-btn,
.add-search-term-close-btn {
    appearance: none;
    border: none;
    background-color: black;
    color: #fff;
    font-family: "Open Sans";
    font-weight: 600;
    font-size: 14px;
    padding: 8px 16px;
    border-radius: 4px;
    display:flex;
    align-items: center;
    justify-content: center;
}

.add-search-term-save-btn,
.add-search-term-close-btn {
    margin:8px 0 0 0;
}

.add-search-term-back-btn,
.add-search-term-save-btn,
.add-search-term-close-btn {
    flex:1 1 100%;
}

.add-search-term-save-btn {
    background-color: green;
}

.add-search-term-back-btn {
    background-color: blue;
}

.ast-generic-info-message-wrapper {
    width:100%;
    position: absolute;
    display:flex;
    align-items: center;
}

@media (min-width:768px){
    .add-search-term-back-btn,
    .add-search-term-save-btn,
    .add-search-term-close-btn {
        flex:unset;
    }

    .add-search-term-save-btn,
    .add-search-term-close-btn {
        margin:0 0 0 8px;
    }   
}