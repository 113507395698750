.list-viewer-container {
    overflow-x: unset;
}

.list-viewer-heading {
    text-align: center;
    font-size: 20px;
    font-family:"Open Sans";
    color:#414142;
    font-weight: 700;
    margin-bottom:16px;
}

.list-viewer-loader-animation-container {
    width:100%;
    margin-top:100px;
    display:flex;
    justify-content: center;
}