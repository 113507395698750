.store-structure-wrapper {
    width:100%;
    min-height:950px;
}

.store-structure-layout-wrapper {
    position:relative;
    padding:16px;
    width:100%;
    max-width: 1440px;
    margin:0 auto;
    min-height: 900px;
}

.store-structure-heading {
    text-align: center;
    font-family: "Open Sans";
    font-size: 26px;
    color:#414142;
    font-weight: 800;
    margin:40px 0 40px;
}

.store-structure-nav-container {
    display: flex;
}

.store-structure-nav {
    padding: 0;
    margin: 0;
    list-style-type: none;
    width: 100%;
    display: grid;
    grid-gap: 16px;
    align-items: start;  
}

.ssni {
    border-radius: 8px;
}

.ssni:nth-child(1) { 
    background-color: rgb(250, 225, 233);
}

.ssni:nth-child(2) {
    background-color: rgb(220, 252, 220);
}

.ssni:nth-child(3) {
    background-color: rgb(248, 248, 206);
}

.ssni:nth-child(4) {
    background-color: rgb(252, 238, 212);
}

.ssni:nth-child(5) {
    background-color: rgb(218, 218, 250);
}

.ssni:nth-child(6) {
    background-color: rgb(218, 249, 250);
}

.ssni:nth-child(7) {
    background-color: rgb(236, 234, 234);
}

.ssni:nth-child(1) .store-structure-access-btn {
    background-color: rgb(241 83 134);
}

.ssni:nth-child(2) .store-structure-access-btn {
    background-color: rgb(66, 175, 66);
}

.ssni:nth-child(3) .store-structure-access-btn {
    background-color:  rgb(196, 196, 7);
}

.ssni:nth-child(4) .store-structure-access-btn {
    background-color: rgb(243, 166, 23);
}

.ssni:nth-child(5) .store-structure-access-btn {
    background-color: rgb(67, 67, 187);
}

.ssni:nth-child(6) .store-structure-access-btn {
    background-color:  rgb(52 183 187);
}

.ssni:nth-child(7) .store-structure-access-btn {
    background-color: rgb(149 146 146);
}

.store-structure-access-btn-container {
    display:flex;
    align-items: center;
    width:100%;
    padding:8px;
    margin-bottom:8px;
}

.store-structure-more-overview-btn-container-show {
    position:absolute;
    display:flex;
    align-items: center;
    width:100%;
    left:0;
    bottom:0;
    background-color: red;
    padding:2px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}

.store-structure-more-overview-btn-container-hide {
    display:none;
}

.store-structure-access-btn, 
.store-structure-more-overview-btn {
    appearance: none;
    border:none;
    border-radius:4px;
    background-color: lightgrey;
    color:#fff;
    font-weight: 700;
    padding:0;
    min-height:40px;
    display:flex;
    align-items: center;
    justify-content: center;
    flex-grow:1;
    font-family: "Open Sans";
}

.store-structure-more-overview-btn {
    background-color: red;
}

.store-structure-nav-item-link, .store-structure-nav-item-link:hover {
    display: flex;
    flex-direction: column;
    text-decoration: none;
    color:#fff;
    padding: 0;
    flex-grow:1;
}

.store-structure-nav-item-heading {
    text-align: center;
    font-size: 18px;
    padding: 8px 16px;
    font-weight: 700;
    margin: 0;
    font-family: "Open Sans";
}

.store-structure-nav-item-list-container {
    position:relative;
    width:100%;
    overflow-y: hidden;
    height:300px;
    transition:height 1s ease-in-out;
}

.store-structure-nav-item-list {
    margin:0;
    list-style-type: square;
    padding:0 8px 0 25px;
}

.ssi:nth-child(1) {
    display:flex;
    flex-direction: row-reverse;
}

.ssi:nth-child(1) svg {
    transform: scaleX(-1);
}

.ssi:nth-child(2) {
    display:none;
}


@media (min-width:500px){
    .store-structure-nav {
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    }
}

@media (min-width:768px){
    .ssi:nth-child(1) {
        flex-direction: row;
    }

    .ssi:nth-child(1) svg {
        transform: unset;
    }

    .ssi:nth-child(2) {
        display:flex
    }
}
