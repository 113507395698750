/** {box-sizing: border-box;}*/

/*
.shop-product-image-container {
    border:1px solid blue;
    display:flex;
    justify-content: center;
    flex-direction: column;
}
*/


.shop-product-image-multi {
    /*border:1px solid red;*/
    width:196px;
    height:196px;
}


.shop-product-image-directions {
    text-align: center;
    font-size:14px;
    color:#333333;
    font-family: "Open Sans";
}

.shop-product-image-zoom-multi-container {
    position: relative;
    display:flex;
    flex-direction: column;
    align-items: center;
    padding:0;
    /*border:2px solid purple;*/
    flex-basis:300px;
    margin:0 25px 0 0;
    z-index: 2;
    /*border:1px solid black;*/
}

.shop-product-image-zooms-container {
    display: flex;
    justify-content: center;
    align-items: center;
    /*border:1px solid black;*/
    width:100%;
    height:100px;
}

.shop-product-image-zooms {
    display:flex;
    justify-content: center;
    align-items: center;
}

.shop-product-image-zoom-list {
    display:flex;
    justify-content: space-between;
    align-items: center;
    list-style-type: none;
    /*border:1px solid red;*/
    margin:0;
    padding:0;
    width:180px;
    height:60px;
}


.shop-product-image-zoom-list > li:first-child {
    margin-left:3px;
}

.shop-product-image-zoom-list > li:last-child {
    margin-right:3px;
}

.selected-outline-zoom-image {
    border:1px solid black;
}

.reg-outline-zoom-image {
    border:1px solid #ddd;
}

.img-zoom-lens-multi {
    position: absolute;
    border: 1px solid #d4d4d4;
    /*set the size of the lens:*/
    width: 196px;
    height: 196px;
    z-index: 4;
    opacity:1;
    transition: opacity .5s ease;
}

.hide-img-zoom-lens-multi {
    position: absolute;
    border: 1px solid #d4d4d4;
    /*set the size of the lens:*/
    width: 196px;
    height: 196px;
    z-index: 4;
    opacity:0;
    transition: opacity .5s ease;
}

.img-zoom-result {
    position: absolute;
    /*border: 1px solid black;*/
    /*set the size of the result div:*/
    width: 650px;
    height: 650px;
    top:0;
    left:320px;
    opacity:1;
    z-index: 4;
    visibility: visible;
    transition: opacity .5s, visibility .5s;
    box-shadow: 0px 0px 16px -2px;
    background-repeat: no-repeat;
    
}

.hide-img-zoom-result {
    position: absolute;
    /*border: 1px solid black;*/
    /*set the size of the result div:*/
    width: 650px;
    height: 650px;
    top:0;
    left:320px;
    opacity:0;
    z-index: 3;
    visibility: hidden;
    transition: opacity .5s, visibility .5s;
    box-shadow: 0px 0px 16px -2px;
    background-repeat: no-repeat;    
}