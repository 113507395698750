.user-rating-shape {
    padding:0!important;
    border:none!important;
}

.star-rating-list-group-container {
    display: flex;
    justify-content: center;
    align-items: center;
    /*border:1px solid black;*/
    padding:0;
    height:21px;
    
}

.star-rating-list {
    padding:0;
    display:flex;
    align-items: center;
    list-style-type: none;
    margin-bottom: 0;
    box-sizing: border-box;
}

.star-rating-list li {
    color:rgb(196, 193, 193);
    font-size: 21px;
    display: flex;
    align-items: center;
}

.star-rating-list-item-shape {
    color:rgb(30, 133, 218)!important;
}

.star-rating-amount-msg {
    white-space: nowrap;
}

.star-rating-amount-msg, .star-rating-amount-no-msg {
    display:inline-block;
    margin-left: 4px;
    font-size:12px;
}

.star-rating-amount-no-msg {
    color:#717172;
    font-family: "Open Sans";
}

.star-rating-item {
    /*border:1px solid green;*/
    color:rgb(196, 193, 193);
    height:21px;
    width:100%;
    display:flex;
    align-items: center;
}
