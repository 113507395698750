.edit-search-term-stand-alone-heading {
    text-align: center;
    font-family: "Open Sans";
    font-size: 26px;
    color: #414142;
    font-weight: 800;
    margin: 40px 0 40px;
}

.edit-search-term-stand-alone-form {
    border:1px solid black;
}

.edit-search-term-stand-alone-input-form-group {
    display:flex;
    flex-direction: column;
    margin-bottom:8px;
}

.edit-search-term-stand-alone-input-form-group:nth-child(3) {
    margin-bottom:16px;
}

.edit-search-term-stand-alone-file-form-group {
    display:flex;
    flex-direction: column;
}

.edit-search-term-stand-alone-file-form-group > input:focus {
    outline-style: none;
}

.edit-search-term-stand-alone-checkbox-form-group {
    display:flex;
    align-items: center;
    margin-bottom:8px;
}

.edit-search-term-stand-alone-checkbox-form-group > label {
    margin:0;
    padding-left:8px;
}

.show-search-term-name-error-msg {
    display:flex;
    align-items: center;
    /*border-left:3px solid red;
    border-right:3px solid red;
    border-bottom:3px solid red;*/
    background-color: rgb(252, 198, 198);
    color:rgb(122, 9, 9);
    font-weight: 600;
    padding:4px;
    height:36px;
    border-radius: 4px;

}

.hide-search-term-name-input-error-border {
    border:1px solid black;
}

.show-search-term-name-input-error-border {
    border-left:3px solid red;
    border-right:3px solid red;
    border-top:3px solid red;
    border-bottom:1px solid red;
}

.edit-search-term-stand-alone-save-btn,
.edit-search-term-stand-alone-cancel-btn {
    appearance: none;
    border: none;
    background-color: black;
    color: #fff;
    font-family: "Open Sans";
    font-weight: 600;
    font-size:14px;
    padding: 8px 16px;
    border-radius: 4px;
    min-width:120px;
    display:flex;
    align-items: center;
    justify-content: center;
}

.edit-search-term-stand-alone-cancel-btn  {
    margin:8px 0 0 0;
}

.edit-search-term-stand-alone-save-btn,
.edit-search-term-stand-alone-cancel-btn {
    flex:1 1 100%;
}

.edit-search-term-stand-alone-save-btn {
    background-color: green;
}

.edit-search-term-stand-alone-cancel-btn  {
    background-color: blue;
}

@media (min-width:768px){
    .edit-search-term-stand-alone-save-btn,
    .edit-search-term-stand-alone-cancel-btn {
        flex:unset;
    }

    .edit-search-term-stand-alone-cancel-btn {
        margin:0 0 0 8px;
    }   
}
