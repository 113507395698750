.shop-product-review-cart-layout-wrapper{
    width:100%;
}

.shop-product-review-cart-wrapper {
    width:100%;
    position:relative;
    margin:32px auto 152px;
    padding:0 16px 16px;
}

.shop-product-cart-heading {
    font-weight: 800;
    font-family: "Open Sans";
    margin-bottom: 24px;
    color:rgb(65, 65, 66);
    line-height: 28px;
    font-size: 32px;
}

.shop-product-cart-section-wrapper {
    display:flex;
    flex-wrap: wrap;
    width:100%;
}

.shop-product-cart-section-left {
    width:100%;
}

.shop-product-cart-section-right {
    width:100%;
    border:1px solid rgb(215, 215, 216);
    border-radius: 8px;
    max-height: 410px;
}

.shop-product-curbside-panel {
    display:flex;
    align-items: center;
    padding:16px;
    margin:0 0 16px;
    cursor:pointer;
    color:rgb(65, 65, 66);
    font-family: "Open Sans";
    border-top:1px solid rgb(215, 215, 216);
    border-right:1px solid rgb(215, 215, 216);
    border-bottom:1px solid rgb(215, 215, 216);
    border-left:8px solid rgb(0 125, 179);
    border-radius:8px;
    box-shadow: none;
}

.shop-product-curbside-panel:hover {
    box-shadow: rgb(27 31 35 / 10%) 0px 1px 12px;
}

.shop-product-curbside-panel-grid {
    display:grid;
    grid-row-gap: 16px;
    width:100%;
}

.shop-product-curbside-image-container-wrapper {
    display:flex;
    align-items: center;
    display:none;
    font-family: "Open Sans";
    font-weight: 800;
}

.shop-product-curbside-image-container {
    display: flex;
    align-items: center;
    padding: 0 16px 0 0;
    width:100%;
}

.shop-product-curside-image-svg {
    width:40px;
    height:40px;
}

.shop-product-curbside-heading-text {
    margin:0 0 0 16px;
    color:#414142;
    font-weight: 800;
    font-size:24px;
}

.shop-product-curbside-location {
    padding:16px 0 0;
    font-weight: 800;
    line-height: 24px;
    font-size: 16px;
    border-top:1px solid rgb(215, 215, 216);
    color:#414142;
    width:100%;
}

.shop-product-curbside-location-details-btn {
    appearance: none;
    background-color: transparent;
    border:none;
    /*width:100%;*/
    display: flex;
    align-items: center;
    color:#007DB3;
    padding:0;
}

.shop-product-curbside-location-details-btn-text {
    font-size: 14px;
    font-weight: 800;
}

.shop-product-curbside-location-btn-svg {
    margin:0 0 0 8px;
}

.shop-product-cart-sub-panel {
    display:flex;
    align-items: center;
    padding:16px 24px;
    margin:0 0 24px;
    border:1px solid rgb(215, 215, 216);
    border-radius: 8px;
}

.shop-product-cart-sub-panel-checkbox-container {
    display:flex;
    align-items: center;
    justify-content: center;
    position:relative;
}

.shop-product-cart-sub-panel-checkbox {
    appearance: none;
    height:14px;
    width:14px;
    background-color: #007DB3;
    border-radius: 4px;
}

.shop-product-cart-sub-panel-checkbox-svg {
    position: absolute;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    width:10px;
    height:10px;
    color:#fff;
}

.shop-product-cart-sub-panel-label {
    margin:0 0 0 8px;
    font-size: 14px;
    font-family: "Open Sans";
    color:#424046;
    font-weight: 800;
}

.shop-product-cart-wrapper {
    width:100%;
}

.shop-product-cart-category-list-container {
    padding:0;
    flex:1 1 0%;
    overflow-y: auto;
}


.shop-product-cart-category-list {
    display: flex;
    justify-content: center;
    flex-direction: column;
    list-style-type: none;
    padding:0;
    margin:0;

}

/*
.shop-product-mini-cart-list-wrapper-closed {
    position:absolute;
    right:0;
    top:0;
    width:78px;
    max-width:380px;
    height:100vh;
    z-index: 1;
    background-color:#fff;
}
*/

/*
.shop-product-mini-cart-list-wrapper-opened {
    position:absolute;
    right:0;
    top:0;
    width:100%;
    height:100vh;
    background-color: #fff;
    transition: width 0s ease;
    z-index: 1;
    box-shadow: rgb(27 31 35 / 20%) 0px 0px 25px 20px;
}
*/

.shop-product-cart-list-container {
    display: flex;
    flex-direction: column;
    top:0;
    padding:0;
    height:100%;
    overflow:hidden;
}

/*
.shop-product-mini-cart-heading-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color:rgb(220, 41, 30);
    color:#fff;
    padding:12px 8px;
    border-left:1px solid rgb(199, 38, 28);
    line-height: 1;
    font-weight: 800;
    font-size: 16px;
    font-family: "Open Sans";
    border:none;
}
*/

.shop-product-cart-heading-angle-left-icon-container {
    width:100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.mini-cart-left-arrrow {
    width:16px;
    height:16px;
    transform: scaleX(-1);
    margin-right: 8px;
}

.mini-cart-right-arrrow {
    width:16px;
    height:16px;
    margin-right: 8px;
}

/*
.shop-product-mini-cart-heading {
    margin:0 0 0 .25rem;
    display:block;
    font-weight: 600;
    font-size: 18px;
}
*/

.shop-product-cart-category-heading {
    font-size: 20px;
    font-weight: 800;
    font-family: "Open Sans";
    margin: 0 0 8px;
}

.shop-product-cart-view-cart-footer {
    /*position:fixed;
    bottom:0;*/
    position: fixed;
    padding:16px;
    background-color: #fff;
    border-top: 1px solid rgb(232, 232, 232);
    box-shadow: rgb(0 0 0 / 10%) 0px 2px 10px;
    width:100%;
    display:flex;
    flex-direction: column;
    left:0;
    bottom:0;
    right:0;
}

.cart-item-total-container {
    display:flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom:12px;
}

.cart-item-total-heading {
    font-family:"Open Sans";
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
}

.cart-item-total-amount {
    font-family: "Open Sans";
    font-size: 20px;
    line-height: 28px;
    font-weight: 800;
}

.shop-product-cart-view-cart-btn-container {
    padding:0;
    display:flex;
    align-items: center;
}

.shop-product-cart-view-cart-btn {
    width:100%;
    font-size: 14px;
    height:48px;
    border-radius: 4px;
    padding:8px 16px;
    font-weight: 800;
    line-height: 20px;
    color:#fff;
    background-color: rgb(220, 41, 30);
    transition: background-color 250ms ease-in-out 0s;
    font-family: "Open Sans";
    border:1px solid transparent;
    display:flex;
    align-items: center;
    justify-content: center;
}

.shop-product-cart-view-cart-btn:hover {
    background-color: rgb(192, 37, 37);
    border:1px solid rgb(192, 37, 37);
}

.shop-product-cart-category-list-item {
    width:100%;
    margin:0 0 32px 0;
}

.shop-product-cart-product-list {
    list-style-type: none;
    padding:0;
    margin-bottom:.375rem;
}

/****test class***/
.shop-product-cart-test-container {
    padding:16px 0;
    display:flex;
    position:relative;
    background-color: white;
    border-top:1px solid rgb(215, 215, 216);
    overflow-x: hidden;
}

.shop-product-cart-test-container {
    border-top:0;
    border-bottom:1px solid rgb(215, 215, 216)
}

.shop-product-cart-test-container:last-child {
    border-bottom:1px solid rgb(215, 215, 216)
}

.shop-product-cart-product-list-item {
    display:grid;
    grid-template-rows: auto;
    grid-template-columns: auto 64px 2fr 2fr 2fr 1fr 1fr;
    grid-gap:8px;
    padding:0;
    width:100%;
    background-color: #fff;
    transition: all .125s ease-in-out;
    position:relative;
}

.shop-product-cart-product-list-item-overlay {
    display:none;
    position:absolute;
    width:100%;
    height:100%;
    background-color:hsla(0,0%,100%,.4);
}

.move-cart-list-item {
    transform:translateX(280px);
    opacity: .4;
}

/*
.shop-product-mini-cart-product-list-item:last-child {
    border-bottom: 1px solid lightgrey;
}
*/

.shop-product-cart-product-list-item-delete-btn-container{
    display: flex;
    justify-content: center;
    align-items: center;
    grid-row-start:1;
    grid-row-end:2;
    margin:0; 
}

.shop-product-cart-product-list-item-delete-btn {
    border:none;
    background-color: transparent;
    color:#909092;
    padding:0;
    cursor: pointer;
}

.cart-list-delete-btn-svg {
    color:rgb(65, 65, 66);
}

.shop-product-cart-product-list-item-image-container {
    display: flex;
    align-items: center;
    grid-row-start:1;
    grid-row-end:2; 
}

.shop-product-cart-product-list-item-image {
    width:64px;
    height:64px;
}

.shop-product-cart-product-list-item-name-container {
    display:flex;
    align-items: center;
    width:100%;
    grid-column-start: 3;
    grid-column-end: 7;
}

.shop-product-cart-product-list-item-name {
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    color:#414142;
    font-family: "Open Sans";
}

.shop-product-checkout-cart-allow-subs-checkbox-wrapper {
    display:flex;
    align-items: center;
    grid-row-start: 3;
    grid-row-end: 4;
    grid-column-start:4;
    grid-column-end:6;
}

.shop-product-checkout-cart-allow-subs-checkbox-container {
    position:relative;
    display:flex;
    align-items: center;
    justify-content: center;
}

.shop-product-checkout-cart-allow-subs-checkbox {
    width:14px;
    height:14px;
    background-color: #007DB3;
    appearance: none;
    border:none;
    border-radius: 4px;
}

.shop-product-checkout-cart-allow-subs-checkbox-svg {
    position:absolute;
    top:50%;
    transform:translateY(-50%);
    width:10px;
    height:10px;
    color:#fff;
}

.shop-product-checkout-cart-allow-subs-checkbox-label {
    font-family: "Open Sans";
    color:#414142;
    font-size: 12px;
    font-weight: 800;
    margin:0 0 0 8px;
    white-space: nowrap;
}

.shop-product-checkout-cart-note-btn-container {
    display:flex;
    width:100%;
    align-items: center;
    grid-row-start: 3;
    grid-row-end: 4;
    grid-column-start: 2;
    grid-column-end: 3;
}

.shop-product-checkout-cart-note-btn {
    background-color: transparent;
    appearance: none;
    padding:0;
    border:none;
    display:flex;
    align-items: center;
}

.shop-product-checkout-cart-note-btn-svg {
    max-width: 16px;
    max-height: 16px;
    margin-right: 8px;
}

.shop-product-checkout-cart-note-btn-text {
    font-family: "Open Sans";
    font-size: 12px;
    color:#414142;
    white-space: nowrap;
}

.shop-product-cart-product-list-item-price-container {
    width:100%;
    min-height:37px;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    justify-content: center;
    grid-row-start: 1;
    grid-row-end: 2;
    grid-column-start: 7;
    grid-column-end: 8;
}

.shop-product-cart-product-list-item-total-price {
    color:rgb(65,65, 66);
    /*font-weight: 700;
    font-size: 14px;*/
    /*border:1px solid black;*/
    text-align: right;
    font-weight: 800;
    font-size: 16px;
    line-height: 28px;
    font-family: "Open Sans";
}

.price-slash {
    position:relative;
}

.price-slash::before {
    position:absolute;
    content:"";
    top:40%;
    border-top:2px solid rgba(12, 12, 12, 0.3);
    right:-4px;
    width:3.5rem;
    transform:rotate(-20deg);
}

.shop-product-cart-product-list-item-total-price-sale-container {
    padding:0;
}

.shop-product-cart-product-list-item-total-price-sale {
    color:red;
    font-weight: 700;
    font-size: 14px;
    /*border:1px solid black;*/
    text-align: right;
}

.shop-product-cart-product-list-item-each-price {
    display: flex;
    color:black;
    justify-content: flex-end;
    font-size: 10px;
    font-weight: 400;
    white-space: nowrap;
    color:#414142;
}

.each-forward-slash-shape {
    font-size: 10px;    
}

.shop-product-cart-product-list-item-quantity-container {
    width:100%;
    display:flex;
    justify-content: flex-end;
    align-items: center;
    position:relative;
    grid-row-start: 2;
    grid-row-end: 3;
    grid-column-start:6;
    grid-column-end:8;
}

.shop-product-cart-product-list-item-quantity-button-container {
    display:flex;
    align-items: center;
    align-items: center;
    max-width: 120px;
    min-width: 100px;
    height:36px;
    position:relative;
}

.expand-item-quantity-button-container-each {
    position:absolute;
    box-shadow: 0 0 4px 0 rgba(0,0,0,.25);
    z-index: 1;
}

.shop-product-cart-product-list-item-quantity-increment-btn, .shop-product-cart-product-list-item-quantity-decrement-btn {
    height:100%;
    width:35px;
    border:none;
    border:1px solid rgb(238, 236, 236);
    /*text-align: center;*/
    color:#333;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
}

.shop-product-cart-icon-btn-container {
    color:#414142;
    display: flex;
    align-items: center;
    justify-content: center;
}

.shop-product-cart-product-list-item-quantity-increment-btn:hover, .shop-product-cart-product-list-item-quantity-decrement-btn:hover {
    background-color: rgb(228, 226, 226);
}

.shop-product-cart-product-list-item-quantity-increment-btn {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}

.shop-product-cart-product-list-item-quantity-decrement-btn {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}

.shop-product-cart-product-list-item-quantity-each {
    text-align: center;
    height:100%;
    width:30px;
    font-weight: 800;
    font-family: "Open Sans";
    border-top:1px solid rgb(238, 236, 236);
    border-bottom:1px solid rgb(238, 236, 236);
    border-right:none;
    border-left:none;
    color: rgb(65, 65, 66);

}

.shop-product-cart-product-list-item-quantity-each:focus, 
.shop-product-cart-product-list-item-quantity-increment-btn:focus,
.shop-product-cart-product-list-item-quantity-decrement-btn:focus {
    outline-style: none;
}

.shop-product-cart-product-list-item-quantity-button-container-pound {
    height:36px;
    max-width: 120px;
    min-width: 100px;
    display:flex;
    align-items: center;
    width:100%;
    position:relative;
    border:1px solid rgb(238, 236, 236);
    border-radius: 8px;
    background-color: #fff;
}

.shop-product-cart-product-list-item-quantity-pound {
    text-align: center;
    height:40px!important;
    /*width:90px;*/
    width:64px;
    font-weight: 800;
    border:1px solid #ccc!important;
    border-radius: .25rem;
    font-family: "Open Sans";
    color: rgb(65, 65, 66);
}

.shop-product-cart-product-list-item-quantity-pound:focus {
    text-align: center;
    height:40px!important;
    /*width:90px;*/
    width:64px;
    font-weight: 800;
    border:1px solid #ccc!important;
    outline-style: none;
    font-family: "Open Sans";
    color: rgb(65, 65, 66);
}

/**may need to remove this style because it doesn't exist in component**/
.shopping-cart-product-quantity-selector-container {
    display:flex;
    width:7rem;
}

/**may need to remove this style**/
.shopping-cart-product-quantity-selector-btn-container {
    position: relative;
    /*width:48px;*/
    display:flex;
    align-items: center;
    border:1px solid rgb(238, 236, 236);
    background-color: #fff;
    padding:0 4px;
    border-radius: 8px;
}

.shopping-checkout-cart-product-quantity-selector-btn {
    border-radius: 4px;
    height:36px;
    background-color: transparent;
    box-shadow: none!important;
    border:none;
    font-size: 16px;
    appearance: none;
    width:100%;
    font-family: "Open Sans";
    color: rgb(65, 65, 66);
    font-weight: 800;
    padding:8px;
    background-image: url('https://server.kando-proto-3.com/down-arrow.png');
    background-repeat: no-repeat;
    background-position: right center;
    background-size: auto 24px;
    background-origin: content-box;
}

.expand-item-quantity-button-container-pound {
    z-index: 2;
    width:64px;
    box-shadow: none;
}

.expand-item-price-container-pound {
    display:flex;
    justify-content: flex-start;
    flex-direction: column;
    width:100%;
}


/*hide arrows for input[type="number"]*/
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}


.delete-cart-product-container {
    position:absolute;
    left:16px;
    background-color: white;
    padding:0;
    z-index: 1;
    display: none;
}

.delete-cart-product-msg {
    padding:0;
    font-size: 14px;
    color:#414142;
    font-family: "Open Sans";
}

.delete-cart-product-btn-container {
    margin-top:8px;
}

.delete-cart-product-remove-btn {
    margin-left:8px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(220, 41, 30);
    border-radius: 4px;
    border:1px solid transparent;
    padding: 8px 16px;
    font-family: "Open Sans";
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    transition: background-color .25s ease-in-out;
    color:#fff;
}

.delete-cart-product-remove-btn:hover {
    background-color: rgb(192, 37, 37);
}



.delete-cart-product-cancel-btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    border-radius: 4px;
    border:1px solid rgb(46, 46, 47);
    padding:8px 16px;
    font-family: "Open Sans";
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    transition: background-color .25s ease-in-out;
    color:rgb(46, 46, 47);
}

.delete-cart-product-cancel-btn:hover {
    background-color: rgb(46, 46, 47);
    color:#fff;
}

.hide-element {
    display:none;
}

.show-element {
    display:block;
}

.show-element-pound {
    display:flex;
    width:128px;
}

.shop-product-cart-empty-cart-btn-container {
    display:flex;
    justify-content: flex-end;
    align-items: center;
    margin:0 0 16px;
}

.shop-product-cart-empty-cart-btn {
    background-color: transparent;
    appearance: none;
    font-size:14px;
    height:44px;
    width:151px;
    font-weight: 800;
    line-height: 20px;
    transition: background-color .25s ease-in-out;
    display:flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    color:#414142;
    border:1px solid rgb(215, 215, 216);
    font-family: "Open Sans";
    padding:8px 16px;

}

.shop-product-cart-empty-cart-btn:hover {
    background-color: #eee;
}

.shop-product-cart-empty-cart-btn-svg {
    margin:0 8px 0 0;
}

.shop-product-cart-order-summary-heading {
    padding:16px;
    line-height: 32px;
    font-size: 24px;
    font-weight: 800;
    margin:0;
    color:#414142;
    font-family: "Open Sans";
}

.shop-product-cart-order-summary-sub-total-container {
    display:flex;
    align-items: center;
    justify-content: space-between;
    padding:0 16px 12px;
    font-family: "Open Sans";
}

.shop-product-cart-sub-total-item-count {
    font-size: 16px;
    margin: 0 8px 0 0;
    font-weight: 400;
}

.shop-product-cart-sub-total-item-amount {
    font-weight: 400;
}

.shop-product-cart-order-summary-estimated-total-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding:16px 0;
    font-family: "Open Sans";
    color:rgb(51, 51, 51);
}

.shop-product-cart-estimated-total-text {
    font-size: 20px;
    line-height: 28px;
    font-weight: 400;
}

.shop-product-cart-estimated-total-amount {
    font-weight: 800;
    font-size:24px;
}

.shop-product-cart-checkout-btn {
    display:none;
    appearance: none;
    align-items: center;
    justify-content: center;
    font-family: "Open Sans";
    font-size: 16px;
    line-height: 24px;
    font-weight: 800;
    border: none;
    border-radius:4px;
    padding: 12px 16px;
    margin: 16px 0px;
    width: 100%;
    transition: background-color 250ms ease-in-out 0s;
    background-color: rgb(220, 41, 30);
    color:#fff;

}

.shop-product-cart-checkout-btn:hover {
    background-color: rgb(192, 37, 37);
    color:#fff;
}

.no-products-added {
    color:rgb(107,107,107);
    background-color: rgb(236, 236, 236);
}

.shop-product-cart-order-summary-bottom-section {
    padding:0 16px;
}

.shop-product-cart-order-summary-extras-container {
    display:flex;
    flex-wrap: wrap;
    width:100%;
    margin:16px 0 0;
}

.shop-product-cart-order-summary-order-note-container, .shop-product-cart-order-summary-order-promo-code-container {
    display:flex;
    align-items: center;
    height:40px;
    width:100%;
}

.shop-product-cart-order-summary-order-note-svg, .shop-product-cart-order-summary-promo-code-svg {
    width:16px;
    height:16px;
    margin-right:8px;
}

.shop-product-cart-order-summary-order-note, .shop-product-cart-order-summary-promo-code {
    font-family: "Open Sans";
    font-size: 14px;
    color:#414142;
    font-weight: 800;
}

.shop-product-cart-order-summary-footnote {
    color: rgb(65, 65, 66);
    font-size: 12px;
    line-height: 16px;
    margin: 16px 0px;
    font-weight: 400;
    font-family: "Open Sans";
}

.shop-product-cart-empty-cart-msg-wrapper {
    font-family: "Open Sans";
    color:#414142;
}

.shop-product-cart-empty-cart-msg-container {
    margin:128px 0;
    display:flex;
    flex-direction: column;
    align-items: center;
}

.shop-product-cart-empty-cart-msg-svg {
    color:rgb(210, 210, 210);
    height:64px;
    width:64px;
}

.shop-product-cart-empty-cart-msg {
    font-size: 24px;
    font-weight: 700;
    text-align: center;
    margin-bottom:8px;
    line-height: 1.5;
}

.shop-product-cart-empty-cart-msg-caption {
    text-align: center;
    font-size: 16px;
    margin-bottom: 8px;
    line-height: 1.5;
}

.shop-product-cart-empty-cart-msg-btn {
    appearance: none;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;
    cursor: pointer;
    background: rgb(220, 41, 30);
    margin: 32px 0 0;
    border-radius: 4px;
    border: 1px solid transparent;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    transition: background-color .25s ease-in-out 0s;
    color: #fff;
    padding:0;
}

.shop-product-cart-empty-cart-msg-btn:hover {
    background-color: rgb(192, 37, 37);
}

.shop-product-cart-empty-cart-msg-btn-link,
.shop-product-cart-empty-cart-msg-btn-link:hover {
    padding:8px 32px;
    text-decoration: none;
    color:#fff;
    font-family: "Open Sans";
}

.cart-loader-animation-container {
    width:100%;
    height:488px;
    display:flex;
    justify-content: center;
    padding-top:100px;
}

@media (min-width:360px){
    .shop-product-cart-product-list-item {
        grid-template-columns: auto 64px 2fr 2fr 2fr 1fr 1fr;
    }

    .shop-product-cart-product-list-item-name-container {
        grid-row-start: 1;
        grid-row-end: 2;
        grid-column-start:3;
        grid-column-end:7;
    }

    .shop-product-checkout-cart-allow-subs-checkbox-wrapper {
        grid-row-start: 2;
        grid-row-end: 3;
        grid-column-start:3;
        grid-column-end:6;
        justify-content: center;
    }

    .shop-product-checkout-cart-note-btn-container {
        grid-row-start: 2;
        grid-row-end: 3;
        grid-column-start:2;
        grid-column-end:3;
    }

    .shop-product-cart-product-list-item-price-container {
        grid-row-start: 1;
        grid-row-end: 2;
        grid-column-start:7;
        grid-column-end:8;
    }

    .shop-product-cart-product-list-item-quantity-container {
        grid-row-start: 2;
        grid-row-end: 3;
        grid-column-start:6;
        grid-column-end:8;

    }
    
}

@media (min-width:380px) {
    .shop-product-cart-product-list-item-delete-btn-container {
        margin:0 8px 0 0;
    }
}

@media (min-width:480px){
    .shop-product-cart-order-summary-extras-container {
        flex-wrap: nowrap;
        align-items: center;
        justify-content: space-evenly;
    }

    .shop-product-cart-order-summary-order-note-container, .shop-product-cart-order-summary-order-promo-code-container {
        width:unset;
    }
}

@media (min-width:768px) {
    
    .shop-product-review-cart-wrapper {
        width:100%;
        position:relative;
        margin:40px auto 71px;
        padding:0 32px 16px;
    }

    .shop-product-curbside-panel-grid {
        display:flex;
        align-items: center;
        grid-row-gap:0;
    }

    .shop-product-curbside-image-container {
        flex:0;
    }

    .shop-product-curbside-location {
        border-left:1px solid rgb(215, 215, 216);
        border-top:none;
        padding:8px 16px;
        flex:1;
    }

    .shop-product-cart-product-list-item-quantity-decrement-btn,
    .shop-product-cart-product-list-item-quantity-each,
    .shop-product-cart-product-list-item-quantity-increment-btn {
        width:40px;
    }

    .shop-product-cart-checkout-btn{
        display:flex;
    }


    .shop-product-cart-view-cart-footer {
        display:flex;
        flex-direction: row;
        justify-content: flex-end;
    }

    .cart-item-total-container {
        border-left:1px solid rgb(232, 232, 232);
        padding-left:16px;
        flex-wrap: nowrap;
        margin:0 32px 0;
    }

    .cart-item-total-heading {
        font-size: 20px;
        margin:0 8px 0 0;
    }

    .cart-item-total-amount {
        font-size: 24px;
    }

    .shop-product-cart-view-cart-btn {
        width:196px;
        white-space: nowrap;
    }
}

@media (min-width:1024px){
    .shop-product-review-cart-wrapper {
        max-width: 1440px;
    }

    .shop-product-cart-product-list-item {
        grid-template-columns: auto 64px 1fr 148px 120px 80px;
    }

    .shop-product-cart-product-list-item-name-container {
        grid-row-start: 1;
        grid-row-end: 2;
        grid-column-start:3;
        grid-column-end:4;
    }

    .shop-product-checkout-cart-allow-subs-checkbox-wrapper {
        grid-row-start: 1;
        grid-row-end: 3;
        grid-column-start:4;
        grid-column-end:5;
    }

    .shop-product-checkout-cart-note-btn-container {
        grid-row-start: 2;
        grid-row-end: 3;
        grid-column-start:3;
        grid-column-end:4;
    }

    .shop-product-cart-product-list-item-price-container {
        grid-row-start:1;
        grid-row-end:3; 
        grid-column-start:6;
        grid-column-end:7;
    }

    .shop-product-cart-product-list-item-quantity-container {
        grid-row-start:1;
        grid-row-end:3;
        grid-column-start:5;
        grid-column-end:6;
    }

    .shop-product-cart-product-list-item-total-price {
        font-size: 20px;
    }

    .shop-product-cart-product-list-item-each-price {
        font-size: 12px;
        line-height: 16px;
        margin-top: 12px;
    }

    .shop-product-checkout-cart-note-btn-text, .shop-product-checkout-cart-allow-subs-checkbox-label {
        font-size: 14px;
    }

    .shop-product-cart-product-list-item-name {
        font-size: 16px;
        line-height: 24px;
    }

    .shop-product-cart-view-cart-footer {
        padding:12px 32px;
    }

    .shop-product-curbside-image-container-wrapper {
        flex-grow:1;
        display:flex;
    }

    .shop-product-cart-order-summary-extras-container {
        flex-wrap: wrap;
    }

    .shop-product-cart-order-summary-order-note-container, .shop-product-cart-order-summary-order-promo-code-container {
        width:100%;
    }
    
}

@media (min-width:1280px) {
    .shop-product-cart-section-wrapper {
        position:relative;
        max-width: 1440px;
    }

    .shop-product-cart-section-left {
        flex:1 1;
        padding:0 32px 0 0;
    }

    .shop-product-cart-section-right {
        flex-basis:310px;
        height: unset;
    }
    
}