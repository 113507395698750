.search-filter-sidebar-container {
    padding:0 0 96px;
    font-family: "Open Sans";
    margin:0;
}

.search-filter-sidebar-list-heading {
    font-weight: 800;
    font-size: 16px;
    color:#414142;
    margin:16px 0 8px;
    padding:0 8px;
}

.search-filter-sidebar-show-all-results-btn {
    display:flex;
    font-size: 16px;
    justify-content: flex-start;
    align-items: center;
    color:rgb(0, 125, 179);
    padding:8px;
    appearance: none;
    border:0;
    background-color: transparent;
    margin:0;
}

.quick-checkmark-remove {
    background-color: #fff;
    z-index: 1;
    border: 1px solid #414142;
    border-radius: 4px;
}

.checkmark-add {
    position:absolute;
    left:0;
    top:0;
    width:100%;
    height:100%;
    display:none;
    align-items: center;
    justify-content: center;
    background-color: #007DB3;
    z-index: 1;
    border-radius: 4px;
}

.search-filter-sidebar-show-all-results-btn-text-container {
    width:100%;
    display:flex;
    align-items: center;
    font-size: 14px;
    font-weight: 800;
    font-family: "Open Sans";
}

.search-filter-sidebar-show-all-results-btn-text {
    display:flex;
    align-items: center;
}

.search-filter-sidebar-show-all-results-btn-direction-arrow {
    width:16px;
    max-width: 16px;
    max-height: 16px;
    margin-left: 8px;
}

.search-filter-sidebar-direction-arrow-position {
    transform:rotate(180deg);
}

@media (min-width:1024px) {
    .search-filter-sidebar-container {
        padding:0;
    }
}