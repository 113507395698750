.shop-product-category-product-container {
    margin:0 auto;
    color:rgb(65, 65, 66);
    width:100%;
    height:100%;
    display:flex;
    flex-direction: column;
}

.shop-product-detail-product-image-container {
    height:200px;
    max-width: 100%;
    padding:8px 0;
    display:flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.shop-product-detail-promotion-image-list-container {
    position: absolute;
    top:0px;
    left:0px;
    list-style-type: none;
    padding:5px 0 0 5px;
}

.shop-product-detail-promotion-image-container {
    position:relative;
    width:30px;
    height:35px;
}

.shop-product-detail-promotion-image {
    position: absolute;
    top:0px;
    left:0px;
}

/**one image style**/
/*
.shop-product-detail-promotion-image {
    position: absolute;
    top:6px;
    left:5px;
    padding:0;
    border:1px solid blue;
}
*/


.shop-product-detail-verification-image-list-container {
    position: absolute;
    top:0px;
    right:0px;
    list-style-type: none;
    padding:0;
    /*border:1px solid black;*/
    /*z-index: 10;*/
}

.shop-product-detail-verification-image-container {
    /*position: absolute;
    top:6px;
    right:5px;*/
    display:flex;
    justify-content: center;
    align-items: center;
    padding:5px;
    width:30px;
    height:30px;
    /*border:1px solid red;*/
}

.shop-product-detail-verification-image {
    border-radius: 50%;
}

.shop-product-detail-product-image {
    height:auto;
    max-width: 100%;
    border:0;
}


.shop-product-detail-rating-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin:0 0 4px;
}

.shop-product-detail-btns-container {
    padding:0 8px 8px;
    min-height:78px;
    margin-top:auto;
}

.shop-product-detail-add-to-list-btn-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.shop-product-detail-add-to-list-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    padding:8px 16px;
    font-family: "Open Sans";
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    margin-top: 8px;
    border:1px solid transparent;
    background-color: transparent;
    width:100%;
    color:#414142;
}

.shop-product-detail-product-link {
    text-decoration: none;
    border:0;
}

.shop-product-details-container {
    text-align: center;
    font-size: 16px;
    padding:8px;
}

.shop-product-detail-price-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
    align-items: baseline;
    margin-bottom: 4px;
    min-height: 24px;
}

.shop-product-detail-price {
    display: flex;
    color:#414142;
    font-weight: 800;
    font-size: 16px;
    line-height: 24px;
    font-family: "Open Sans";
    padding-right:8px;
    
}

.shop-product-detail-sale-price {
    display: flex;
    color:#DC291E;
    font-weight: 800;
    font-size: 16px;
    line-height: 24px;
    font-family: "Open Sans";
}

.shop-product-detail-list-price-container {
    padding:0;
    margin:0 0 5px 0;
}

.shop-product-detail-list-price {
    display: flex;
    color:grey;
    font-weight: 700;
    font-size: 11px;
    font-family: sans-serif;
    justify-content: center;
    align-items: center;
    text-decoration: line-through;
    height:17px;
}

.shop-product-detail-unit-of-measure-price {
    display:flex;
    color:#717172;
    font-size:12px;
    font-family: "Open Sans";
}

.shop-product-category-product-name {
    font-size: 14px;
    color:#414142;
    margin-bottom: 8px;
    line-height: 20px;
    text-align:left;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    font-family: "Open Sans";
}

.product-detail-rating-star-shape {
    font-size: 12px!important;
}

@media (min-width:480px){
    .shop-product-category-product-container {
        /*width:220px;*/
        max-width: 400px;
    }
}