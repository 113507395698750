.add-dpt-category-heading {
    text-align: center;
    font-family: "Open Sans";
    font-size: 26px;
    color: #414142;
    font-weight: 800;
    margin: 40px 0 40px;
}

.add-dpt-category-form {
    border:1px solid rgb(177, 176, 176);
    border-radius:8px;
    padding:16px;
    font-family: "Open Sans";
}

.add-dpt-category-input-form-group {
    /*border:1px solid black;*/
    display:flex;
    flex-direction: column;
    margin-bottom:8px;
}

.add-dpt-category-checkbox-form-group {
    /*border:1px solid black;*/
    display:flex;
    align-items: center;
}

.add-dpt-category-checkbox-form-group > label {
    margin:0;
    padding-left:.5rem;
}

.add-thumbnail-image {
    max-width:40px;
    max-height:40px;
}

.form-label-spacing-container {
    width:100%;
    min-height:32px;
}