.store-location-display-container {
    position:relative;
    display: flex;
    flex-direction: row;   
}

.store-locator-input-form-container {
    flex:1 1 100%;
    padding:0;
}

.store-location-display-map-location-container {
    display:none;
    padding:0 0 0 8px;
}

.sld-loader-animation-container {
    display: none;
}

@media (min-width:1024px) {
    .store-locator-input-form-container {
        flex:1 1 50%;
        max-width: 50%;
        padding:0 8px 0 0;
    }

    .store-location-display-map-location-container {
        display: flex;
        flex:1 1 50%;
        max-width: 50%;
    }

    .sld-loader-animation-container {
        display: flex;
        justify-content: center;
        flex:1 1;
        margin-top: 320px;
    }

}

@media (min-width:1440px) {
    .store-locator-input-form-container {
        flex:1 1 33.3333%;
        max-width: 33.3333%;
        padding:0 8px 0 0;
    }

    .store-location-display-map-location-container {
        display: flex;
        flex:1 1 66.6667%;
        max-width: 66.6667%;
        
    }

}