.shopping-cart-item-limit-error-msg {
    border-radius: 4px;
    padding: 8px;
    margin-bottom: 16px;
    color: rgb(66, 64, 70);
    background-color: rgb(247, 247, 247);
    border: 1px solid rgb(232, 232, 232);
    font-size: 16px;
    font-family: "Open Sans";
    line-height: 16px;
    text-align: center;
}

.shopping-cart-item-limit-error-msg-individual {
    display:flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    border-radius: 4px;
    padding:8px;
    margin-bottom: 16px;
    color: #FF0000;
    background-color: #F2DFDE;
    border: 1px solid #dc291e;
    font-size: 16px;
    font-family: "Open Sans";
    line-height: 16px;
}

@media (min-width:305px) and (max-width:1023px) {
    .product-qty-limit-msg-img {
        display:inline-block;
        width:25px;
        height:25px;
        margin-right:8px;
    }
}