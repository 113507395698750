.slider-container-wrapper {
    margin-bottom:1rem;
    /*border-bottom: 1px solid #D7D7D8;*/
}

.slider-heading {
    width:100%;
    padding:0;
    font-weight: 800;
    margin:16px 0 8px;
    color:#414142;
}

.slider-container {
    padding:0 1rem 0 0;
    /*width:200px;*/
    background-color: transparent;
}

.slider-btn-container {
    /*background-color: #009FCE;*/
    background-color: transparent;
    position:relative;
    height:4px;
}

.slider-btn-amount-container {
    display: flex;
    justify-content: space-between;
    padding:8px 0;
    width:100%;
    margin-bottom:15px;
}

.slider1Amount, .slider2Amount {
    width:55px;
    border-style: none;
}

.slider2Amount {
    text-align: right;
}

#slider1, #slider2 {
    border-radius: 50%;
    border-style:none;
    outline-style: none;
    /*background-color: #009FCE;*/
    background-color: transparent;
    height:16px;
    width:16px;
    position: absolute;
    top:-6px;
}

#slider1 {
    left:0%;
}

#slider2 {
    left:100%;
}



/*****testing area****/
