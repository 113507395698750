.footer-wrapper {
    position:relative;
    width:100%;
    font-family: "Open Sans";
    display:flex;
    align-items: center;
    flex-direction: column;
    background-color: #414142;
    padding-bottom:32px;
}

.footer-offers-wrapper {
    width:100%;
    background-color: #DC291E;
    /*background-color: rgb(167, 35, 27);*/
}

.footer-offers-container {
    display:flex;
    flex-wrap: wrap;
    width:100%;
    padding:16px;
    max-width: 1000px;
    margin:0 auto;
}

.footer-offers-heading{
    display:flex;
    flex-wrap: wrap;
    align-items: center;
    font-weight: 400;
    font-size: 22px;
    color:#fff;
    flex:1 1 100%;
    max-width:100%;
    margin-bottom:8px;
}

.footer-offers-exclusive-text {
    text-transform: uppercase;
    font-weight: 800;
}

.footer-offers-text{
    font-size: 14px;
    line-height: 20px;
    font-weight: 800;
    color:#fff;
    display:none;
}

.footer-offers-form {
    display:flex;
    flex:1 1;
    align-items: center;
}

.footer-offers-input-control-wrapper {
    display:flex;
    flex:1 1;
    height:44px;
}

.footer-offers-input-control {
    width:100%;
    appearance: none;
    border-radius: 4px;
    padding:8px;
    outline: none;
    margin-right:8px;
    font-size: 16px;
    background-color: #fff;
    border:none;
}

.footer-offers-btn {
    appearance: none;
    border:1px solid #fff;
    color:#fff;
    background-color: transparent;
    border-radius: 4px;
    font-weight: 800;
    font-size: 16px;
    padding:0 16px;
    display:flex;
    align-items: center;
    justify-content: center;
}

.footer-offers-btn:hover {
    color:#DC291E;
    background-color: #fff;
}

.footer-info-wrapper {
    display:flex;
    flex-wrap: wrap;
    font-family: "Open Sans";
    color:#fff;
    padding:64px 8px 0;
    margin:0;
    list-style-type: none;
}

.footer-company-wrapper,
.footer-services-wrapper,
.footer-customer-service-wrapper,
.footer-icon-wrapper, 
.footer-more-wrapper {
    flex:1 1 50%;
    max-width:50%;
    padding:0 8px;
}

.footer-icon-wrapper {
    margin:0 auto;
}

.footer-company-heading, 
.footer-services-heading, 
.footer-customer-service-heading, 
.footer-more-heading {
    /*color:#fff;*/
    font-size: 20px;
    line-height: 28px;
    font-weight: 800;
    margin-bottom:12px;
}

.footer-company-list,
.footer-services-list,
.footer-customer-service-list,
.footer-icon-list,
.footer-more-list {
    padding:0;
    list-style-type: none;
    margin:0;
    font-size:14px;
    line-height: 1.8;
    margin-bottom:32px;
}

.footer-icon-list {
    display:flex;
    align-items: center;
    flex-wrap:wrap;
    justify-content: center;
    gap:8px;
}

.footer-company-list-item,
.footer-services-list-item,
.footer-customer-service-list-item,
.footer-more-list-item {
    word-wrap: break-word;
}

.footer-icon-list-item:nth-child(n + 6){
    margin-top:8px;
    flex:1 1 100%;
}

.footer-icon-list-item-link {
    appearance: none;
    text-decoration: none;
    color:transparent;
}

.footer-icon-list-item-svg {
    display:inline-flex;
    width:30px;
    height:30px;
    opacity: 0.7;
    color:#fff;
}

.footer-icon-list-item-img {
    display:flex;
    margin:0 auto;
}

.footer-list-item-link, .footer-list-item-link:hover {
    appearance: none;
    text-decoration: none;
    color:#fff;
}

.footer-policy-wrapper {
    color:#fff;
    font-size: 14px;
    margin-top:32px;
    padding:0 16px;
}

.footer-copyright {
    margin-bottom:8px;
    display:flex;
    align-items: center;
    justify-content: center;
}

.footer-policy-list {
    padding:0;
    margin:0;
    list-style-type: none;
    display:flex;
    flex-wrap: wrap;
    justify-content: center;
}

.footer-policy-list-item:last-child {
    flex:1 1 100%;
    display:flex;
    align-items: center;
    justify-content: center;
}

.footer-policy-list-item-link, .footer-policy-list-item-link:hover {
    display:flex;
    appearance: none;
    text-decoration: none;
    color:#fff;
    margin-bottom:8px;
    padding:0 8px;
    line-height: 20px;
    font-weight: 400;
}

.footer-policy-list-item:nth-child(2) a,
.footer-policy-list-item:nth-child(3) a {
    border-left:1px solid #fff;
}

.footer-brands-wrapper {
    display:flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding:0 16px;
    margin:0 0 8px;
    list-style-type: none;
    font-size: 14px;
}

.footer-brands-list-item-link, .footer-brands-list-item-link:hover {
    color:#fff;
    text-decoration: none;
    appearance: none;
    padding:0 8px;
    line-height: 20px;
    font-weight: 400;
}

.footer-brands-list-item:nth-child(n + 2) a {
    border-left:1px solid #fff;
}

@media (min-width:768px) {
    .footer-offers-wrapper {
        margin-bottom:21px;
        box-shadow: rgb(255 255 255) 0px 5px 0px, rgb(220 41 30) 0px 21px 0px;
    }

    .footer-offers-text{
        display:flex;
        align-items: center;
        flex:1 1 50%;
        max-width: 50%;
        padding:0 8px 0 0;
    }

    .footer-offers-form {
        flex:1 1 50%;
        max-width: 50%;
        padding:0 0 0 8px;
    }

    /****info section styles****/
    .footer-info-wrapper {
        display:grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        max-width: 1000px;
        width:100%;
    }

    .footer-info-wrapper li {
        max-width: unset;
    }

    .footer-more-wrapper {
        grid-row-start:2;
        grid-row-end:3;
        grid-column-start: 3;
        margin-top:-40%;
    }
    
    .footer-icon-wrapper {
        grid-row-start:1;
        grid-row-end:2;
        grid-column-start: 4;
    }

    .footer-icon-list {
        margin:0;
    }
    
}

@media (min-width:1024px) {
    .footer-offers-heading{
        flex:1 1 25%;
        max-width: 25%;
    }

    .footer-offers-text{
        display:flex;
        align-items: center;
        flex:1 1 33.3333%;
        max-width: 33.3333%;
        padding:0 8px;
    }

    .footer-offers-form{
        flex:1 1 33.3333%;
        max-width: 33.3333%;
    }
    
}