.whats-new-container {
    width:100%;
    min-height: 900px;
}

.whats-new-image-container {
    width:100%;
    padding-bottom:33.8%;
    height:0;
    position:relative;
}

.whats-new-image {
    position: absolute;
    top:0;
    left:0;
    height:100%;
    width:100%;
    object-fit: cover;
    object-position: center center;
}
