.stt-button {
    bottom: 24px;
    position: fixed;
    right: 24px;
    margin-left: auto;
    border-radius: 48px;
    border: 2px solid rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.24) 0px 4px 8px;
    background: rgb(65, 65, 66);
    color: rgb(255, 255, 255);
    font-weight: 600;
    font-size: 14px;
    height: 70px;
    width: 70px;
    z-index: 1;
    display:none;
}

.stt-svg-wrapper {
    width:100%;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.stt-svg-element {
    transform: rotate(180deg);
    color: rgb(255, 255, 255);
    height: 24px;
    width: 24px;
}

.stt-button-show {
    display:block;
}