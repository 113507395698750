.e-sfat, .e-sfg{
    position:relative;
    /*height:24px;*/
    height:50px;
    /*transition:height .3s ease;*/
    background-color: rgb(119, 151, 111);
    overflow-y: hidden;
}

.search-filter-group {
    /*background-color:rgb(252, 158, 158);*/
    /*color:#fff;*/
    padding-left:5px;
    height:50px;
    display: flex;
    align-items: center;
    flex-wrap:wrap;
}

.existing-sfg {
    background-color: rgb(172, 222, 252);
}

.search-filter-group-category-name {
    padding-left: 10px;
    height:24px;
    display:inline-flex;
    align-items: center;
}

.search-filter {
    /*background-color:rgb(186, 169, 194);*/
    /*color:#fff;*/
    padding-left:5px;
    height:50px;
    display: flex;
    align-items: center;
}

.existing-sf {
    background-color:rgb(252, 199, 245);
}

.search-term {
    /*background-color:rgb(214, 202, 214);*/
    /*color:#fff;*/
    padding-left:5px;
    height:50px;
    display: flex;
    align-items: center;
}

.existing-st {
    background-color:rgb(252, 203, 138);
}

.search-filter-group-label {
    padding-left:5px;
    margin:0;
    cursor: pointer;
}

.search-filter-group-search-filters {
    margin-left:25px;
    /*display:none;*/
    height:50px;
}

.search-filter-group-search-terms {
    margin-left:25px;
    overflow-y: hidden;
    height:50px;
    /*display:none;*/
}

.show-item {
    display: block;
}

/**product verification styles**/
.product-verification-container {
    padding-left:5px;
}

.product-verification-label {
    padding-left:5px;
}

/**product promotion styles**/
.product-promotion-container {
    padding-left:5px;
}

.product-promotion-label {
    padding-left:5px;
}

/**style for search filter group error messages**/
.no-sfs-selected:nth-child(2){
    border-top:3px solid rgb(142, 53, 184);
    border-left:3px solid rgb(142, 53, 184);
    border-right:3px solid rgb(142, 53, 184);
}
.no-sfs-selected:nth-child(n+0){
    border-left:3px solid rgb(142, 53, 184);
    border-right:3px solid rgb(142, 53, 184);
}
.no-sfs-selected:last-child {
    border-bottom:3px solid rgb(142, 53, 184);
    border-left:3px solid rgb(142, 53, 184);
    border-right:3px solid rgb(142, 53, 184);
}

.no-sts-selected:nth-child(2){
    border-top:3px solid rgb(245, 31, 102);
    border-left:3px solid rgb(245, 31, 102);
    border-right:3px solid rgb(245, 31, 102);
}
.no-sts-selected:nth-child(n+0){
    border-left:3px solid rgb(245, 31, 102);
    border-right:3px solid rgb(245, 31, 102);
}
.no-sts-selected:last-child {
    border-bottom:3px solid rgb(245, 31, 102);
    border-left:3px solid rgb(245, 31, 102);
    border-right:3px solid rgb(245, 31, 102);
}
