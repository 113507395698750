/***error message style for no keyword entered in search box************/

.keyword-search-error-message-caret-up {
    position:relative;
    /*display:none;*/
    top:4px;
    left:4px;
}

.keyword-search-error-message-caret-up::before, .keyword-search-error-message-caret-up::after {
    content:'';
    position:absolute;
    top:0;
    left:6px;
    width:0;
    height:0;
    z-index:1;
}

.keyword-search-error-message-caret-up:before {
    top:-4px;
    border-left:12px solid transparent;
    border-right:12px solid transparent;
    /*border-bottom:12px solid lightgrey;*/
    border-bottom:12px solid grey;
}

.keyword-search-error-message-caret-up::after {
    top:-2px;
    border-left:12px solid transparent;
    border-right:12px solid transparent;
    border-bottom:12px solid white;
}

.alert-shape {
    border:1px solid red!important;
    opacity:0.1;
}

@keyframes test {
    from {width:175px}
    to {width:185px}
}

.keyword-search-error-message-container {
    position: absolute;
    /*width:180px;*/
    height:53px;
    /*border:1px solid grey;*/
    left:0px;
    top:42px;
    z-index:0;
    opacity: 0;
    visibility: hidden;
    transition: opacity .7s;
    padding:0;
}

.keyword-search-error-message-container-adjustment {
    z-index: 2;
    visibility: visible;
    opacity: 1;
}

.keyword-search-error-message {
    position: relative;
    display:flex;
    align-items: center;
    height:42px;
    width:175px;
    border:1px solid grey;
    top:11px;
    left:0;
    font-size: 13px;
    border-radius: .25rem;
    padding:.25rem 0 .25rem .5rem;
    background-color: white;
    box-shadow: 3px 3px 6px 0 rgb(163, 163, 163);
    transition: width .7s;
}

.keyword-search-error-message-adjustment {
    width:185px;
}

.exclamation-shape {
    color:white;
    font-size: 12px;
}

.exclamation-shape-container {
    background-color: orange;
    height:25px;
    width:25px;
    display:flex;
    justify-content: center;
    align-items: center;
    border-radius: .25rem;
    /*border:1px solid green;*/
}

.exclamation-message-shape {
    /*border:1px solid black;*/
    flex-grow: 1;
    display:flex;
    justify-content: center;
    padding:0 .25rem;
}

.keyword-search-position-container {
    display:flex;
    width:100%;
    height:36px;
    position: relative;
}

.keyword-search-position {
    z-index: 4;
    display:flex;
    flex:1 1 100%;
    align-items: center;
    position:relative;
}

/*
@media screen and (max-width:479px){
    .keyword-search-position {
        display:none;
    }
}
*/

@media screen and (min-width:480px){
    .keyword-search-position {
        margin:0 16px;
    }
}

@media screen and (min-width:768px){
    .keyword-search-position {
        min-width:240px;
        flex-basis: 400px;
        flex-grow:0;
    }
}

@media screen and (min-width:1024px){
    .keyword-search-position {
        flex-basis: 342px;
    }
}