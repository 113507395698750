.shop-product-mobile-layout-wrapper {
    margin-top: 60px;
    padding:0 20px;
}

.shop-product-mobile-layout-overview-details-container {
    display:flex;
    flex-direction: column;
}

.shop-product-mobile-layout-product-price {
    display:flex;
    align-items: center;
    color:#333333;
    font-family: "Open Sans";
    font-size: 16px;
    font-weight: 700;
}

.shop-product-mobile-layout-image-wrapper {
    max-width:400px;
    width:100%;
    margin:14px auto;
}

.shop-product-mobile-layout-image-container {
    position:relative;
    width:100%;
    height:100%;
}

.shop-product-mobile-layout-image {
    max-width: 100%;
    height:auto;
}


@media (min-width:1024){
    .shop-product-mobile-layout-wrapper{
        display:none;
    }
}













