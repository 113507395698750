.hplb-item-selector-wrapper {
   width:100%;
   display:flex;
   flex-wrap:wrap;
   margin-bottom:16px;
}

.hplb-item-selector-label {
   flex:1 1 100%;
}

.hplb-item-selector {
   width:100%;
   height:36px;
   border-radius: 4px;
   background-color: transparent;
   outline: none;
   appearance: none;
   background-image: url('data:image/svg+xml;utf8,<svg width=\"16\" height=\"16\" viewBox=\"0 0 16 16\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\"><path fillRule=\"evenodd\" clipRule=\"evenodd\" d=\"M1.65 4.24a1 1 0 0 0-1.3 1.52l7 6a1 1 0 0 0 1.3 0l7-6a1 1 0 0 0-1.3-1.52L8 9.684 1.65 4.24Z\" fill=\"currentColor\"/></svg>');
   background-repeat: no-repeat;
   background-size: 14px 14px;
   /*background-position: right center;*/
   padding:0 4px;
   -webkit-appearance: none;
   border: 1px solid #b1b0b0;
   color:#414142;
 }
 
 .hplb-item-type {
    padding:5px;
 }


 @media (min-width:768px) {
   .hplb-item-selector-wrapper {
      justify-content: flex-start;
   }

   .hplb-item-selector {
      width:unset;
   }
 }