.hpld-item-container {
    width:100%;
    background-color: #fff;
    padding:0;
 }

.hpld-no-mb {
  max-width: 1440px;
  margin:0 auto;
  width:100%;
}

.hpld-mb {
  width:100%;
  margin:0 auto;
  padding:0;
}

.hpld-item-options-list {
  display:flex;
  flex-wrap:wrap;
  list-style-type: none;
  margin-bottom:16px;
  padding:0 16px;
}
 
.hpld-item-option-list-item {
  flex:1 1 100%;
}

.hpld-item-option-list-edit-btn,
.hpld-item-option-list-delete-btn,
.hpld-item-option-list-cancel-btn,
.hpld-item-option-list-save-btn,
.hpld-item-option-list-items-btn {
  appearance: none;
  display: flex;
  align-items: center;
  justify-content: center;
  color:#fff;
  font-weight: 600;
  font-size: 14px;
  border:none;
  padding:8px 32px;
  border-radius: 4px;
  margin-top: 8px;
  width:100%;
}

.hpld-item-option-list-edit-btn,
.hpld-item-option-list-items-btn {
  background-color: green;
}

.hpld-item-option-list-delete-btn,
.hpld-item-option-list-save-btn {
  background-color: blue;
}

.hpld-item-option-list-cancel-btn {
  background-color: black;
}
 
 .hpld-item {
    text-align: center;
    width:100%
 }

 .hpld-item-no-production-banner-options {
  padding:0 16px;
 }


 .hpld-item-no-mb {
    width:100%;
    padding:0;
 }

 .hpld-slider-image-container {
   position: relative;
   width:100%;
   height:0;
   padding-bottom:100%;
 }

 .hpld-slider-image {
   position: absolute;
   left:0;
   top:0;
   width:100%;
   height:100%;
 }

 .hpld-carousel-wrapper {
    width:100%;
    position:relative;
 }

 .hpld-carousel-btn-overlay {
    position: absolute;
    width:100%;
    height:92px;
    left:0;
    bottom:48px;
    background-color: transparent;
 }

  .hpl-generic-info-msg-container {
    display:flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top:50px;
    left:50%;
    transform: translateX(-50%);
    width:100%;
    padding:0 16px;
  }

  .hpld-loaeder-animation-container {
    width:100%;
    display: flex;
    justify-content: center;
    margin-top: 40px;
}

  @media (min-width:533px){
    .hpl-generic-info-msg-container {
      max-width: 500px;
      padding:0;
    }
  }

 @media screen and (min-width:768px){
  .hpld-item-options-list {
    padding:0 32px;
  }

  .hpld-item-option-list-edit-btn,
  .hpld-item-option-list-delete-btn,
  .hpld-item-option-list-cancel-btn,
  .hpld-item-option-list-save-btn,
  .hpld-item-option-list-items-btn {
    width:120px;
  }

  .hpld-mb {
    max-width: 1376px;
    padding:0 32px;
  }
 
  .hpld-item-no-mb {
     padding:0 32px;
  }

  .hpld-item-no-production-options {
    padding:0 32px;
   }

   .hpld-item-no-production-banner-options {
    padding:0;
   }

 }

 @media screen and (max-width:767px){
  .hpld-mb {
    padding:0 16px;
  }
}

@media screen and (min-width:1500px){
  .hpld-mb {
    padding:0;
  }
}
